import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { GridListTileBar } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { GridList } from '@material-ui/core';
import { GridListTile } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import { VerifiedUser } from '@material-ui/icons';
import { Help } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import { MenuBook } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { red } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';
import { teal } from '@material-ui/core/colors';

import Header from "./Header";
import Footer from "./Footer";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import moment from "moment";
import { useParams } from "react-router-dom";
import Client from 'shopify-buy';

import { getDownloadUrl } from "./DownloadFile";
import { RESIZE_SIZE } from "./DownloadFile";
import publyLogo from "./publyLogo.png";
import finishedA5MagazineImage from "./finishedA5MagazineImage.png";
import finishedCalendarImage from "./finishedCalendarImage.png";
import twitterSocialIconRoundedSquare from "./twitterSocialIconRoundedSquare.png";
import paymentMethod from "./paymentMethod.png";
import { A5_MAGAZINE_PX } from './PrintSize';
import { CALENDAR_PX } from './PrintSize';
import { PagePreviewDialog } from './PagePreviewDialog';
import { getCalendarDateList } from './ItemUtil';
import { getPreviewStyleImage } from './ImageUtil';
import { getShopifyDomain } from './ShopifyUtil';
import { getShopifyStorefrontAccessToken } from './ShopifyUtil';
import { getTwitterProfileImageOriginUrl } from "./ImageUtil";
import RecommendedItem from "./RecommendedItem";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import { firebaseApp } from './App';

const RELATED_ITEM_LIMIT = 8;

const language = navigator.language.split(/[-_]/)[0];

const useStyles = makeStyles(theme => ({

  root: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
    marginBottom: theme.spacing(2),
  },
  appBar: {
    backgroundColor: theme.palette.appBarBg,
  },
  toolbar: {
    display: 'flex',
  },
  logo: {
    width: 134,
    position: 'relative',
    top: 5,
    padding: theme.spacing(1),
  },
  title: {
    paddingRight: 12,
    wordBreak: 'break-word',
  },
  avatar: {
    float: 'left',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  name: {
    color: 'black',
    marginLeft: theme.spacing(1),
    fontWeight: 300,
    fontSize: '1.3em',
  },
  verifiedUserIcon: {
    position: 'relative',
    top: 4,
    marginRight: theme.spacing(1),
    color: 'green',
  },
  twitterIcon: {
    width: 22,
    top: 3,
    position: 'relative',
  },
  createdAt: {
    textAlign: 'right',
  },
  selfIntroduction: {
    clear: 'both',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  underCheck: {
    textAlign: 'center',
    border: '1px solid',
    padding: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  calendarDateOption: {
    backgroundColor: theme.palette.textFieldBg,
    marginTop: theme.spacing(1),
  },
  gridList: {
    // flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    width: '100%',
  },
  gridTile: {
    textAlign: 'center',
    backgroundColor: theme.palette.imageBg,
  },
  gridTileRoot: {
    [theme.breakpoints.only('xs')]: {
      '&:nth-child(n+2)': {
        marginTop: 4,
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(n+3)': {
        marginTop: 2,
      },
    },
  },
  gridTileBar: {
    top: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    height: 'auto',
    right: 'auto',
    zIndex: 1,
  },
  loadingGridImage: {
    position: 'absolute',
    width: '100%',
  },
  gridImageWrap: {
    height: '100%',
    width: '100%',
  },
  gridImage: {
    width: '100%',
    height: '100%',
  },
  blurImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    filter: 'blur(3px)',
    margin: -6,
    width: 'calc(100% + 6px)'
  },
  blurInfo: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, .3)',
    color: 'white',
    padding: theme.spacing(1),
    cursor: 'pointer',
    bottom: 0,
  },
  priceDetail: {
    clear: 'both',
    marginTop: theme.spacing(4),
  },
  priceInfo: {
    margin: 0,
  },
  priceInfoRow1: {
    float: 'left',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    paddingLeft: 4,
    [theme.breakpoints.only('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '40%',
    },
  },
  priceInfoRow2: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    paddingLeft: 4,
    [theme.breakpoints.only('xs')]: {
      marginLeft: '51%',
      width: '49%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '41%',
      width: '59%',
    },
  },
  itemPrice: {
  },
  postage: {
    borderTop: '1px dashed black',
  },
  totalPrice: {
    color: red[500],
    borderTop: `1px solid ${red[500]}`,
  },
  buyInfoWrap: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  buyInfo: {
    clear: 'both',
    marginTop: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      borderRadius: 6,
    },
    backgroundColor: theme.palette.appBarBg,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  buttons: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1),
      width: 170,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  buyButton: {
    marginTop: theme.spacing(3),
  },
  paymentMethodWrap: {
    textAlign: 'center',
    lineHeight: 0,
  },
  paymentMethod: {
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  own: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(1),
    borderTop: `1px dashed black`,
  },
  scope: {
    margin: theme.spacing(1),
  },
  report: {
    display: 'block',
    margin: `${theme.spacing(1)}px 0 0 auto`,
  },
  delete: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  deleteInfo: {
    marginTop: theme.spacing(1),
  },
  unpublish: {
    textAlign: 'center',
    height: 'calc(100vh - 145px)',
  },
  toTopLogo: {
    height: 20,
    position: 'relative',
    top: 5,
    left: 5,
  },
  actualImage: {
    marginTop: theme.spacing(4),
  },
  tweetButton: {
    height: 40,
    width: '100%',
    backgroundColor: '#1DA1F2',
    textAlign: 'center',
    borderRadius: 4,
    display: 'block',
    marginTop: theme.spacing(5),
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  tweetLink: {
    color: 'white',
    position: 'relative',
    top: -14,
  },
  letsShare: {
    textAlign: 'center',
    marginTop: 4,
  },
  purchaseLoading: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
  helpText: {
    position: 'relative',
    top: -7,
    left: 4,
  },
  previewPage: {
    marginTop: theme.spacing(1),
  },
  authorContentsWrap: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  authorContents: {
    // border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.appBarBg,
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      borderRadius: 6,
    },
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  purchaseCount: {
    display: 'flex',
    color: 'white',
    marginTop: theme.spacing(4),
  },
  purchaseCountText: {
    padding: `4px ${theme.spacing(1)}px`,
    backgroundColor: grey[700],
    borderRadius: '4px 0 0 4px',
  },
  purchaseCountValue: {
    backgroundColor: teal[500],
    borderRadius: '0 4px 4px 0',
  },
  descriptionContentsWrap: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  descriptionContents: {
    backgroundColor: theme.palette.appBarBg,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      borderRadius: 6,
    },
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  requestMessage: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    clear: 'both',
  },
  description: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  finishedImage: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 6,
  },
  recommendedItemsWrap: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  recommendedItems: {
    width: '100%',
  },
  recommendedItem: {
    display: 'inline-block',
    [theme.breakpoints.only('xs')]: {
      width: `calc(50% - 4px)`,
      '&:nth-child(even)': {
        marginLeft: theme.spacing(1),
      },
      '&:nth-child(n+2)': {
        marginTop: theme.spacing(1),
      },
    },
    [theme.breakpoints.only('sm')]: {
      width: `calc(33% - 8px)`,
      marginRight: theme.spacing(1),
      '&:nth-child(3n)': {
        marginRight: 0,
      },
      '&:nth-child(n+3)': {
        marginTop: theme.spacing(1),
      },
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(25% - 6px)`,
      marginRight: theme.spacing(1),
      '&:nth-child(4n)': {
        marginRight: 0,
      },
      '&:nth-child(n+4)': {
        marginTop: theme.spacing(1),
      },
    },
  },
  relatedItems: {
    marginTop: theme.spacing(1),
  },
  seeAll: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    fontSize: '0.9rem',
  },
  relatedCreatorAvatar: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  relatedCreator: {
    display: 'inline-block',
  },

}));

// 購入数取得
/*
const getPurchaseCountText = (purchaseCount) => {

  if (purchaseCount >= 10) {
    return `${Number(parseInt(purchaseCount / 10) * 10).toLocaleString()}+`;
  } else {
    return '< 10';
  }

};
*/

// 翌月取得
const getNextMonth = () => {
  return moment().add(1, 'M');
};

export const ItemContext = React.createContext({ callbacks: null });

function ItemImage(props) {

  const { item, page, calendarDate } = props;
  const classes = useStyles();

  // State
  const [image, setImage] = React.useState(null);
  const [loading, setLoading] = React.useState(<LinearProgress color="secondary" />);

  // Context
  const { callbacks } = React.useContext(ItemContext);

  React.useEffect(() => {

    function showLinearProgressLoading() {

      setLoading(<LinearProgress color="secondary" />);
      callbacks.onStartRenderingImage();

    }

    function hideLinearProgressLoading() {

      setLoading(null);
      callbacks.onCompleteRenderingImage();

    }

    getDownloadUrl(page['remotePath'].replace('origin', `origin_${RESIZE_SIZE}`), (url) => {

      showLinearProgressLoading();
      getPreviewStyleImage(url, item, page, calendarDate, (src) => {

        setImage(src);
        hideLinearProgressLoading();

      });

    });

  }, [item, page, calendarDate, callbacks]);

  const isBlur = React.useMemo(() => {
    return page['blur'] === true;
  }, [page]);

  return (

    <>
      { image === null ? (
        <Skeleton
          variant="rect"
          className={classes.gridImage}
        />
      ) : (
        <div
          className={classes.gridImageWrap}
        >
          <div className={classes.loadingGridImage}>
            { loading }
          </div>
          <img
            src={image}
            className={classes.gridImage + " pageImage " + (isBlur ? classes.blurImage : '')}
            alt="item"
          />
          { isBlur ? (
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.blurInfo}
              onClick={(e) => e.target.style.display='none'}
            >
              <FormattedMessage id="Blurred by author. Looking forward to it after purchase." />
            </Typography>
          ) : null }
        </div>
      )}
    </>

  );

};

// タグに一致する作品一覧を表示
const openTagPageWindow = (tag) => {
  window.open(`/explore/tags/${tag}`, '_blank');
};

// クリエーターに一致する作品一覧を表示
const openCreatorPageWindow = (username) => {
  window.open(`/explore/@${username}`, '_blank');
};

function Item(props) {

  const { intl } = props;
  const { userId, itemId } = useParams();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));

  // State
  const [item, setItem] = React.useState(null);
  const [request, setRequest] = React.useState(undefined); // undefinedからnull or notが決まる
  const [colsAndCellHeight, setColsAndCellHeight] = React.useState({ cols: 1, cellHeight: 300, spacing: 0 });
  const [dialog, setDialog] = React.useState(null);
  const [calendarDate, setCalendarDate] = React.useState(getNextMonth().format("YYYYMM"));
  const [isCalendarDateDisable, setCalendarDateDisable] = React.useState(true);
  const [purchaseLoading, setPurchaseLoading] = React.useState(null);
  const [pageContainer, setPageContainer] = React.useState(null);
  const [otherItems, setOtherItems] = React.useState([]);
  const [relatedItems, setRelatedItems] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [relatedCreators, setRelatedCreators] = React.useState([]);
  const [tagMenuAnchorEl, setTagMenuAnchorEl] = React.useState(null);

  const visitor = firebase.auth().currentUser;
  const isAuthor = React.useMemo(() => visitor && visitor.uid === userId, [visitor, userId]);
  const isRequestClient = React.useMemo(() => visitor && request && visitor.uid === request['clientUserId'], [visitor, request]);
  const isPrivateItem = React.useMemo(() => item && item['isPublic'] !== true, [item]);
  const isEditableItem = React.useMemo(() =>  isAuthor && request === null, [isAuthor, request]);
  const relatedCreatorsWithoutMe = React.useMemo(() => relatedCreators.filter(doc => doc.id !== userId), [userId, relatedCreators]);

  // 関連するクリエーターをクリック
  const handleClickRelatedCreatorAvatar = React.useCallback((relatedCreator) => {
    openCreatorPageWindow(relatedCreator['username']);
  }, []);

  // タグメニュークリック
  const handleTagMenu = React.useCallback((event) => {
    setTagMenuAnchorEl(event.currentTarget);
  }, []);

  // タグメニュー閉じる
  const handleCloseTagMenu = React.useCallback(() => {
    setTagMenuAnchorEl(null);
  }, []);

  // タグをクリック
  const handleClickTag = React.useCallback((tag) => {

    openTagPageWindow(tag);
    handleCloseTagMenu();

  }, [handleCloseTagMenu]);

  // すべての同一クリエーターの他の作品を見る
  const handleSeeAllOtherItems = React.useCallback(() => {
    openCreatorPageWindow(item['username']);
  }, [item]);

  // すべての関連する作品を見る
  const handleSeeAllRelatedItems = React.useCallback((event) => {

    if (tags.length === 0) return;
    else if (tags.length === 1) openTagPageWindow(tags[0]);
    else handleTagMenu(event);

  }, [tags, handleTagMenu]);

  // このクリエーターにリクエストする
  const handleRequestThisCreator = React.useCallback(() => {
    window.open(`/request/${request['creatorUserId']}`, '_blank');
  }, [request]);

  // 画像再描画開始
  const onStartRenderingImage = React.useCallback(() => {
    setCalendarDateDisable(true);
  }, []);

  // 画像描画終了
  const onCompleteRenderingImage = React.useCallback(() => {
    setCalendarDateDisable(false);
  }, []);

  // カレンダー日付変更
  const handleChangeCalendarDate = React.useCallback((event) => {
    setCalendarDate(event.target.value);
  }, []);

  // ページプレビューダイアログを閉じる
  const closePagePreviewDialog = React.useCallback(() => {
    setDialog(null);
  }, []);

  // ページプレビューダイアログを表示
  const openPagePreviewDialog = React.useCallback(() => {

    const bindingDirection = item['bindingDirection'];
    const pages = item['pages'].slice();
    if (bindingDirection === 'right') {
      pages.reverse();
    }
    setDialog(
      <PagePreviewDialog
        direction={bindingDirection}
        blurList={pages.map((p) => p['blur'] === true)}
        closeCallback={closePagePreviewDialog}
      />
    );

  }, [item, setDialog, closePagePreviewDialog]);

  // 通報
  const handleReport = React.useCallback(() => {

    if (visitor) {

      const win = window.open(`https://docs.google.com/forms/d/e/1FAIpQLSc2p-u7iEAy0hFYSosy2XRh2vr_3Udw0T-Cu9MKKV3Mrgy6WA/viewform?entry.1728282580=${window.location.href}&entry.1710876718=${visitor.uid}`, '_blank');
      if (!win) {
        alert(intl.formatMessage({ id: 'Blocked pop-up. Allow pop-up.' }));
      }

    } else {
      alert(intl.formatMessage({ id: 'Sign in to report inappropriate content.' }));
    }

  }, [visitor, intl]);

  // 削除
  const handleDelete = React.useCallback(async () => {

    if (window.confirm(intl.formatMessage({ id: 'Are you sure you want to delete this item?' }))) {

      await firebase.firestore()
      .collection('profiles')
      .doc(userId)
      .collection('items')
      .doc(itemId)
      .delete()
      .catch(console.error);
      window.location.href = '/myPage';

    }

  }, [intl, userId, itemId]);

  // 公開非公開
  const handleChangeScope = React.useCallback(async (event) => {

    const isPrivate = event.target.checked;
    firebase.firestore()
    .collection('profiles')
    .doc(userId)
    .collection('items')
    .doc(itemId)
    .set({
      isPublic: !isPrivate
    }, { merge: true} )
    .then(() => {

      const nextItem = {
        ...item,
        isPublic: !isPrivate,
      };
      setItem(nextItem);

    })
    .catch(console.error);

  }, [userId, itemId, item]);

  // 自分にだけ公開しているときの表示
  const unpublishItem = React.useMemo(() => {

    return (

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        classes={{ root: classes.unpublish }}
      >
        <Container
          maxWidth="sm"
        >
          <Typography
            variant="h5"
            paragraph
          >
            <FormattedMessage id="This item is in private." />
          </Typography>
          <Link
            href="/"
            color="textPrimary"
          >
            <FormattedMessage id="Go to Top" />
            <img
              src={publyLogo}
              className={classes.toTopLogo}
              alt="Publy"
            />
          </Link>
        </Container>
      </Grid>

    );

  }, [classes]);

  const showErrorAlert = React.useCallback((reason) => {

    console.error(reason);
    window.alert(intl.formatMessage({ id: 'Not found item' }));
    window.location.href = '/';

  }, [intl]);

  const getItem = React.useCallback(() => {

    return new Promise((resolve, reject) => {

      firebase.firestore()
      .collection('profiles')
      .doc(userId)
      .collection('items')
      .doc(itemId)
      .get()
      .then(doc => {

        if (doc.exists) {
          resolve(doc);
        } else {
          reject('Notfound item');
        }

      })
      .catch(showErrorAlert);

    });

  }, [userId, itemId, showErrorAlert]);

  const termsOfUse = React.useMemo(() => {

    if (visitor !== null) return null;

    return (
      <Typography
        variant="body2"
        align="center"
        gutterBottom
      >
        { language === 'ja' ? (
          <>
            購入するボタンをクリックすると、
            <br/>
            <Link
              href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/termsofuse.html"
              color="textPrimary"
              underline="always"
              target="_blank"
            >
              利用規約
            </Link>
            ・
            <Link
              href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/pp.html"
              color="textPrimary"
              underline="always"
              target="_blank"
            >
              プライバシーポリシー
            </Link>
            に同意したことになります。
          </>
        ) : (
          <>
            By clicking Buy Now! button,
            <br/>
            you agree &nbsp;
            <Link
              href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/termsofuse.html"
              color="textPrimary"
              underline="always"
              target="_blank"
            >
              term of use
            </Link>
            &nbsp;/&nbsp;
            <Link
              href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/pp.html"
              color="textPrimary"
              underline="always"
              target="_blank"
            >
              privacy policy
            </Link>
            .
          </>
        )}
      </Typography>
    );

  }, [visitor]);

  // Shopify Buy Button SDKで購入ボタンを描画する
  // ただし、購入前にチェックしたいことがあるので、描画する購入はdisplay: noneにする(public/index.htmlのcss参照）
  // 同じボタンのHTMLを直書きしてボタンクリック時のチェックを終えたあと、描画したShopifyボタンをclick()する
  const buyButton = React.useMemo(() => {

    if (item === null) return null;

    // 環境に応じて購入ボタンを表示する
    const shopifyDomain = getShopifyDomain();
    const storefrontAccessToken = getShopifyStorefrontAccessToken();

    // 購入ボタンはShopify上の「購入ボタン」で作ったときのHTML/JSを参考に以下コピー
    const productId = item['shopifyProductId'];

    async function checkItemAvailable() {

      setPurchaseLoading(<Backdrop className={classes.purchaseLoading} open>
        <CircularProgress color="inherit" />
      </Backdrop>);

      const itemDoc = await getItem().catch(showErrorAlert);
      if (itemDoc && itemDoc.exists) {

        const client = Client.buildClient({
          domain: shopifyDomain,
          storefrontAccessToken: storefrontAccessToken
        });
        client.product.fetch(btoa(`gid://shopify/Product/${productId}`)).then((product) => {

          const vid = product.variants[0].id;
          const input = {
            lineItems: [
              {
                variantId: vid,
                quantity: 1
              }
            ],
            customAttributes: [
              {
                key: "data",
                value: JSON.stringify({
                  userId: visitor === null ? null : visitor.uid,
                  calendarDate: item['type'] === 'calendar' && item['calendarDateOption'] === 'next' ? calendarDate : null,
                })
              }
            ]
          };
          client.checkout.create(input).then((checkout) => {

            var checkOutUrl = checkout.webUrl;
            if (checkOutUrl.indexOf('?') === -1) {
              checkOutUrl += '?';
            } else {
              checkOutUrl += '&';
            }
            checkOutUrl += `locale=${window.navigator.language.split(/[-_]/)[0]}`;

            setPurchaseLoading(null);
            window.location.href = checkOutUrl;

          });

        });

      } else {

        alert(intl.formatMessage({ id: 'Not found item' }));
        window.location.reload();

      }

    };

    return (
      <>
        { productId ? (
          <>
            <div id="buyButton" />
            <button
              className="shopify-buy__btn_publy"
              onClick={checkItemAvailable}
            >
              <FormattedMessage id="Buy Now!" />
              <Typography
                component="div"
                variant="caption"
                className={classes.openShopify}
              >
                <FormattedMessage id="(Open Shopify)" />
              </Typography>
            </button>
            <div
              className={classes.paymentMethodWrap}
            >
              <img
                src={paymentMethod}
                className={classes.paymentMethod}
                alt="Payment methods are VISA, Mastercard, American Express, JCB, Shop Pay, Apple Pay, Google Pay"
              />
            </div>
            { termsOfUse }
          </>
        ) : (
          <Typography
            variant="h6"
            component="div"
            color="error"
            className={classes.underCheck}
          >
            <FormattedMessage id="Preparing to purchase" />
            <FormattedMessage id="(You can buy it soon)" />
          </Typography>
        )}
      </>
    );

  }, [item, calendarDate, getItem, showErrorAlert, visitor, intl, classes, termsOfUse]);

  // 画面表示時
  React.useEffect(() => {

    async function onload() {

      const getRequset = async (requestId) => {

        if (!requestId) return null;
        const doc = await firebase.firestore()
        .collection('requests')
        .doc(requestId)
        .get()
        .catch(showErrorAlert);
        if (doc && doc.exists) {
          return doc;
        } else {
          return null;
        }

      };

      const getAuthor = async () => {

        const doc = await firebase.firestore()
        .collection('profiles')
        .doc(userId)
        .get()
        .catch(showErrorAlert);
        if (doc && doc.exists) {
          return doc;
        } else {
          return null;
        }

      };

      const itemDoc = await getItem().catch(showErrorAlert);
      if (itemDoc && itemDoc.exists) {

        const itemData = itemDoc.data();
        const authorDoc = await getAuthor().catch(showErrorAlert);
        if (authorDoc && authorDoc.exists) {

          const authorData = authorDoc.data();

          // tagsのキーが重複するのでリネームしておく
          authorData['profileTags'] = authorData['tags'];
          delete authorData['tags'];
          setItem({
            ...itemData,
            ...authorData,
          });

        }

        // 一定時間経ってもまだShopifyのアイテム登録出来ていないなら再度作成する
        // ただしリクエスト作品はしない
        if (itemData
          && moment().diff(moment(itemData['createdAt'].toDate()), 'minutes') >= 1
          && !itemData['shopifyProductId']
          && !itemData['requestId']) {

          const createShopifyProductByItem = firebaseApp.functions('asia-northeast1').httpsCallable('createShopifyProductByItem');
          await createShopifyProductByItem({ profileId: userId, itemId: itemId })
          .catch(console.error);

        }

        // リクエスト作品
        const requestDoc = await getRequset(itemData['requestId']).catch(showErrorAlert);
        if (requestDoc) {

          const requestData = requestDoc.data();
          setRequest(requestData);
          if (requestData['itemType'] === 'calendarNoDate') {
            setCalendarDate(moment(requestData['calendarDate'].toDate()).format("YYYYMM"));
          }

        } else {
          setRequest(null);
        }

      }

    };

    onload();

  }, [userId, itemId, getItem, showErrorAlert]);

  // タグを持つ他のユーザを取得
  React.useEffect(() => {

    if (!tags || tags.length === 0) return;

    // 上限は現時点設けず
    // 一般公開されたら検討する
    firebase.firestore().collection('profiles')
      .where('tags', 'array-contains-any', tags)
      .orderBy('name', 'asc')
      // .limit(RELATED_ITEM_LIMIT)
      .get()
      .then(querySnapshot => {

        const docs = querySnapshot.docs;
        setRelatedCreators(docs.map(doc => {

          return {
            id: doc.id,
            ...doc.data()
          };

        }));

      })
      .catch(console.error);

  }, [userId, tags]);

  // 同一クリエーターの他の作品を取得
  React.useEffect(() => {

    if (!item || !item["profileImage"]) {
      return;
    }

    firebase.firestore().collection('profiles')
      .doc(userId)
      .collection('items')
      .where('isPublic', '==', true)
      .orderBy('createdAt', 'desc')
      .limit(RELATED_ITEM_LIMIT)
      .get()
      .then(querySnapshot => {

        const docs = querySnapshot.docs;
        setOtherItems(docs.map(doc => {

          const userId = doc.ref.parent.parent.id;
          return {
            id: doc.id,
            name: item["name"],
            profileImage: item["profileImage"],
            userId,
            ...doc.data()
          };

        }));

      })
      .catch(console.error);

  }, [item, userId]);

  // 同一タグのクリエーターの作品を取得
  React.useEffect(() => {

    if (!relatedCreators || relatedCreators.length === 0) return;

    firebase.firestore().collectionGroup('items')
      .where('tags', 'array-contains-any', tags)
      .where('isPublic', '==', true)
      .orderBy('createdAt', 'desc')
      .limit(RELATED_ITEM_LIMIT)
      .get()
      .then(querySnapshot => {

        const docs = querySnapshot.docs;
        setRelatedItems(docs.map(doc => {

          const userId = doc.ref.parent.parent.id;
          const relatedCreator = relatedCreators.find(e => e.id === userId)
          return {
            id: doc.id,
            name: relatedCreator ? relatedCreator['name'] : null,
            profileImage: relatedCreator ? relatedCreator['profileImage'] : null,
            userId,
            ...doc.data()
          };

        }));

      })
      .catch(console.error);

  }, [tags, relatedCreators]);

  // タグを設定しておく
  React.useEffect(() => {
    setTags((item && item['tags']) || null);
  }, [item]);

  React.useEffect(() => {

    // 掲載する画像のエリアを計算する
    // 横は半分にする(cols=2が該当）
    const calcAndSetColsAndCellHeight = () => {

      if (pageContainer === null || item === null/* || request === undefined*/) return;
      const width = pageContainer.clientWidth;
      const type = item['type'];
      if (type === 'calendar') {

        const cols = fullScreen ? 1 : 2;
        const height = (width / cols) * CALENDAR_PX.y / CALENDAR_PX.x;
        setColsAndCellHeight({
          cols: cols,
          cellHeight: height,
          spacing: 0,
        })

      } else {

        const cols = fullScreen ? 1 : 2;
        const height = ((width / cols) * A5_MAGAZINE_PX.y / A5_MAGAZINE_PX.x);
        setColsAndCellHeight({
          cols: cols,
          cellHeight: height,
          spacing: 4,
        });

      }

    };

    calcAndSetColsAndCellHeight();

  }, [fullScreen, item, pageContainer]);

  const itemImageCallback = React.useMemo(() => {

    return {
      onStartRenderingImage: onStartRenderingImage,
      onCompleteRenderingImage: onCompleteRenderingImage
    };

  }, [onStartRenderingImage, onCompleteRenderingImage]);

  return (

    <>
      <Header />
      { item === null && request === undefined ? (
        <LinearProgress color="secondary" />
      ) : (
        item['reportedReason'] || (!isRequestClient && !isAuthor && isPrivateItem) ? unpublishItem : (
          <>
            <Container
              maxWidth="sm"
              classes={{ root: classes.root }}
            >
              <Typography
                variant={fullScreen ? "h4" : "h3"}
                component="div"
                className={classes.title}
              >
                { request ? <FormattedMessage id="Request item" /> : item['title']}
              </Typography>
            </Container>
            <Container
              maxWidth="sm"
              classes={{ root: [classes.root, classes.descriptionContentsWrap].join(' ') }}
            >
              { request ? (
                <div
                  className={classes.descriptionContents}
                >
                  <>
                    {/*
                    <Typography
                      paragraph
                    >
                      <Avatar
                        src={getTwitterProfileImageOriginUrl(request['clientProfileImage'])}
                        classes={{ root: classes.avatar }}
                      />
                      <Link
                        underline="none"
                        target="_blank"
                        href={`https://twitter.com/${request['clientUsername']}`}
                        className={classes.name}
                      >
                        {request['clientName']}
                        <img
                          src={twitterSocialIconRoundedSquare}
                          alt="Twitter"
                          className={classes.twitterIcon}
                        />
                      </Link>
                    </Typography>
                    */}
                    <Typography
                      component="div"
                      variant="h6"
                      color="textPrimary"
                    >
                      <FormattedMessage id="Request message" />&nbsp;💎
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textPrimary"
                      className={classes.requestMessage}
                    >
                      {request['message']}
                    </Typography>
                  </>
                </div>
              ) : item['description'] && (
                <div
                  className={classes.descriptionContents}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.description}
                    color="textPrimary"
                  >
                    {item['description']}
                  </Typography>
                </div>
              )}
            </Container>
            <Container
              maxWidth="sm"
              classes={{ root: classes.root }}
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                className={classes.createdAt}
              >
                <FormattedMessage id="Created at: " />
                <FormattedMessage id="DD-MM, YYYY">
                  { format => (moment(item['createdAt'].toDate()).format(format[0])) }
                </FormattedMessage>
              </Typography>
              <GridList
                spacing={colsAndCellHeight.spacing}
                cellHeight={colsAndCellHeight.cellHeight}
                className={classes.gridList}
                cols={colsAndCellHeight.cols}
                ref={setPageContainer}
              >
                { item['pages'].map((page, index) => (
                  <GridListTile
                    key={page['remotePath']}
                    classes={{ root: classes.gridTileRoot, tile: classes.gridTile }}
                  >
                    <GridListTileBar
                      titlePosition="top"
                      title={
                        item['type'] === 'calendar' ? null : (
                            (index === 0 ? `1p - ${intl.formatMessage({ id: "Cover" })}` : (index === item['pages'].length - 1
                                ? `${index + 1}p - ${intl.formatMessage({ id: "Back cover" })}` : `${index + 1}p`))
                        )
                      }
                      classes={{
                        root: classes.gridTileBar,
                      }}
                    />
                    <ItemContext.Provider value={{
                      callbacks: itemImageCallback
                    }}>
                      <ItemImage item={item} page={page} calendarDate={calendarDate}  />
                    </ItemContext.Provider>
                  </GridListTile>
                ))}
              </GridList>
              { item['type'] === 'magazine' ? (
                <div
                  className={ classes.previewPage}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={openPagePreviewDialog}
                    classes={{ root: classes.button }}
                    startIcon={<MenuBook />}
                  >
                    <FormattedMessage id="View by opening page" />
                  </Button>
                </div>
              ) : null}
              { request === null && item['type'] === 'calendar' && item['calendarDateOption'] === 'next' ? (
                <FormControl
                  variant="filled"
                  classes={{ root: classes.calendarDateOption }}
                  disabled={isCalendarDateDisable}
                  fullWidth
                >
                  <InputLabel id="calendar-date-option">
                    <FormattedMessage id="Calendar date" />
                  </InputLabel>
                  <Select
                    labelId="calendar-date-option"
                    defaultValue={calendarDate}
                    onChange={handleChangeCalendarDate}
                  >
                    { getCalendarDateList().map((date, index) => (
                      <MenuItem
                        value={date}
                        key={"calendar-date-option-" + date}
                      >
                        <FormattedMessage id="MMM, YYYY">
                          { format => (moment(date).format(format[0])) }
                        </FormattedMessage>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <FormattedMessage id="Select date you want to purchase. Purchase is for one month only." />
                  </FormHelperText>
                </FormControl>
              ) : null}
              {/* 購入回数は非表示 */}
              { /*request === null && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="div"
                  classes={{ root: classes.purchaseCount }}
                  align="center"
                >
                  <div
                    className={classes.purchaseCountText}
                  >
                    <FormattedMessage id="Purchase count" />
                  </div>
                  <div
                    className={[classes.purchaseCountValue, classes.purchaseCountText].join(' ')}
                  >
                    {getPurchaseCountText(item['purchaseCount'])}
                  </div>
                </Typography>
              ) */}
              { request === null && (
                <>
                  <Typography
                    variant="h5"
                    component="div"
                    className={classes.priceDetail}
                  >
                    <FormattedMessage id="Price" />
                  </Typography>
                  <dl
                    className={classes.priceInfo}
                  >
                    <dt
                      className={classes.priceInfoRow1 + ' ' + classes.itemPrice}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                      >
                        <FormattedMessage id="Item price" />
                      </Typography>
                    </dt>
                    <dd
                      className={classes.priceInfoRow2 + ' ' + classes.itemPrice}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                      >
                        <FormattedMessage id="¥{ yen }" values={{ yen: Number(item['itemPrice']).toLocaleString() }} />
                      </Typography>
                    </dd>
                    <dt
                      className={classes.priceInfoRow1 + ' ' + classes.postage}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                      >
                        <FormattedMessage id="Shipping cost" />
                      </Typography>
                    </dt>
                    <dd
                      className={classes.priceInfoRow2 + ' ' + classes.postage}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                      >
                        <FormattedMessage id="¥{ yen }" values={{ yen: Number(item['itemPostage']).toLocaleString() }} />
                      </Typography>
                    </dd>
                    <dt
                      className={classes.priceInfoRow1 + ' ' + classes.totalPrice}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                      >
                        <b>
                          <FormattedMessage id="Total (included tax)" />
                        </b>
                      </Typography>
                    </dt>
                    <dd
                      className={classes.priceInfoRow2 + ' ' + classes.totalPrice}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                      >
                        <b>
                          <FormattedMessage id="¥{ yen }" values={{ yen: Number(item['totalPrice']).toLocaleString() }} />
                        </b>
                      </Typography>
                    </dd>
                  </dl>
                </>
              )}
            </Container>
            { request === null && (
              <Container
                maxWidth="sm"
                classes={{ root: classes.buyInfoWrap }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                  className={classes.buyInfo}
                >
                  <FormattedMessage id="It may take about up to 2 weeks after ordering. (Only in Japan)" />
                </Typography>
              </Container>
            )}
            <Container
              maxWidth="sm"
              classes={{ root: classes.root }}
            >
              { request === null ? (
                <>
                  { buyButton }
                  <Typography
                    component="div"
                    variant="body2"
                    gutterBottom
                  >
                    <Link
                      href="https://kunimasu.notion.site/f758e58a3ad348f1a7e3b558d9e1460a"
                      target="_blank"
                      underline="always"
                    >
                      <Help
                        className={classes.helpIcon}
                      />
                      <span
                        className={classes.helpText}
                      >
                        <FormattedMessage id="How to buy item?" />
                      </span>
                    </Link>
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    component="div"
                    variant="body2"
                    gutterBottom
                  >
                    <FormattedMessage id="Not sold because it is a requested item. If you favorite, you can request it to this creator." />
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleRequestThisCreator}
                  >
                    <FormattedMessage id="Request to this creator" />
                  </Button>
                </>
              )}
            </Container>
            { request === null && (
              <Container
                maxWidth="sm"
                classes={{ root: classes.root }}
              >
                <Link
                  underline="none"
                  className={classes.tweetButton}
                  href={`https://twitter.com/share?url=${window.location.href.replace('_item', 'item')}&hashtags=Publy&via=${item['username']}&related=${item['username']}&text=${item['title']} - Publy`}
                  target="_blank"
                >
                  <img
                    alt="Tweet"
                    height="40"
                    src={twitterSocialIconRoundedSquare}
                  />
                  <span
                    className={classes.tweetLink}
                  >
                    <FormattedMessage id="Tweet" />
                  </span>
                </Link>
                <Typography
                  variant="body2"
                  component="div"
                  classes={{ root: classes.letsShare }}
                 >
                  { <FormattedMessage id="Share this item!" /> }
                </Typography>
              </Container>
            )}
            <Container
              maxWidth="sm"
              classes={{ root: classes.authorContentsWrap }}
            >
              <Typography
                variant="subtitle1"
                component="div"
                classes={{ root: classes.authorContents }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  classes={{ root: classes.author }}
                  gutterBottom
                >
                  <FormattedMessage id="Author" />
                </Typography>
                <Avatar
                  src={getTwitterProfileImageOriginUrl(item['profileImage'])}
                  classes={{ root: classes.avatar }}
                />
                <Link
                  underline="none"
                  target="_blank"
                  href={`https://twitter.com/${item['username']}`}
                  className={classes.name}
                >
                  {item['name']}
                  &nbsp;
                  {item['verifiedUser'] === true && (
                    <Tooltip
                      title={<FormattedMessage id="Official" />}
                    >
                      <VerifiedUser
                        className={classes.verifiedUserIcon}
                      />
                    </Tooltip>
                  )}
                  <img
                    src={twitterSocialIconRoundedSquare}
                    alt="Twitter"
                    className={classes.twitterIcon}
                  />
                </Link>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="textSecondary"
                  className={classes.selfIntroduction}
                >
                  {item['selfIntroduction']}
                </Typography>
              </Typography>
            </Container>
            <Container
              maxWidth="sm"
              classes={{ root: classes.root }}
            >
              <Typography
                variant="h5"
                component="div"
                className={classes.actualImage}
              >
                <FormattedMessage id="Actual image" />
              </Typography>
              <Typography
                variant="body2"
                component="div"
                color="textSecondary"
                gutterBottom
              >
                { 'calendar' === item['type'] ? (
                  <FormattedMessage id="It is blindfolded so that delivery staff and family can't see item directly." />
                ) : (
                  <FormattedMessage id="It is blindfolded so that delivery staff and family can't see item directly." />
                )}
              </Typography>
              <div>
                <img
                  src={'calendar' === item['type'] ? finishedCalendarImage : finishedA5MagazineImage}
                  width="100%"
                  alt="Finished item"
                  className={classes.finishedImage}
                />
              </div>
            </Container>
            <Container
              maxWidth="sm"
            >
              <div>
                <Button
                  onClick={handleReport}
                  size="small"
                  classes={{ root: classes.report }}
                >
                  <FormattedMessage id="Report" />
                </Button>
              </div>
            </Container>
            { otherItems && otherItems.length > 0 && (
              <Container
                maxWidth="md"
                className={classes.recommendedItemsWrap}
              >
                <Typography
                  variant="h5"
                  component="div"
                >
                  <FormattedMessage id="We also recommend it" />
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  <span>
                    <FormattedMessage id="Other items by the same creator" />
                  </span>
                  <Link
                    onClick={handleSeeAllOtherItems}
                    classes={{ root: classes.seeAll }}
                    underline="always"
                  >
                    <FormattedMessage id="See all" />
                  </Link>
                </Typography>
                <div
                  className={classes.recommendedItems}
                  style={{ textAlign: (relatedItems.length > 4 || otherItems.length > 4) ? 'left' : 'center' }}
                >
                  { otherItems.map(otherItem => (
                    <div
                      key={otherItem['id']}
                      className={classes.recommendedItem}
                    >
                      <RecommendedItem
                        item={otherItem}
                      />
                    </div>
                  ))}
                </div>
              </Container>
            )}
            { relatedItems && relatedItems.length > 0 && (
              <Container
                maxWidth="md"
                className={classes.recommendedItemsWrap}
              >
                <Typography
                  variant="body1"
                  component="div"
                  classes={{ root: classes.relatedItems }}
                  gutterBottom
                >
                  <span>
                    <FormattedMessage id="Related creators' items" />
                  </span>
                  <Link
                    onClick={handleSeeAllRelatedItems}
                    classes={{ root: classes.seeAll }}
                    underline="always"
                  >
                    <FormattedMessage id="See all" />
                  </Link>
                  <Menu
                    id="menu-appbar"
                    anchorEl={tagMenuAnchorEl}
                    keepMounted
                    open={Boolean(tagMenuAnchorEl)}
                    onClose={handleCloseTagMenu}
                  >
                    {tags.map(tag => (
                      <MenuItem
                        key={tag}
                        onClick={() => handleClickTag(tag)}
                      >
                        {tag}
                      </MenuItem>
                    ))}
                  </Menu>
                </Typography>
                <div
                  className={classes.recommendedItems}
                  style={{ textAlign: (relatedItems.length > 4 || otherItems.length > 4) ? 'left' : 'center' }}
                >
                  { relatedItems.map(relatedItem => (
                    <div
                      key={relatedItem['id']}
                      className={classes.recommendedItem}
                    >
                      <RecommendedItem
                        item={relatedItem}
                      />
                    </div>
                  ))}
                </div>
              </Container>
            )}
            { relatedCreatorsWithoutMe && relatedCreatorsWithoutMe.length > 0 && (
              <Container
                maxWidth="md"
                className={classes.recommendedItemsWrap}
              >
                <Typography
                  variant="body1"
                  component="div"
                  classes={{ root: classes.relatedItems }}
                  gutterBottom
                >
                  <FormattedMessage id="Related creators" />
                </Typography>
                <div
                  className={classes.recommendedItems}
                  style={{ textAlign: relatedCreators.length > 8 ? 'left' : 'center' }}
                >
                  { relatedCreatorsWithoutMe.map(relatedCreator => (
                    <div
                      key={relatedCreator['id']}
                      className={classes.relatedCreator}
                    >
                      <Avatar
                        alt={relatedCreator['name']}
                        src={getTwitterProfileImageOriginUrl(relatedCreator['profileImage'])}
                        classes={{ root: classes.relatedCreatorAvatar }}
                        onClick={() => handleClickRelatedCreatorAvatar(relatedCreator) }
                      />
                    </div>
                  ))}
                </div>
              </Container>
            )}
            <Container
              maxWidth="md"
            >
              { isEditableItem && (
                <div
                  className={classes.own}
                >
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    <FormattedMessage id="Following is a menu that only creator can see." />
                  </Typography>
                  <div
                    className={classes.scope}
                  >
                    <FormControlLabel
                      control={<Switch checked={isPrivateItem} onChange={handleChangeScope} />}
                      label={<FormattedMessage id="Publish only to myself" />}
                    />
                    <Typography
                      variant="caption"
                      component="div"
                    >
                      { isPrivateItem ? <FormattedMessage id="No one but creator can see or buy item." />
                        : <FormattedMessage id="Anyone can see or buy item." /> }
                    </Typography>
                  </div>
                  <div
                    className={classes.delete}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleDelete}
                      startIcon={<Delete />}
                    >
                      <FormattedMessage id="Delete item" />
                    </Button>
                    <Typography
                      variant="caption"
                      component="div"
                      className={classes.deleteInfo}
                    >
                      <FormattedMessage id="Delete this item. This operation cannot be undone." />
                    </Typography>
                  </div>
                </div>
              )}
            </Container>
          </>
        )
      )}
      <Footer />
      { dialog }
      { purchaseLoading }
    </>

  );

}

export default injectIntl(Item);
