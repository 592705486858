import React from 'react';
import { Typography } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Link } from '@material-ui/core';
import publyLogo from './publyLogo.png';

import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({

  root: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: 'center',
  },
  logo: {
    height: 20,
    position: 'relative',
    top: 5,
    left: 5,
  },

}));

export default function NotFoundPage(props) {

  const classes = useStyles();

  return (

    <Grid container alignItems="center" justifyContent="center" classes={{ root: classes.root }}>
      <Container maxWidth="xs">
        <Typography
          variant="h5"
          paragraph
        >
          <FormattedMessage id="404 page not found" />
        </Typography>
        <Link
          href="/"
          color="textPrimary"
        >
          <FormattedMessage id="Go to Top" />
          <img
            src={publyLogo}
            className={classes.logo}
            alt="Publy"
          />
        </Link>
      </Container>
    </Grid>

  );

}
