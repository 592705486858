import React from 'react';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { GridList } from '@material-ui/core';
import { GridListTile } from '@material-ui/core';
import { GridListTileBar } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { Avatar } from '@material-ui/core';

import { Crop } from '@material-ui/icons';
import { Help } from '@material-ui/icons';
import { ArrowBackIos } from '@material-ui/icons';
import { ArrowForwardIos } from '@material-ui/icons';
import { CheckBox } from '@material-ui/icons';

import { red } from '@material-ui/core/colors';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import emojiRegex from "emoji-regex";
import moment from "moment";
import { MyPageContext } from './MyPage';
import InteractiveButton from "./InteractiveButton";
import CropImageDialog from "./CropImageDialog";
import { FormattedMessage, injectIntl } from "react-intl";
import { v4 as uuidv4 } from 'uuid';
import { getS3Bucket } from './DownloadFile';
import Dropzone from 'react-dropzone';
import twitterCardA5MagazinePreviewJa from './twitterCardA5MagazinePreview_ja.png';
import twitterCardA5MagazinePreviewEn from './twitterCardA5MagazinePreview_en.png';
import twitterCardCalendarPreviewJa from './twitterCardCalendarPreview_ja.png';
import twitterCardCalendarPreviewEn from './twitterCardCalendarPreview_en.png';
import twitterSocialIconRoundedSquare from "./twitterSocialIconRoundedSquare.png";
import AlertDialog from './AlertDialog';
import { CALENDAR_PX } from './PrintSize';
import { A5_MAGAZINE_PX } from './PrintSize';
import { formatBytes } from './Format';
import { toBlob } from './ImageUtil';
import { rotate } from './ImageUtil';
import { ITEM_MIN_PRICE } from './ItemUtil';
import { SERVICE_FEE } from './ItemUtil';
import { SUPPORT_IMG_FORMAT } from './ItemUtil';
import { ITEM_COST } from './ItemUtil';
import { ITEM_POSTAGE } from './ItemUtil';
import { ITEM_DEFAULT_PRICE } from './ItemUtil';
import { ITEM_PAGE_NUM } from './ItemUtil';
import { ITEM_HELP_URL } from './ItemUtil';
import { REQUEST_ITEM_HELP_URL } from './ItemUtil';
import { ITEM_IMAGE_HELP_URL } from './ItemUtil';
import { computeServiceFee } from './ItemUtil';
import { computeSales } from './ItemUtil';

const language = navigator.language.split(/[-_]/)[0];

export const CreateItemContext = React.createContext({callbacks: null});

// カレンダープレビュー時の幅
const CALENDAR_PREIVEW_IMAGE_WIDTH = 140;
const CALENDAR_PREIVEW_IMAGE_WIDTH_FULL_SCREEN = 100;

// ページに使う画像をキャッシュするときのPrefix
const PREFIX_PAGE_IMAGE_CACHE = "page";

// アップできるファイル最大サイズ
const MAX_UPLOAD_FILE_SIZE = 100 * 1024 * 1024;

const useStyles = makeStyles(theme => ({

  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  contents: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.textFieldBg,
  },
  textFieldWrap: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  textFieldMain: {
    backgroundColor: theme.palette.textFieldBg,
    display: 'inline-flex',
  },
  textFieldUnit: {
    display: 'inline-flex',
    marginLeft: 4,
    alignItems: 'flex-end',
  },
  calendarDateOption: {
    backgroundColor: theme.palette.textFieldBg,
    marginBottom: 4,
  },
  magazineBindingType: {
    backgroundColor: theme.palette.textFieldBg,
  },
  button: {
    color: 'white',
  },
  description: {
    marginTop: theme.spacing(1),
  },
  stepTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  helpLink: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  helpIcon: {
    position: 'relative',
    top: 3,
  },
  helpText: {
    position: 'relative',
    top: -3,
    left: theme.spacing(1),
  },
  infoArea: {
    padding: theme.spacing(1),
    marginBottom: 4,
    marginTop: 4,
    borderRadius: 6,
    backgroundColor: theme.palette.appBarBg,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  infoAreaImportant: {
    color: red[500],
  },
  importantArea: {
    paddingLeft: theme.spacing(2),
    color: red[500],
  },
  infoAreaRow: {
    lineHeight: 1.8,
  },
  importantAreaRow: {
    lineHeight: 1.8,
  },
  yourSalesTitle: {
    marginBottom: -theme.spacing(1),
  },
  yourSales: {
    color: red[500],
    fontWeight: 600,
    marginLeft: 4,
  },
  yourSalesOp: {
    padding: `0 ${theme.spacing(1)}px`,
    marginLeft: -20,
  },
  helpIcon2: {
    position: 'relative',
    top: 3,
    fontSize: 16,
  },
  moreDetailsText: {
    marginLeft: 4,
  },
  pageImageRoot: {
    position: 'relative',
  },
  noteList: {
    paddingLeft: 20,
    margin: 0,
  },
  gridList: {
    margin: 0,
    transform: 'translateZ(0)',
    width: '100%',
  },
  gridTile: {
    backgroundColor: theme.palette.imageBg,
  },
  gridTileRoot: {
    [theme.breakpoints.only('xs')]: {
      '&:nth-child(n+2)': {
        marginTop: 4,
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(n+3)': {
        marginTop: 2,
      },
    },
  },
  selectByClick: {
    position: 'relative',
    top: `calc(45%)`,
    left: 'calc(50% - 100px)',
    padding: theme.spacing(1),
    display: 'block',
    float: 'left',
    backgroundColor: '#EEEEEE',
    color: '#666666',
    borderRadius: 6,
    width: 200,
    textAlign: 'center',
  },
  blur: {
    color: 'white',
  },
  calendarPreview: {
    position: 'absolute',
    right: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(0),
      bottom: theme.spacing(0),
    },
    [theme.breakpoints.only('xs')]: {
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  },
  calendarImage: {
    backgroundColor: theme.palette.imageBg,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: CALENDAR_PREIVEW_IMAGE_WIDTH,
    display: 'block',
    [theme.breakpoints.only('xs')]: {
      boxShadow: '8px 8px 16px 0px #3f3f3f',
      width: CALENDAR_PREIVEW_IMAGE_WIDTH_FULL_SCREEN,
    },
    [theme.breakpoints.up('sm')]: {
      borderLeft: '1px solid #DCDCDC',
      borderTop: '1px solid #DCDCDC',
      borderRight: '1px solid #DCDCDC',
    },
  },
  calendarBaseImage: {
    backgroundColor: theme.palette.imageBg,
    width: CALENDAR_PREIVEW_IMAGE_WIDTH,
    display: 'block',
    [theme.breakpoints.only('xs')]: {
      boxShadow: '8px 8px 16px 0px #3f3f3f',
      width: CALENDAR_PREIVEW_IMAGE_WIDTH_FULL_SCREEN,
    },
    [theme.breakpoints.up('sm')]: {
      borderLeft: '1px solid #DCDCDC',
      borderBottom: '1px solid #DCDCDC',
      borderRight: '1px solid #DCDCDC',
    },
  },
  headerBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0.0) 100%)',
    paddingRight: theme.spacing(1),
    zIndex: 3,
  },
  footerBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    zIndex: 3,
  },
  footerTitleWrap: {
    display: 'none',
  },
  footerActionIcon: {
    margin: 'auto',
  },
  imageWrap: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    outline: 'none !important',
    position: 'absolute',
    zIndex: 0,

  },
  cutMarginImage: {
    opacity: 0.7,
    zIndex: 4,
    pointerEvents: 'none',
  },
  iconInInfo: {
    top: 2,
    position: 'relative',
    right: 2,
  },
  textInInfo: {
    position: 'relative',
    top: -4,
  },
  nextButton: {
  },
  backButtonWrap: {
    textAlign: 'center',
  },
  important: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    border: `1px solid ${red[500]}`,
    borderRadius: 6,
    marginBottom: theme.spacing(3),
    backgroundColor: 'white',
    boxShadow: '0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  agreeTerms: {
    color: red[500],
  },
  makeSureRead: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  finishedImage: {
    [theme.breakpoints.only('xs')]: {
      color: 'white',
    },
  },
  createButton: {
    borderRadius: '6px 6px 0 0',
  },
  managementIdGuide: {
    backgroundColor: 'blue',
    position: 'absolute',
    zIndex: 4,
    opacity: 0.7,
  },
  fieldHint: {
    // marginLeft: theme.spacing(1),
  },
  requestMessageContentsWrap: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  requestMessageContents: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: theme.palette.appBarBg,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      borderRadius: 6,
    },
  },
  requestMessage: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    clear: 'both',
    paddingTop: theme.spacing(1),
  },
  requestClientAvatar: {
    float: 'left',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  requestClientName: {
    color: 'black',
    marginLeft: theme.spacing(1),
    fontWeight: 100,
    fontSize: '1.3em',
  },
  twitterIcon: {
    width: 22,
    top: 3,
    position: 'relative',
  },
  requestMessageLabel: {
    clear: 'both',
  },
  twitterCard: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 12,
  },

}));

const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
const revokeObjectURL = (window.URL || window.webkitURL).revokeObjectURL || window.revokeObjectURL;

// 最大販売価格
const MAX_PRICE = 100000;

const getDefaultValidationMessages = () => {

  return {
    title: null,
    description: null,
    price: null
  };

};

// 画面表示用デフォルト
const getDefaultFiles = (num) => {

  const ret = [];
  for (let i = 0; i < num; i++) {
    ret.push({ file: null, blur: false });
  }
  return ret;

};

// 保存用デフォルト
const getDefaultFilesRef = (num) => {

  const ret = [];
  for (let i = 0; i < num; i++) {
    ret.push(getFilesRefDict());
  }
  return ret;

};

const getFilesRefDict = (_defaultValues) => {

  const defaultValues = _defaultValues || {};
  return {
    origin: defaultValues['origin'] || null,
    cropped: defaultValues['cropped'] || null,
    crop: defaultValues['crop'] || null,
    blur: defaultValues['blur'] || false
  };

};

// デフォルトの閉じ方向
const DEFAULT_BINDING_DIRECTION = 'left';

// 表示時にキャッシュしたページ画像のキャッシュを削除
// 表示に存在するキャッシュは、前回の分
const removeCachedImageAll = () => {

  for (const key in sessionStorage) {

    if (key.startsWith(PREFIX_PAGE_IMAGE_CACHE)) {
      sessionStorage.removeItem(key);
    }

  }

};

// 文字列オブジェクトならtrue
const isStringObject = (object) => {
  return (typeof object).toLowerCase() === 'string';
}

// カレンダーの日付画像を取得
const getNextMonth = () => {
  return moment().add(1, 'M');
};

const getCalendarDateImageUrl = (requestCalendarDate) => {

  const calendarDate = requestCalendarDate ? moment(requestCalendarDate.toDate()) : getNextMonth();
  const bucket = getS3Bucket(window.location.host);
  return `https://s3-ap-northeast-1.amazonaws.com/${bucket}/calendar_date/thumbnail/${calendarDate.format("YYYYMM")}.jpg`;

};

// 画像のキャッシュのキーを取得
const getPageImageCacheKey = (index) => {
  return `${PREFIX_PAGE_IMAGE_CACHE}-${index}-thumbnail`;
};

// ページの画像をキャッシュから取得
// キャッシュがなければ保存
const getCachedPageImage = (file, index) => {

  const key = getPageImageCacheKey(index);
  const cachedImage = sessionStorage.getItem(key);
  if (cachedImage) {
    return cachedImage;
  } else {

    const createdImage = isStringObject(file) ? file : createObjectURL(file);
    try {
      sessionStorage.setItem(key, createdImage);
    } catch(_) {}
    return createdImage;

  }

};

function CreateItem(props) {

  const { newItemType, request, intl } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));

  // リクエスト
  const requestId = React.useMemo(() => request ? request.id : null, [request]);
  const requestData = React.useMemo(() => request ? request.data() : null, [request]);

  // リクエスト or 新規
  const itemCost = React.useMemo(() => requestData ? requestData['itemCost'] : ITEM_COST[newItemType], [newItemType, requestData]);
  const serviceFee = React.useMemo(() => requestData ? requestData['serviceFee'] : SERVICE_FEE[newItemType], [newItemType, requestData]);

  // 共通
  const itemPostage = React.useMemo(() => ITEM_POSTAGE[newItemType], [newItemType]);
  const pageNum = React.useMemo(() => ITEM_PAGE_NUM[newItemType], [newItemType]);
  const itemMinPrice = React.useMemo(() => ITEM_MIN_PRICE[newItemType], [newItemType]);
  const itemDefaultPrice = ITEM_DEFAULT_PRICE[newItemType];
  const calendarPreviewImageWidth = fullScreen ? CALENDAR_PREIVEW_IMAGE_WIDTH_FULL_SCREEN : CALENDAR_PREIVEW_IMAGE_WIDTH;
  const helpUrl = (request ? REQUEST_ITEM_HELP_URL : ITEM_HELP_URL)[newItemType];
  const imageHelpUrl = ITEM_IMAGE_HELP_URL[newItemType];
  const defaultCalendarDateOption = React.useMemo(() => {

    if (requestData) {

      const itemType = requestData['itemType'];
      if (itemType === 'calendarWithDate') {
        return 'none';
      } else if (itemType === 'calendarNoDate') {
        return 'next';
      } else {
        return null;
      }

    } else {
      return newItemType === 'calendar' ? 'next' : null;
    }

  }, [newItemType, requestData]);

  // カレンダー
  // 日付部を含む画像か、来月の日付を自動生成するか
  const CALENDAR_DATE_OPTIONS = React.useMemo(() => {

    return [
      { label: intl.formatMessage({ id: 'Upload image without date' }), value: 'next'},
      { label: intl.formatMessage({ id: 'Upload image contains date' }), value: 'none'},
    ];

  }, [intl]);

  // State
  const [profile, setProfile] = React.useState(null);
  const [dialog, setDialog] = React.useState(null);
  const [sales, setSales] = React.useState(0);
  const [files, setFiles] = React.useState(getDefaultFiles(pageNum)); // 画面表示用
  const [validationMessages, setValidationMessages] = React.useState(getDefaultValidationMessages());
  const [calendarDateOption, setCalendarDateOption] = React.useState(defaultCalendarDateOption);
  const [colsAndCellHeight, setColsAndCellHeight] = React.useState(null);
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [bindingDirection, setBindingDirection] = React.useState(newItemType === 'magazine' ? DEFAULT_BINDING_DIRECTION : null);
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const [uploadedFileCount, setUploadedFileCount] = React.useState(0);
  const [cutMarginImage, setCutMarginImage] = React.useState(null); // カットマージン用
  const [managementIdGuideStyle, setManagementIdGuideStyle] = React.useState(null); // 管理ID用

  // Ref
  const itemTitleRef = React.useRef(null);
  const itemDescriptionRef = React.useRef(null);
  const itemPriceRef = React.useRef(null);
  const filesRef = React.useRef(getDefaultFilesRef(pageNum));
  const pageContainerRef = React.useRef(null);
  const currentUploadedFileCountRef = React.useRef(0);
  const cutMarginImageRef = React.useRef({ "magazine": null, "calendar": { "none": null, "next": null } });
  const profileRef = React.useRef(null);

  // Context
  const { callbacks } = React.useContext(MyPageContext);

  // 計算したサービスの手数料
  const serviceCost = React.useMemo(() => computeServiceFee(sales, itemPriceRef, itemMinPrice), [sales, itemMinPrice]);

  // カレンダーの日付オプションの選択
  const handleChangeCalendarDateOption = (event) => {
    setCalendarDateOption(event.target.value);
  };

  // 画面に変更がある場合はtrue
  const isModifiedScreen = React.useCallback(() => {

    return (itemTitleRef.current && itemTitleRef.current.value.length !== 0)
        || (itemDescriptionRef.current && itemDescriptionRef.current.value.length !== 0)
        || (itemPriceRef.current && parseInt(itemPriceRef.current.value) !== itemDefaultPrice)
        || isPrivate === true
        || (newItemType === 'magazine' && bindingDirection !== DEFAULT_BINDING_DIRECTION)
        // ぼかしのチェックは無関係（アップした時点で編集したと見なすので）
        || filesRef.current.filter(e => e.origin !== null).length !== 0;

  }, [itemDefaultPrice, isPrivate, bindingDirection, itemTitleRef, itemPriceRef, itemDescriptionRef, newItemType]);

  // 戻る
  const backToSelectItem = React.useCallback(() => {

    if (isModifiedScreen()) {

      if (window.confirm(intl.formatMessage({ id: 'Item you are creating will be disposed.' }))) {
        callbacks.backToSelectItem();
      }

    } else {
      callbacks.backToSelectItem();
    }

  }, [isModifiedScreen, intl, callbacks]);

  // ダイアログ閉じる
  const closeDialog = React.useCallback(() => {
    setDialog(null);
  }, []);

  // 画面表示用 fileRefを設定する
  const setFilesRef = React.useCallback((files) => {

    filesRef.current = files;
    setFiles(files.map(function(v) {

      return {
        file: v.cropped || v.origin,
        blur: v.blur || false
      };

    }));

  }, []);

  // 該当ページの画像入れ替え
  const replacePage = React.useCallback((update, index) => {

    sessionStorage.removeItem(getPageImageCacheKey(index));
    const nextFiles = Array.from(filesRef.current);
    nextFiles[index] = getFilesRefDict(update);
    return setFilesRef(nextFiles);

  }, [setFilesRef]);

  // クロップされた
  const cropCallback = React.useCallback((cropped, index, crop) => {

    closeDialog();

    const croppedFile = filesRef.current[index];
    return replacePage(
      {
        origin: croppedFile['origin'],
        cropped: cropped,
        crop: crop
      },
      index
    );

  }, [closeDialog, replacePage]);

  // クロップ
  const handleCrop = React.useCallback((index) => {

    setDialog(
      <CropImageDialog
        calendarDateOption={calendarDateOption}
        type={newItemType}
        index={index}
        file={createObjectURL(filesRef.current[index].origin)}
      />);

  }, [calendarDateOption, newItemType]);

  // ファイルダイアログ表示
  const showOpenFileDialog = React.useCallback(() => {

    return new Promise(resolve => {

      const input = document.createElement('input');
      input.type = 'file';
      input.accept = SUPPORT_IMG_FORMAT;
      input.multiple = 'magazine' === newItemType;
      input.onchange = event => { resolve(event.target.files); };
      input.click();

      // iOS Safariはイベント発火前にリソース開放して発火しないようなので
      // グローバルに保持しておく
      window.uploadInputFile = input;

    });

  }, [newItemType]);

  // 全画像を入れ替える
  const replaceAllPages = React.useCallback((files) => {

    files.forEach((_, index) => {
      sessionStorage.removeItem(getPageImageCacheKey(index));
    });

    const nextFiles = getDefaultFilesRef(pageNum);
    files.forEach((file, index) => {
      nextFiles[index]['origin'] = file;
    });

    return setFilesRef(nextFiles);

  }, [pageNum, setFilesRef]);

  // ぼかし変更
  const handleChangeBlur = React.useCallback((event, index) => {

    const nextFiles = Array.from(filesRef.current);
    nextFiles[index]['blur'] = event.target.checked;
    return setFilesRef(nextFiles);

  }, [setFilesRef]);

  // 順番入れ替え
  const pageMoveTo = React.useCallback((index, nextIndex) => {

    // キャッシュが残ると表示が更新されないので
    sessionStorage.removeItem(getPageImageCacheKey(index));
    sessionStorage.removeItem(getPageImageCacheKey(nextIndex));

    const nextFiles = Array.from(filesRef.current);
    const tmp = nextFiles[nextIndex];
    nextFiles[nextIndex] = nextFiles[index];
    nextFiles[index] = tmp;
    return setFilesRef(nextFiles);

  }, [setFilesRef]);

  // ページ画像選択された
  const chosenImage = React.useCallback(async (chosenFiles, pageIndex) => {

    // ファイルサイズが超えていたら追加しない
    var isContainsOverMaxFileSize = false;
    const files = Array.from(chosenFiles).slice(0, pageNum).filter((file) => {

      if (MAX_UPLOAD_FILE_SIZE > file.size) {
        return true;
      } else {
        isContainsOverMaxFileSize = true;
        return false;
      }

    });

    // ファイルサイズが大きすぎたらアラートする
    if (isContainsOverMaxFileSize) {

      setDialog(
        <AlertDialog
          message={intl.formatMessage(
            { id: 'File size that can be uploaded is up to { max }.' }, { max: formatBytes(MAX_UPLOAD_FILE_SIZE) }
          )}
          closeCallback={closeDialog}
        />
      );

    }

    if (files.length === 0) return;

    // ソートなし
    // ユーザがフォルダーで選んだ順（になるはず）
    if (newItemType === 'magazine' && files.length > 1) {

      if (window.confirm(intl.formatMessage({ id: 'All image are replaced to new. Is it okay?' }))) {
        return replaceAllPages(files);
      }

    } else if (newItemType === 'calendar' || files.length === 1) {

      // カレンダーなら先頭の1つのみ選ぶ
      return replacePage({ origin: files[0] }, pageIndex);
    }

  }, [closeDialog, intl, newItemType, pageNum, replaceAllPages, replacePage]);

  // ページ画像選択
  const handleChooseImage = React.useCallback(async (pageIndex) => {
    chosenImage(await showOpenFileDialog(), pageIndex);
  }, [chosenImage, showOpenFileDialog]);

  // 画像アップロード
  const uploadFile = React.useCallback((file, timestamp, crop) => {

    async function upload(img, timestamp, resolve, reject, revokeUrl) {

      let blob = null;
      if ('image/png' === file.type || crop) {

        // PNGかクロップした画像
        // クロップしたらExif回転を考慮する必要ないので
        blob = file;

      } else {

        // オリエンテーションを無視
        // Flipしているような写真はそのままに、縦にするだけ
        const canvas = rotate(img, 0);
        blob = await toBlob(canvas).catch(console.error);

      }

      const remotePath = ['users', firebase.auth().currentUser.uid, 'items', timestamp, uuidv4(), 'origin'].join('/');
      firebase
      .storage()
      .ref(remotePath)
      .put(blob)
      .on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {

        switch (snapshot.state) {
          case firebase.storage.TaskState.RUNNING:
            break;
          default:
            break;
        }

      }, function(error) {

        revokeObjectURL(revokeUrl);
        reject(error.code);

      }, function() {

        revokeObjectURL(revokeUrl);
        resolve(remotePath);

        currentUploadedFileCountRef.current += 1;
        setUploadedFileCount(currentUploadedFileCountRef.current);

      });

    };

    return new Promise(async (resolve, reject) => {

      const url = (isStringObject(file)) ? file : createObjectURL(file);
      const img = new Image();
      img.onload = async () => {
        upload(img, timestamp, resolve, reject, url);
      }
      img.src = url;

    });

  }, []);

  // 画像をアップロードする
  const uploadFiles = React.useCallback(async (files, crops) => {

    // 全画像同じ時刻のストレージにいれる
    const timestamp = Date.now();
    // 進捗報告で100%まで行ききらないのであえて少し待つ
    const remotePaths = await Promise.all(files.map((file, index) => uploadFile(file, timestamp, crops[index])))
    .catch(console.error);
    return new Promise((resolve) => {
      setTimeout(() => resolve(remotePaths), 800);
    });

  }, [uploadFile]);

  // 作成
  const handleCreate = React.useCallback(async (callback) => {

    // リクエスト作品の場合、まだキャンセルされていないか確認する
    if (request) {

      var isAllowToCreate = true;
      const latestRequestDoc = await firebase.firestore()
        .collection('requests')
        .doc(requestId)
        .get()
        .catch(console.error);
      // 存在しない
      if (!latestRequestDoc || !latestRequestDoc.exists) {
        isAllowToCreate = false;
      }
      // キャンセルされた
      const latestRequestData = latestRequestDoc.data();
      if (latestRequestData['cancelledAt']) {
        isAllowToCreate = false;
      }
      if (!isAllowToCreate) {

        setDialog(
          <AlertDialog
            title={intl.formatMessage({ id: 'Could not create item' })}
            message={intl.formatMessage({ id: 'Cannot be created because request was invalid or canceled' })}
            closeCallback={closeDialog}
          />
        );
        callback(false);
        return;

      }

    }

    const files = filesRef.current;
    const images = files.map(e => e.cropped || e.origin);
    const crops = files.map(e => e.crop);
    const blurs = files.map(e => e.blur);

    // リクエストならnullになる
    const title = requestData ? null : itemTitleRef.current.value.trim();
    const description = requestData ? null : itemDescriptionRef.current.value;
    const price = requestData ? requestData['price'] : itemPriceRef.current.value.trim();

    const validationMessages = getDefaultValidationMessages();

    // アップしたファイル数をリセットしておく
    currentUploadedFileCountRef.current = 0;

    // 何かエラーメッセージを設定したらtrue
    const isSetAnyValidationMessage = () => {
      return Object.values(validationMessages).filter(e => e !== null).length > 0;
    };

    // リクエストは入力チェック不要
    let focusField = null;
    if (!requestData) {

      if (title.length === 0) {

        focusField = itemTitleRef.current;
        validationMessages['title'] = intl.formatMessage({ id: 'Enter field' });

      }
      if (price.length === 0) {

        focusField = focusField || itemPriceRef.current;
        validationMessages['price'] = intl.formatMessage({ id: 'Enter field' });

      }

    }

    let isValid = true;
    let alertMessage = null;
    if (focusField === null && images.filter(e => e !== null).length !== images.length) {

      alertMessage = intl.formatMessage({ id: 'calendar' === newItemType ? 'Select image for calendar.' : 'Select all images to be posted on page.' });
      isValid = false;

    }
    if (isSetAnyValidationMessage()) {

      setValidationMessages(validationMessages);
      isValid = false;

    }
    if (focusField !== null) {
      focusField.focus();
    }

    // バリデーションはOKだけど絵文字を使っている場合は
    // このまま使うか確認する
    if (isValid) {

      const match = emojiRegex().exec(title);
      if (match !== null
        && match.length > 0
        && !window.confirm(intl.formatMessage({ id: 'Title contains emoji. It is garbled on Twitter card, make sure are you okay?' }))) {
        isValid = false;
      }

    }

    if (isValid) {

      const user = firebase.auth().currentUser;
      const profileId = user.uid;
      const remotePaths = await uploadFiles(images, crops);
      if (remotePaths.filter(e => e !== null).length === remotePaths.length) {

        const profileDoc = profileRef.current;
        if (!profileDoc || !profileDoc.exists) {
          return;
        }

        const profileData = profileDoc.data();
        const pages = remotePaths.map((remotePath, index) => {

          return {
            remotePath: remotePath,
            crop: crops[index],
            blur: blurs[index],
          };

        });

        const isPublic = requestData ? requestData['isPublic'] : !isPrivate;
        const intPrice = parseInt(price);

        const newItemDoc = firebase.firestore()
          .collection('profiles')
          .doc(profileId)
          .collection('items')
          .doc();
        const newItemId = newItemDoc.id;

        // まとめて追加・更新
        const batch = firebase.firestore().batch();
        if (request) {

          // リクエストの場合は入稿日をアップデート
          batch.update(
            request.ref,
            {
              submittedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }
          );

          // リクエストした時点でユーザは決済しているので
          // リクエスト作品を作成したら保留ステータスを解除する
          const orderId = requestData['orderId'];

          batch.update(
            firebase.firestore()
            .collection('orders')
            .doc(orderId),
            {
              itemId: newItemId,
            }
          );

        }

        const tags = profileData['tags'];
        batch.set(
          newItemDoc,
          {

            // 共通
            type: newItemType,
            pages,
            username: profileData['username'],
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            isPublic,
            serviceFee,
            itemCost,
            itemPrice: intPrice,
            itemPostage,
            totalPrice: intPrice + itemPostage,
            sales: requestData ? requestData['sales'] : sales,
            tags: tags ? tags.map(tag => tag.toLowerCase()) : null,

            // 作成時
            title,
            description,
            calendarDateOption,

            // 雑誌のみ
            bindingDirection,

            // リクエストのみ
            requestId,
            requestClientUserId: requestData ? requestData['clientUserId'] : null,
            requestClientName: requestData ? requestData['clientName'] : null,
            requestCreatorName: requestData ? requestData['creatorName'] : null,

          },
          {
            merge: true
          }
        );

        await batch.commit()
        .catch(error => {

          console.error(error);
          isValid = false;

        });

        if (isValid) {

          // 画面を離れて作成画面の描画は不要になるのでreturn
          callbacks.createdItem(profileId, newItemId, title, request);
          return;

        }

      } else {

        isValid = false;
        alertMessage = intl.formatMessage({ id: 'Failed to upload page images.' });

      }

    }

    callback(isValid);

    if (alertMessage !== null) {

      setDialog(
        <AlertDialog
          message={alertMessage}
          closeCallback={closeDialog}
        />
      );

    }

  }, [bindingDirection, calendarDateOption, callbacks, closeDialog,
    intl, isPrivate, itemCost, itemPostage, newItemType, sales, serviceFee, uploadFiles,
    request, requestId, requestData]);


  // 売上を計算する
  const computeAndSetSales = React.useCallback((price) => {
    setSales(computeSales(price, itemCost, serviceFee, itemMinPrice));
  }, [itemMinPrice, serviceFee, itemCost]);

  // 販売価格を変動したとき
  const handleChangePrice = React.useCallback((event) => {

    const price = parseInt(event.target.value.trim());
    event.target.value = isNaN(price) ? '' : Math.min(MAX_PRICE, price);
    computeAndSetSales(event.target.value);

  }, [computeAndSetSales]);

  // 販売価格からフォーカスを外したとき
  const handleBlurPrice = React.useCallback((event) => {

    const price = parseInt(event.target.value.trim());
    itemPriceRef.current.value = isNaN(price) ? '' : Math.max(itemMinPrice, price);
    computeAndSetSales(itemPriceRef.current.value);

  }, [itemMinPrice, computeAndSetSales]);

  // ページ 左に移動
  const handleMoveToLeft = React.useCallback((index) => {
    return pageMoveTo(index, index - 1);
  }, [pageMoveTo]);

  // ページ 左に移動
  const handleMoveToRight = React.useCallback((index) => {
    return pageMoveTo(index, index + 1);
  }, [pageMoveTo]);

  // R18の利用規約チェック
  const handleChangeAgreeTerms = React.useCallback(() => {
    setAgreeTerms(!agreeTerms);
  }, [agreeTerms]);

  // 公開非公開状態を変更する
  const handleChangeScope = React.useCallback(() => {
    setIsPrivate(!isPrivate);
  }, [isPrivate]);

  // 閉じ方向が左右変更する
  const handleChangeBindingDirection = React.useCallback((event) => {
    setBindingDirection(event.target.value);
  }, []);

  React.useEffect(() => {

    // アイテム選択したときにスクロールされているので
    window.scrollTo(0, 0);

    // キャッシュは削除した状態で表示
    removeCachedImageAll();

    return () => {

      // 保持しておいたので解放
      window.uploadInputFile = null;

    };

  }, []);

  React.useEffect(() => {

    window.onbeforeunload = function(e) {

      if (isModifiedScreen()) {
        e.returnValue = intl.formatMessage({ id: 'Item you are creating will be disposed.' });
      }

    }

    return () => {

      // 不要
      window.onbeforeunload = null;

    };

  }, [isModifiedScreen, intl]);

  React.useEffect(() => {
    computeAndSetSales(itemDefaultPrice);
  }, [itemDefaultPrice, computeAndSetSales]);

  React.useEffect(() => {

    // 掲載する画像のエリアを計算する
    // 横は半分にする(cols=2が該当）
    const calcAndSetColsAndCellHeight = () => {

      const width = pageContainerRef.current.clientWidth;
      if (newItemType === 'calendar') {

        if (calendarDateOption === 'none') {

          const cols = fullScreen ? 1 : 2;
          const height = (width / cols) * CALENDAR_PX.y / CALENDAR_PX.x;
          setColsAndCellHeight({
            cols: cols,
            cellHeight: height,
            spacing: 0
          })

        } else {

          const cols = fullScreen ? 1 : 2;
          const height = width / cols;
          setColsAndCellHeight({
            cols: cols,
            cellHeight: height,
            spacing: 0
          });

        }

      } else {

        const cols = fullScreen ? 1.0 : 2.0;
        const height = ((width / cols) * A5_MAGAZINE_PX.y / A5_MAGAZINE_PX.x);
        setColsAndCellHeight({
          cols: cols,
          cellHeight: height,
          spacing: 4
        })

      }

    };

    calcAndSetColsAndCellHeight();

  }, [calendarDateOption, fullScreen, newItemType]);

  React.useEffect(() => {

    // 管理ID部分のスタイルを取得
    function createMagazineManagementIdGuideStyle() {

      const uploadImageElm = getUploadImageElement();
      const width = uploadImageElm.clientWidth;
      const height = uploadImageElm.clientHeight;

      // 管理ID部分をガイドする
      const managementIdX = width * 0.943;
      const managementIdY = height * 0.940;
      const managementIdWidth = width * 0.052;
      const managementIdHeight = height * 0.047;
      setManagementIdGuideStyle({
        left: managementIdX,
        top: managementIdY,
        width: managementIdWidth,
        height: managementIdHeight,
      });

    }

    const getUploadImageElement = () => document.getElementsByClassName('uploadImage')[0];

    // キャンバスからURLを取得
    async function getObjectUrl(canvas) {
      return createObjectURL(await toBlob(canvas, 'image/png'));
    };

    // カットマージンの画像のキャンバス生成
    function createCutMarginImageCanvas(width, height, marginX, marginY, isNextTypeCalendar) {

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      context.clearRect(0, 0, width, height);

      // 長方形を使って枠線を描く
      context.fillStyle = 'rgba(255, 0, 0)';
      // 左
      context.fillRect(0, 0, marginX, height);
      // 上
      context.fillRect(0, 0, width, marginY);
      // 右
      context.fillRect(width - marginX, 0, marginX, height);

      // 下
      // 下はマガジンかカレンダー（type none）のみ
      if (!isNextTypeCalendar) {
        context.fillRect(0, height - marginY, width, marginY);
      }

      return canvas;

    };

    // カレンダー（type none）のマージン画像を生成
    async function createNoneTypeCalendarCutMarginImage() {

      let cutMarginImage = cutMarginImageRef.current['calendar']['none'];
      if (cutMarginImage === null) {

        const uploadImageElm = getUploadImageElement();
        const width = uploadImageElm.clientWidth;
        const height = uploadImageElm.clientHeight;
        if (width === 0 || height === 0) return;

        // トンボのアップ画像に対する割合
        const marginX = width * 0.030;
        const marginY = height * 0.016;
        const isNextTypeCalendar = false;
        cutMarginImage = await getObjectUrl(createCutMarginImageCanvas(width, height, marginX, marginY, isNextTypeCalendar));
        cutMarginImageRef.current['calendar']['none'] = cutMarginImage;

      }

      return cutMarginImage;

    };

    // カレンダー（type next）のマージン画像を生成
    async function createNextTypeCalendarCutMarginImage() {

      let cutMarginImage = cutMarginImageRef.current['calendar']['next'];
      if (cutMarginImage === null) {

        const uploadImageElm = getUploadImageElement();
        const width = uploadImageElm.clientWidth;
        const height = width;
        if (width === 0 || height === 0) return;

        // トンボのアップ画像に対する割合
        const marginX = width * 0.030;
        const marginY = height * 0.030;
        const isNextTypeCalendar = true;
        cutMarginImage = await getObjectUrl(createCutMarginImageCanvas(width, height, marginX, marginY, isNextTypeCalendar));
        cutMarginImageRef.current['calendar']['next'] = cutMarginImage;

      }

      return cutMarginImage;

    };

    // マガジンのマージン画像を生成
    async function createMagazineCutMarginImage(pageNum) {

      let cutMarginImage = cutMarginImageRef.current['magazine'];
      if (cutMarginImage === null) {

        const uploadImageElm = getUploadImageElement();
        const width = uploadImageElm.clientWidth;
        const height = uploadImageElm.clientHeight;
        if (width === 0 || height === 0) return;

        // トンボのアップ画像に対する割合
        const marginX = width * 0.0196;
        const marginY = height * 0.0137;
        const isNextTypeCalendar = false;
        cutMarginImage = await getObjectUrl(createCutMarginImageCanvas(width, height, marginX, marginY, isNextTypeCalendar));
        cutMarginImageRef.current['magazine'] = cutMarginImage;

      }

      return cutMarginImage;

    };

    // トンボのカットマージンをカレンダー用に作る
    async function createAndSetCalendarCutMarginImage(option) {

      if (option === 'none') {
        setCutMarginImage(await createNoneTypeCalendarCutMarginImage());
      } else {
        setCutMarginImage(await createNextTypeCalendarCutMarginImage());
      }

    };

    // トンボのカットマージンを示す画像を取得
    async function createAndSetCutMarginImage() {

      if (newItemType === 'calendar') {
        createAndSetCalendarCutMarginImage(calendarDateOption);
      } else {
        setCutMarginImage(await createMagazineCutMarginImage());
      }

    };

    createAndSetCutMarginImage();
    createMagazineManagementIdGuideStyle()

  }, [colsAndCellHeight, calendarDateOption, newItemType]);

  React.useEffect(() => {

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = backToSelectItem;
    return () => {
      window.onpopstate = null;
    };

  }, [backToSelectItem]);

  React.useEffect(() => {

    const user = firebase.auth().currentUser;
    async function _setProfile() {

      const profile = await firebase.firestore()
      .collection('profiles')
      .doc(user.uid)
      .get()
      .catch(console.error);
      if (profile && profile.exists) {

        profileRef.current = profile;
        setProfile(profile.data());

      }

    };

    _setProfile();

  }, []);

  // 現金支払いならtrue
  const isSalesCashPayment = React.useMemo(() => profile && profile['isSalesCashPayment'] === true, [profile]);

  return (
    <>
      <Container
        maxWidth="sm"
        classes={{ root: classes.root }}
      >
        <Typography
          classes={{ root: classes.stepTitle }}
          component="div"
          variant={fullScreen ? "h5" : "h4"}
          color="textPrimary"
        >
          { request ? (
            <FormattedMessage id="Create request item" />
          ) : (
            <>
              { newItemType === 'magazine' ? (
                <FormattedMessage id="Create magazine type" />
              ) : (
                <FormattedMessage id="Create calendar type" />
              )}
            </>
          )}
        </Typography>
      </Container>
      { request && (
        <Container
          maxWidth="sm"
          classes={{ root: [classes.root, classes.requestMessageContentsWrap].join(' ') }}
        >
          <div
            className={classes.requestMessageContents}
          >
            <Typography
              paragraph
            >
              <Avatar
                src={requestData['clientProfileImage']}
                classes={{ root: classes.requestClientAvatar }}
              />
              <Link
                underline="none"
                target="_blank"
                href={`https://twitter.com/${requestData['clientUsername']}`}
                className={classes.requestClientName}
              >
                {requestData['clientName']}
                <img
                  src={twitterSocialIconRoundedSquare}
                  alt="Twitter"
                  className={classes.twitterIcon}
                />
              </Link>
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color="textPrimary"
              classes={{ root: classes.requestMessageLabel }}
            >
              <FormattedMessage id="Request message" />&nbsp;💎
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="textPrimary"
              className={classes.requestMessage}
              paragraph
            >
              {requestData['message']}
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color="textPrimary"
            >
              <FormattedMessage id="Request item type" />
            </Typography>
            <Typography
              paragraph
            >
              {requestData['calendarDate'] && (
                <Typography
                  component="span"
                  color="textPrimary"
                  variant="body2"
                >
                  <FormattedMessage id="MMM, YYYY">
                    { format => (moment(requestData['calendarDate'].toDate()).format(format[0])) }
                  </FormattedMessage>
                  &nbsp;
                </Typography>
              )}
              <Typography
                component="span"
                color="textPrimary"
                variant="body2"
              >
                {requestData['itemType'] && requestData['itemType'] === 'magazine' ? (
                  <FormattedMessage id="Magazine" />
                ) : requestData['itemType'] === 'calendarWithDate' ? (
                  <FormattedMessage id="Calendar with date" />
                ) : (
                  <FormattedMessage id="Calendar with no date" />
                )}
              </Typography>
            </Typography>
            <Typography
              component="div"
              variant="h6"
              color="textPrimary"
            >
              <FormattedMessage id="Sales" />
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="textPrimary"
            >
              <FormattedMessage
                id="¥{ yen }"
                values={{ yen: Number(requestData['sales']).toLocaleString() }}
              />
            </Typography>
          </div>
        </Container>
      )}
      <Container
        maxWidth="sm"
        classes={{ root: classes.root }}
      >
        { request === null && (
          <>
            <div>
              <img
                src={ newItemType === 'magazine' ? (
                  language === 'ja' ? twitterCardA5MagazinePreviewJa : twitterCardA5MagazinePreviewEn
                ) : (
                  language === 'ja' ? twitterCardCalendarPreviewJa : twitterCardCalendarPreviewEn
                )}
                alt="Twiter Card"
                width="100%"
                className={classes.twitterCard}
              />
            </div>
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
            >
              <FormattedMessage id="When you create and tweet, it will be tweeted so that you can see what kind of item it is." />
            </Typography>
          </>
        )}
        <Typography
          classes={{ root: classes.helpLink }}
          component="div"
          variant="body2"
          color="textSecondary"
        >
          <Link
            href={helpUrl}
            underline="always"
            target="_blank"
          >
            <Help
              className={classes.helpIcon}
            />
            <span
              className={classes.helpText}
            >
              <FormattedMessage id="How to make?" />
            </span>
          </Link>
        </Typography>
        <div>
          { request === null && (
            <div className={classes.contents}>
              <FormattedMessage id="e.g. Children's book">
              { placeholder => (
                <TextField
                  variant="filled"
                  placeholder={placeholder[0]}
                  label={<FormattedMessage id="Title - up to 20 characters" />}
                  autoComplete="off"
                  inputRef={itemTitleRef}
                  inputProps={{
                    maxLength: 20,
                  }}
                  fullWidth
                  error={validationMessages.title != null}
                  helperText={validationMessages.title}
                  classes={{ root: classes.textField }}
                  required
                />
              )}
              </FormattedMessage>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                className={classes.fieldHint}
              >
                <FormattedMessage
                  id="It will be displayed on { text }."
                  values={{
                    text: [
                      intl.formatMessage({ id: 'Sales page' }),
                      intl.formatMessage({ id: 'Twitter card' })
                    ].join(', ')
                  }}
                />
              </Typography>
            </div>
          )}
          { request === null && (
            <div className={classes.contents}>
              <FormattedMessage id="e.g. Children's favorite vehicles, animals and food were drawn with a gentle touch!">
              { placeholder => (
                <TextField
                  variant="filled"
                  placeholder={placeholder[0]}
                  label={<FormattedMessage id="Descritpion - up to 1000 characters" />}
                  autoComplete="off"
                  inputRef={itemDescriptionRef}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  fullWidth
                  error={validationMessages.description != null}
                  helperText={validationMessages.description}
                  classes={{ root: classes.textField }}
                  multiline
                  rows={3}
                  rowsMax={10}
                />
              )}
              </FormattedMessage>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                className={classes.fieldHint}
              >
                <FormattedMessage
                  id="It will be displayed on { text }."
                  values={{
                    text: [
                      intl.formatMessage({ id: 'Sales page' }),
                      intl.formatMessage({ id: 'Twitter card' })
                    ].join(', ')
                  }}
                />
              </Typography>
            </div>
          )}
          { request === null && (
            <>
              <Typography
                component="div"
                variant="subtitle1"
                color="textPrimary"
                className={classes.yourSalesTitle}
              >
                <FormattedMessage id="Let's decide selling price for your item." />
              </Typography>
              <div
                className={classes.contents}
              >
                <FormattedMessage id="e.g. 500">
                { placeholder => (
                  <div
                    className={classes.textFieldWrap}
                  >
                    <TextField
                      variant="filled"
                      defaultValue={itemDefaultPrice}
                      placeholder={placeholder[0]}
                      label={<FormattedMessage id="Selling price including tax (Currency: yen)" />}
                      autoComplete="off"
                      inputRef={itemPriceRef}
                      inputProps={{
                        max: MAX_PRICE,
                        min: itemMinPrice,
                      }}
                      type="number"
                      fullWidth
                      error={validationMessages.price != null}
                      helperText={validationMessages.price}
                      classes={{ root: classes.textFieldMain }}
                      required
                      onChange={handleChangePrice}
                      onBlur={handleBlurPrice}
                    />
                    <Typography
                      component="div"
                      variant="body2"
                      color="textSecondary"
                      classes={{ root: classes.textFieldUnit }}
                    >
                      <FormattedMessage id="Yen" />
                    </Typography>
                  </div>
                )}
                </FormattedMessage>
                <div
                  className={classes.infoArea}
                >
                  <div>
                    <Typography
                      component="span"
                      variant="h6"
                      className={classes.yourSales}
                    >
                      <FormattedMessage id="Creator sales is " />
                    </Typography>
                    <Typography
                      className={classes.yourSales}
                      component="span"
                      variant="h6"
                    >
                      <FormattedMessage
                        id="¥{ yen }"
                        values={{
                          yen: Number(sales).toLocaleString()
                        }}
                      />
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                    >
                      <FormattedMessage
                        id="(Material cost and fee: ¥{ serviceCost })"
                        values={{
                          serviceCost: Number(serviceCost).toLocaleString()
                        }}
                      />
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textSecondary"
                      className={classes.infoAreaRow}
                    >
                      <ul
                        className={classes.noteList}
                      >
                        <li>
                          <FormattedMessage id="Selling price can be set between ¥{ itemMinPrice } and ¥{ itemMaxPrice }."
                            values={{
                              itemMinPrice: Number(itemMinPrice).toLocaleString(),
                              itemMaxPrice: Number(MAX_PRICE).toLocaleString()
                            }}
                          />
                        </li>
                        <li>
                          <FormattedMessage id="Buyer will pay postage when purchase." />
                        </li>
                        <li>
                          <FormattedMessage id="Creator sales can only you be seen." />
                        </li>
                      </ul>
                    </Typography>
                  </div>
                </div>
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                  className={classes.fieldHint}
                >
                  <FormattedMessage id="Selling price only, " />
                  <FormattedMessage
                    id="It will be displayed on { text }."
                    values={{
                      text: [
                        intl.formatMessage({ id: 'Sales page' }),
                        intl.formatMessage({ id: 'Twitter card' })
                      ].join(', ')
                    }}
                  />
                </Typography>
              </div>
            </>
          )}
          <div className={classes.contents}>
            { request === null && newItemType === 'calendar' && (
              <FormControl
                variant="filled"
                classes={{ root: classes.calendarDateOption }}
                fullWidth
              >
                <InputLabel id="calendar-image-type">
                  <FormattedMessage id="Calendar image" />
                </InputLabel>
                <Select
                  native
                  labelId="calendar-image-type"
                  defaultValue={calendarDateOption}
                  onChange={handleChangeCalendarDateOption}
                >
                  {CALENDAR_DATE_OPTIONS.map((option) => (
                    <option
                      value={option.value}
                      key={"calendar-" + option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText>
                  { calendarDateOption === 'next' ? (
                    <FormattedMessage id="You can upload image of content only. At time of purchase, user selects calendar date." />
                  ) : (
                    <FormattedMessage id="You should upload image including calendar. Purchaser will receive uploaded image as is." />
                  )}
                </FormHelperText>
              </FormControl>
            )}
            <div
              className={classes.pageImageRoot}
            >
              <GridList
                spacing={(colsAndCellHeight && colsAndCellHeight.spacing) || 0}
                cellHeight={(colsAndCellHeight && colsAndCellHeight.cellHeight) || 0}
                className={classes.gridList}
                cols={(colsAndCellHeight && colsAndCellHeight.cols) || 0}
                ref={pageContainerRef}
              >
              { files.map(( { file, blur }, index) => (
                <GridListTile
                  key={`page-${index.toString()}`}
                  classes={{ root: classes.gridTileRoot, tile: classes.gridTile }}
                >
                  <GridListTileBar
                    titlePosition="top"
                    title={
                      newItemType === 'calendar' ? (<FormattedMessage id="Calendar image" />) : (
                        (index === 0 ? `1p - ${intl.formatMessage({ id: "Cover" })}` : (index === files.length - 1
                          ? `${index + 1}p - ${intl.formatMessage({ id: "Back cover" })}` : `${index + 1}p`))
                      )
                    }
                    classes={{
                      root: classes.headerBar,
                    }}
                    actionIcon={
                      <>
                        { newItemType === 'calendar' || file === null ? null : (
                          index === 0 ? null : (
                            <>
                              <FormControlLabel
                                classes={{ root: classes.blur }}
                                control={
                                  <Checkbox
                                    checked={blur}
                                    onChange={async e => handleChangeBlur(e, index)}
                                    color="default"
                                    size="small"
                                    classes={{ root: classes.blur }}
                                  />
                                }
                                label={<FormattedMessage id="Blur" />}
                              />
                            </>
                          )
                        )}
                      </>
                    }
                  />
                  <Dropzone onDrop={(files) => chosenImage(files, index)} accept={SUPPORT_IMG_FORMAT}>
                    {({ getRootProps }) => (
                      <>
                        <div
                          className={classes.imageWrap + " " + classes.cutMarginImage}
                          style={{ backgroundImage: file === null ? "" : `url(${cutMarginImage})` }}
                        />
                        { file !== null && newItemType === 'magazine' && index === files.length - 1 ? (
                          <div
                            className={classes.managementIdGuide}
                            style={managementIdGuideStyle}>
                          </div>
                        ) : null }
                        <div
                          className={classes.imageWrap + " uploadImage" }
                          {...getRootProps({
                            onClick: () => handleChooseImage(index)
                          })}
                          style={{ backgroundImage: file === null ? "" : `url(${getCachedPageImage(file, index)})` }}
                        >
                          { file !== null ? null : (
                            <Grid
                              classes={{ root: classes.selectByClick }}
                            >
                              <Typography
                                component="div"
                                variant="body2"
                                color="textSecondary"
                              >
                                <FormattedMessage id="Select JPEG image by click" />
                              </Typography>
                              { newItemType === 'calendar' ? null : (
                                <Typography
                                  component="div"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  <FormattedMessage id={fullScreen ? "You can select multiple files at once" : "You can also drag and drop multiple files"} />
                                </Typography>
                              )}
                            </Grid>
                          )}
                        </div>
                      </>
                    )}
                  </Dropzone>
                  { file === null ? null : (
                    <GridListTileBar
                      classes={{
                        root: classes.footerBar,
                        titleWrap: classes.footerTitleWrap,
                        actionIcon: classes.footerActionIcon
                      }}
                      actionIcon={
                        <>
                          <IconButton
                            classes={{ root: classes.button }}
                            onClick={() => handleCrop(index)}
                          >
                            <Crop />
                          </IconButton>
                          { newItemType === 'calendar' ? null : (
                            <>
                              <IconButton
                                size="small"
                                classes={{ root: classes.button }}
                                disabled={index === 0}
                                onClick={async () => handleMoveToLeft(index)}
                              >
                                <ArrowBackIos />
                              </IconButton>
                              <IconButton
                                size="small"
                                classes={{ root: classes.button }}
                                disabled={index === files.length - 1}
                                onClick={async () => handleMoveToRight(index)}
                              >
                                <ArrowForwardIos />
                              </IconButton>
                            </>
                          )}
                        </>
                      }
                    />
                  )}
                </GridListTile>
              ))}
              </GridList>
              { newItemType === 'calendar'
                  // && calendarDateOption !== 'none'
                  && files[0]['file'] ? (
                <div
                  className={classes.calendarPreview}
                >
                  <div
                    id="calendarPreviewImage"
                    className={classes.calendarImage}
                    style={{ height: calendarDateOption === 'none' ? calendarPreviewImageWidth * (CALENDAR_PX.y / CALENDAR_PX.x) : calendarPreviewImageWidth,
                             backgroundImage: files[0]['file'] === null ? "" : `url(${getCachedPageImage(files[0]['file'], 0)})` }}
                  />
                  { calendarDateOption === 'none' ? null : (
                    <img
                      src={getCalendarDateImageUrl(requestData ? requestData['calendarDate'] : null)}
                      className={classes.calendarBaseImage}
                      alt="calendar"
                    />
                  )}
                  <Typography
                    component="div"
                    variant="caption"
                    align="center"
                    classes={{ root: classes.finishedImage }}
                  >
                    <FormattedMessage id="Finished image" />
                  </Typography>
                </div>
              ) : null }
            </div>
            <div
              className={classes.infoArea}
            >
              <Typography
                component="div"
                variant="body2"
                color="textSecondary"
                paragraph
              >
                <ul
                  className={classes.noteList}
                >
                  <li
                    className={classes.infoAreaRow}
                  >
                    <FormattedMessage id="If using CMYK images, colors may change depending on browser." />
                  </li>
                  <li
                    className={classes.infoAreaRow}
                  >
                    { newItemType === 'calendar' ? (
                      <>
                        { calendarDateOption === 'none' ? (
                          <FormattedMessage id="Minimum size of calendar image is 1200 x 2242px or above. If it is smaller than this, image may be rough." />
                        ) : (
                          <FormattedMessage id="Minimum size of calendar image is 1200 x 1200px or above. If it is smaller than this, image may be rough." />
                        )}
                      </>
                    ) : (
                      <FormattedMessage id="Minimum size of magazine image is 1800 x 2570px or above. If it is smaller than this, image may be rough." />
                    )}
                  </li>
                  <li
                    className={classes.infoAreaRow}
                  >
                    <Crop
                      fontSize="small"
                      className={classes.iconInInfo}
                    />
                    <span
                      className={classes.textInInfo}
                    >
                      <FormattedMessage id="Crop and rotate image." />
                    </span>
                  </li>
                  { newItemType === 'calendar' ? null : (
                    <li
                      className={classes.infoAreaRow}
                    >
                      <CheckBox
                        fontSize="small"
                        className={classes.iconInInfo}
                      />
                      <span
                        className={classes.textInInfo}
                      >
                        <FormattedMessage id="By selecting [Blur] check box, you can blur page image in purchase page. Check when you want to show clearly only to buyer." />
                      </span>
                    </li>
                  )}
                  <li
                    className={classes.infoAreaRow + " " + classes.infoAreaImportant}
                  >
                    <FormattedMessage id="Bleeding guidelines are displayed in red frame in uploaded image preview. Area inside red frame is discarded during bookbinding process, so avoid important content." />
                  </li>
                  { newItemType === 'calendar' ? null : (
                    <li
                      className={classes.infoAreaRow + " " + classes.infoAreaImportant}
                    >
                      <FormattedMessage id="QR code for item management will be automatically assigned to bottom right of back cover, so avoid to include important content. Uploaded image preview is marked in blue." />
                    </li>
                  )}
                </ul>
              </Typography>
              <Link
                href={imageHelpUrl}
                underline="always"
                target="_blank"
              >
                <Help
                  className={classes.helpIcon2}
                  size="small"
                />
                <Typography
                  className={classes.moreDetailsText}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  <FormattedMessage id="More details" />
                </Typography>
              </Link>
            </div>
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              className={classes.fieldHint}
            >
              <FormattedMessage
                id="It will be displayed on { text }."
                values={{
                  text: (request ? [
                    intl.formatMessage({ id: 'Item' }),
                  ] : [
                    intl.formatMessage({ id: 'Item' }),
                    intl.formatMessage({ id: 'Sales page' }),
                    intl.formatMessage({ id: newItemType === 'calendar' ? 'Twitter card' : 'Twitter card (Only cover page)' })
                  ]).join(', ')
                }}
              />
            </Typography>
          </div>
          { request === null && (
            <div
              className={classes.contents}
            >
              <FormControlLabel
                control={<Switch checked={isPrivate} onChange={handleChangeScope} />}
                label={<FormattedMessage id="Publish only to myself" />}
              />
              <Typography
                variant="caption"
                component="div"
              >
                { isPrivate ? <FormattedMessage id="No one but creator can see or buy item." /> : <FormattedMessage id="Anyone can see or buy item." /> }
              </Typography>
            </div>
          )}
          { newItemType === 'magazine' ? (
            <div
              className={classes.contents}
            >
              <FormControl
                variant="filled"
                classes={{ root: classes.calendarDateOption }}
                fullWidth
              >
                <InputLabel id="magazine-binding-type">
                  <FormattedMessage id="Binding direction" />
                </InputLabel>
                <Select
                  labelId="magazine-binding-type"
                  defaultValue={bindingDirection}
                  onChange={handleChangeBindingDirection}
                >
                  <MenuItem
                    value="left"
                    key={"magazine-binding-left"}
                  >
                    <FormattedMessage id="Use left binding" />
                  </MenuItem>
                  <MenuItem
                    value="right"
                    key={"magazine-binding-right"}
                  >
                    <FormattedMessage id="Use right binding" />
                  </MenuItem>
                </Select>
                <FormHelperText>
                  <FormattedMessage id="It seems that there are many right binding when content contains vertical characters, and left binding when content contains horizontal characters. If image is main content, you can choose one of them as you like." />
                </FormHelperText>
              </FormControl>
              <Link
                href="https://factory-books.pixiv.help/hc/ja/articles/360000289521-%E5%8F%B3%E7%B6%B4%E3%81%98%E3%81%A8%E5%B7%A6%E7%B6%B4%E3%81%98%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                underline="always"
                target="_blank"
              >
                <Help
                  className={classes.helpIcon2}
                  size="small"
                />
                <Typography
                  className={classes.moreDetailsText}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  <FormattedMessage id="Click here for details on binding direction." />
                </Typography>
              </Link>
            </div>
          ) : null }
          <Typography
            component="div"
            variant="body2"
            classes={{ root: classes.important }}
          >
            <ul
              className={classes.importantArea}
            >
              <li
                className={classes.importantAreaRow}
              >
                <Typography
                  component="div"
                  variant="body2"
                  gutterBottom
                  classes={{ root: classes.agreeTerms }}
                >
                  <FormattedMessage id="Color may change because it is converted to CMYK when printing." />
                </Typography>
              </li>
              <li
                className={classes.importantAreaRow}
              >
                <Typography
                  component="div"
                  variant="body2"
                  gutterBottom
                  classes={{ root: classes.agreeTerms }}
                >
                  <FormattedMessage id="Items should not include sexual, violent or discriminatory content or infringement of rights." />
                </Typography>
              </li>
              <li
                className={classes.importantAreaRow}
              >
                <Typography
                  component="div"
                  variant="body2"
                  classes={{ root: classes.agreeTerms }}
                >
                  { isSalesCashPayment ? (
                    <FormattedMessage id="Sales will be given in cash. You can check your current sales in My Account." />
                  ) : (
                    <FormattedMessage id="Sales will be granted with Amazon gift point of ¥1 = 1 point. You can confirm current Amazon gift point on My account." />
                  )}
                </Typography>
              </li>
              { request && (
                <li
                  className={classes.importantAreaRow}
                >
                  <Typography
                    component="div"
                    variant="body2"
                    classes={{ root: classes.agreeTerms }}
                  >
                    <FormattedMessage id="Once requested item is created, it cannot be deleted or changed." />
                  </Typography>
                </li>
              )}
            </ul>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={agreeTerms}
                    onChange={handleChangeAgreeTerms}
                  />
                }
                label={
                  <Typography
                    component="div"
                    variant="body2"
                  >
                    <FormattedMessage id="Agree and create about above" />
                  </Typography>
                }
              />
            </Grid>
          </Typography>
          <div className={classes.contents}>
            <div
              className={classes.nextButton}
            >
              <InteractiveButton
                fullWidth={true}
                disabled={!agreeTerms}
                text={<FormattedMessage id="Create" />}
                clickHandler={handleCreate}
                buttonClasses={{ root: classes.createButton }}
              />
            </div>
            <LinearProgress variant="determinate" value={uploadedFileCount / pageNum * 100} />
          </div>
          <div
            className={classes.contents + ' ' + classes.backButtonWrap}
          >
            <Button
              variant="contained"
              onClick={backToSelectItem}
            >
              <FormattedMessage id="Back" />
            </Button>
          </div>
        </div>
        <CreateItemContext.Provider value={{ callbacks: { closeDialog: closeDialog, cropCallback: cropCallback } }}>
          { dialog }
        </CreateItemContext.Provider>
      </Container>
    </>
  );

}

export default injectIntl(CreateItem);
