import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import { List } from '@material-ui/icons';
import { Public } from '@material-ui/icons';

import { TwitterFollowButton } from 'react-twitter-embed';
import { TwitterDMButton } from 'react-twitter-embed';

import Footer from "./Footer";
import Header from "./Header";
import ItemListDialog from "./ItemListDialog";
import SelectItem from "./SelectItem";
import CreateItem from "./CreateItem";
import AccountSettingsDialog from "./AccountSettingsDialog";
import PurchaseHistoryDialog from "./PurchaseHistoryDialog";
import NewsDialog from "./NewsDialog";
import RequestSettingsDialog from "./RequestSettingsDialog";
import RequestInboxDialog from "./RequestInboxDialog";
import SentRequestDialog from "./SentRequestDialog";
import AlertDialog from "./AlertDialog";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const useStyles = makeStyles(theme => ({

  mainContainer: {
    textAlign: 'center',
    marginTop: `${theme.spacing(3)}px`,
  },
  mainContainerTitle: {
    marginBottom: 4,
  },
  mainContainerBody: {
    marginBottom: theme.spacing(2),
  },
  myPage: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
  },
  twitterEmbed: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
  welcome: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.appBarBg,
    [theme.breakpoints.up('sm')]: {
      borderRadius: 6,
    },
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  welcomeText: {
    fontWeight: 'bold',
  },
  welcomeOkButton: {
    textDecoration: 'underline',
  },
  welcomeRowTitle: {
    fontWeight: 'bold',
  },
  welcomeDivider: {
    margin: theme.spacing(2, 0),
  },
  createItemButton: {
    marginTop: '0.35em',
    textDecoration: 'underline',
  },
  openRequestSettingsButton: {
    textDecoration: 'underline',
    marginTop: '0.35em',
  },
  myItemsButtonWrap: {
    marginTop: theme.spacing(1),
  },

}));

export const MyPageContext = React.createContext({ callbacks: null });

function MyPage(props) {

  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));

  // State
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [dialog, setDialog] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isWelcomeShown, setWelcomeShown] = React.useState(Boolean(localStorage.getItem('welcomeShown')));
  const [isDisplayWelcome, setDisplayWelcome] = React.useState(false);

  // Ref
  const newItemTypeRef = React.useRef(null);
  const uploadRequestRef = React.useRef(null);

  // アイテム選択に戻る
  const handleBackSelectItem = React.useCallback(() => {
    setActiveStep(0);
  }, []);

  // ダイアログを閉じる
  const closeDialog = React.useCallback(() => {
    setDialog(null);
  }, []);

  // Welcomeを閉じる
  const handleClickCloseWelcome = React.useCallback(() => {

    setWelcomeShown(true);
    localStorage.setItem('welcomeShown', true);

  }, []);

  // アイテム作成完了
  const handleCreatedItem = React.useCallback((profileId, itemId, itemName, request) => {

    handleBackSelectItem();

    const title = intl.formatMessage({ id: 'Created successfully 🎉' });
    const message = <Link
        target="_blank"
        href={`/items/${profileId}/${itemId}`}
      >
        { request ? (
          <FormattedMessage id="Go to page of corresponding request" />
        ) : (
          <FormattedMessage id="Go to purchase page of [{ itemName }]" values={{ itemName: itemName }} />
        )}
      </Link>
    setDialog(
      <AlertDialog
        title={title}
        message={message}
        closeCallback={closeDialog}
      />
    );

  }, [intl, handleBackSelectItem, closeDialog]);

  // リクエストアップロード
  const handleUploadRequest = React.useCallback((requestId, itemType) => {

    firebase.firestore()
    .collection('requests')
    .doc(requestId)
    .get()
    .then((doc) => {

      if (doc.exists) {

        newItemTypeRef.current = itemType;
        uploadRequestRef.current = doc;
        setActiveStep(1);

      }

    })
    .catch(console.error);

    closeDialog();
    setActiveStep(0);

  }, [closeDialog]);

  // マガジン作成
  const handleNewMagazineItem = React.useCallback(() => {

    newItemTypeRef.current = 'magazine';
    uploadRequestRef.current = null;
    setActiveStep(1);

  }, []);

  // カレンダー作成
  const handleNewCalendarItem = React.useCallback(() => {

    newItemTypeRef.current = 'calendar';
    uploadRequestRef.current = null;
    setActiveStep(1);

  }, []);

  // アイテムリスト表示
  const showItemListDialog = React.useCallback(() => {
    setDialog(<ItemListDialog />);
  }, []);

  // 作成したアイテム一覧
  const handleExplorePublishItems = React.useCallback(() => {
    window.open(`/explore/@${profile.username}`, '_blank');
  }, [profile]);

  // 作成したアイテム一覧
  const handleMyItems = React.useCallback(() => {
    showItemListDialog();
  }, [showItemListDialog]);

  // アカウント設定表示
  const showAccountSettingsDialog = React.useCallback((additionalUserInfo) => {
    setDialog(<AccountSettingsDialog additionalUserInfo={additionalUserInfo} />);
  }, []);

  // 購入履歴表示
  const showPurchaseHistoryDialog = React.useCallback(() => {
    setDialog(<PurchaseHistoryDialog />);
  }, []);

  // お知らせ表示
  const showNewsDialog = React.useCallback(() => {
    setDialog(<NewsDialog />);
  }, []);

  // リクエスト設定画面表示
  const showRequestSettingsDialog = React.useCallback(() => {
    setDialog(<RequestSettingsDialog />);
  }, []);

  // 受けたリクエスト画面表示
  const showRequestInboxDialog = React.useCallback(() => {
    setDialog(<RequestInboxDialog />);
  }, []);

  // 送ったリクエスト画面表示
  const showSentRequestDialog = React.useCallback(() => {
    setDialog(<SentRequestDialog />);
  }, []);

  // マイアカウントが選択された
  const handleMyAccount = React.useCallback(() => {
    showAccountSettingsDialog();
  }, [showAccountSettingsDialog]);

  // リクエスト設定が選択された
  const handleRequestSettings = React.useCallback(() => {
    showRequestSettingsDialog();
  }, [showRequestSettingsDialog]);

  // 受けたリクエストが選択された
  const handleRequestInbox = React.useCallback(() => {
    showRequestInboxDialog();
  }, [showRequestInboxDialog]);

  // 送ったリクエストが選択された
  const handleSentRequest = React.useCallback(() => {
    showSentRequestDialog();
  }, [showSentRequestDialog]);

  // 購入履歴が選択された
  const handlePurchaseHistory = React.useCallback(() => {
    showPurchaseHistoryDialog();
  }, [showPurchaseHistoryDialog]);

  // お知らせが選択された
  const handleNews = React.useCallback(() => {
    showNewsDialog();
  }, [showNewsDialog]);

  // ログアウト
  const signout = React.useCallback(() => {
    firebase.auth().signOut().catch(console.error);
  }, []);

  // ログアウトが選択された
  const handleSignOut = React.useCallback(() => {
    signout();
  }, [signout]);

  // 認証後トップ
  const mainContents = React.useMemo(() => {

    return profile && (
      <>
        <main>
          { activeStep > 0 ? null : (
            <>
              <Container
                maxWidth="sm"
                classes={{ root: classes.mainContainer }}
              >
                <Typography
                  variant={fullScreen ? "h3" : "h2"}
                  className={classes.myPage}
                >
                  <FormattedMessage id="My Page" />
                </Typography>
              </Container>
              { isDisplayWelcome && !isWelcomeShown && (
                <Container
                  maxWidth="sm"
                  classes={{ root: [classes.mainContainer, classes.welcome].join(' ') }}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    classes={{ root: classes.welcomeText }}
                  >
                    <FormattedMessage id="Welcome&nbsp;🎉" />
                  </Typography>
                  <Divider
                    classes={{ root: classes.welcomeDivider }}
                    light
                  />
                  <div>
                    <Typography
                      variant="body1"
                      component="div"
                      classes={{ root: classes.welcomeRowTitle }}
                      align="left"
                      gutterBottom
                    >
                      <FormattedMessage id="Share your item on SNS" />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                    >
                      <FormattedMessage id="First, let's create calendar or magazine." />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                    >
                      <FormattedMessage id="Select item you want to make and set name, selling price, image, etc of item." />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                      gutterBottom
                    >
                      <FormattedMessage id="When you created item, let's share its sales page on SNS." />
                    </Typography>
                    <div>
                      <Button
                        onClick={handleNewCalendarItem}
                        classes={{ root: classes.createItemButton }}
                      >
                        <FormattedMessage id="Create calendar type" />
                      </Button>
                      <Button
                        onClick={handleNewMagazineItem}
                        classes={{ root: classes.createItemButton }}
                      >
                        <FormattedMessage id="Create magazine type" />
                      </Button>
                    </div>
                  </div>
                  <Divider
                    classes={{ root: classes.welcomeDivider }}
                    light
                  />
                  <div>
                    <Typography
                      variant="body1"
                      component="div"
                      classes={{ root: classes.welcomeRowTitle }}
                      align="left"
                      gutterBottom
                    >
                      <FormattedMessage id="Get fans requests" />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                    >
                      <FormattedMessage id="First, let's create request reception screen and share it on SNS." />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                    >
                      <FormattedMessage id="You can accept requests from fans such as I want this kind of item." />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                    >
                      <FormattedMessage id="When you receive request, check content and amount of request and create as much as you can." />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                      gutterBottom
                    >
                      <FormattedMessage id="After you created, we will deliver product to fans requested." />
                    </Typography>
                    <Button
                      classes={{ root: classes.openRequestSettingsButton }}
                      onClick={showRequestSettingsDialog}
                    >
                      <FormattedMessage id="Open request settings" />
                    </Button>
                  </div>
                  <Divider
                    classes={{ root: classes.welcomeDivider }}
                    light
                  />
                  <div>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowBody }}
                      align="left"
                      gutterBottom
                    >
                      <FormattedMessage id="Let's set your profile from My Account and register your email address in News." />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.welcomeRowTitle }}
                      align="center"
                      paragraph
                    >
                      <FormattedMessage id="Have fun Publy&nbsp;👍" />
                    </Typography>
                  </div>
                  <Button
                    onClick={handleClickCloseWelcome}
                    classes={{ root: classes.welcomeOkButton }}
                  >
                    <FormattedMessage id="OK!" />
                  </Button>
                </Container>
              )}
              <Container
                maxWidth="sm"
                classes={{ root: classes.mainContainer }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  className={classes.mainContainerTitle}
                >
                  <FormattedMessage id="Created item" />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.mainContainerBody}
                >
                  <FormattedMessage id="You can check created item here." />
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleExplorePublishItems}
                    classes={{ root: classes.button }}
                    startIcon={<Public />}
                  >
                    <FormattedMessage id="Explore published items" />
                  </Button>
                </div>
                <div
                  className={classes.myItemsButtonWrap}
                >
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleMyItems}
                    classes={{ root: classes.button }}
                    startIcon={<List />}
                  >
                    <FormattedMessage id="My items" />
                  </Button>
                </div>
              </Container>
              <Container
                maxWidth="sm"
                classes={{ root: classes.mainContainer }}
              >
                <Typography
                  variant="h5"
                  className={classes.mainContainerTitle}
                >
                  <FormattedMessage id="New item" />
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.mainContainerBody}
                >
                  <FormattedMessage id="Select item you want to make." />
                </Typography>
              </Container>
            </>
          )}
          {[
            <SelectItem />,
            <CreateItem
              newItemType={newItemTypeRef.current}
              request={uploadRequestRef.current}
            />
          ][activeStep]}
          { activeStep > 0 ? null : (
            <Container
              classes={{ root: classes.twitterEmbed }}
            >
              <Typography
                component="div"
                variant="body1"
                classes={{ root: classes.agreementWrap }}
                align="center"
              >
                <FormattedMessage id="Follow our official account." />
              </Typography>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
              >
                <TwitterFollowButton
                  screenName="publyfun"
                />
              </Grid>
              <Typography
                component="div"
                variant="body1"
                align="center"
              >
                <FormattedMessage id="For inquiries, please use DM or marshmallow." />
              </Typography>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
              >
                <TwitterDMButton
                  id={1259466717123579905}
                />
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
              >
                <Link
                  href="https://marshmallow-qa.com/publyfun"
                  target="_blank"
                >
                  https://marshmallow-qa.com/publyfun
                </Link>
              </Grid>
            </Container>
          )}
        </main>
      </>
    );

  }, [
    profile, activeStep, classes, fullScreen, handleMyItems, handleClickCloseWelcome,
    isWelcomeShown, handleNewCalendarItem, handleNewMagazineItem, showRequestSettingsDialog,
    isDisplayWelcome, handleExplorePublishItems
  ]);

  // ヘッダー
  const header = React.useMemo(() => {
    return (<Header />);
  }, []);

  // フッター
  const footer = React.useMemo(() => {
    return (<Footer />);
  }, []);

  React.useEffect(() => {
    setDisplayWelcome(profile ? profile['isEnabledRequestFunction'] : false)
  }, [profile]);

  React.useEffect(() => {

    const _setProfile = async (userId) => {

      const doc = await firebase.firestore()
      .collection('profiles')
      .doc(userId)
      .get()
      .catch(console.error);
      if (doc && doc.exists) {
        setProfile(doc.data());
      }

    };

    const goTop = () => {

      window.history.pushState(null, null, '/');
      window.location.href = '/';

    };

    firebase.auth().onAuthStateChanged(async function(user) {

      setUser(user);
      if (user === null) {
        goTop();
      } else {
        _setProfile(user.uid);
      }

    });

  }, []);

  // コールバック
  const callbacks = React.useMemo(() => {

    return {
      closeDialog,
      newMagazineItem: handleNewMagazineItem,
      newCalendarItem: handleNewCalendarItem,
      createdItem: handleCreatedItem,
      backToSelectItem: handleBackSelectItem,
      handleMyItems,
      handleExplorePublishItems,
      handleMyAccount,
      handleRequestSettings,
      handleRequestInbox,
      handleSentRequest,
      handlePurchaseHistory,
      handleNews: handleNews,
      handleSignOut: handleSignOut,
      handleUploadRequest,
    };

  }, [closeDialog, handleNewMagazineItem, handleNewCalendarItem,
    handleCreatedItem, handleBackSelectItem, handleMyItems, handleMyAccount,
    handlePurchaseHistory, handleNews,
    handleRequestSettings, handleRequestInbox, handleSentRequest,
    handleSignOut, handleUploadRequest, handleExplorePublishItems
  ]);

  return (

    <MyPageContext.Provider value={{ callbacks, profile }}>

      { user === null || profile === null ? <LinearProgress color="secondary" /> : (
        <>
          { header }
          { mainContents }
          { footer }
          { dialog }
        </>
      )}

    </MyPageContext.Provider>

  );

}

export default injectIntl(MyPage);
