import moment from "moment";

// アイテムの最低販売価格
export const ITEM_MIN_PRICE = {
  magazine: 450,
  calendar: 260,
}

// 管理費
// 1.0 = 100%
export const SERVICE_FEE = {
  magazine: 0.07,
  calendar: 0.07,
}

// PNGは透明部分の処理でPDFにしたときにくすんだ白になるのでサポートしない
// const SUPPORT_IMG_FORMAT = 'image/png, image/jpeg';
export const SUPPORT_IMG_FORMAT = 'image/jpeg';

// アイテムの原価
export const ITEM_COST = {
  magazine: 419,
  calendar: 242,
}

// アイテムの送料
export const ITEM_POSTAGE = {
  magazine: 150,
  calendar: 150,
}

// アイテムの初期販売価格
export const ITEM_DEFAULT_PRICE = {
  magazine: 740,
  calendar: 400,
}

// 写真掲載数
export const ITEM_PAGE_NUM = {
  magazine: 12,
  calendar: 1,
}

// 通常アイテムの作り方のリンク
export const ITEM_HELP_URL = {
  magazine: 'https://kunimasu.notion.site/f9a0f1d09b7340d5a1dc6b564026ff00',
  calendar: 'https://kunimasu.notion.site/30e89066e0684dbba8ab3dd8f321971e',
}

// リクエストアイテムの作り方のリンク
export const REQUEST_ITEM_HELP_URL = {
  magazine: 'https://kunimasu.notion.site/f9a0f1d09b7340d5a1dc6b564026ff00',
  calendar: 'https://kunimasu.notion.site/30e89066e0684dbba8ab3dd8f321971e',
}

// 作り方の画像についてのリンク
export const ITEM_IMAGE_HELP_URL = {
  magazine: 'https://kunimasu.notion.site/f9a0f1d09b7340d5a1dc6b564026ff00#02afd9dd1e434ca3aa2bccacb31e90d8',
  calendar: 'https://kunimasu.notion.site/30e89066e0684dbba8ab3dd8f321971e#428a2d9069ee48aa8f4064c8b502c31a',
}

  // 売上を計算する
export const computeSales = (price, itemCost, serviceFee, itemMinPrice) => {

  const isTooLowPrice = isNaN(price) || price < itemMinPrice;
  const fee = Math.floor(price * serviceFee);
  return isTooLowPrice ? 0 : Math.max(0, price - itemCost - fee);

};

// 手数料を計算する
export const computeServiceFee = (sales, priceRef, itemMinPrice) => {

  if (sales === null || priceRef.current === null) return 0;
  const price = parseInt(priceRef.current.value);
  return (isNaN(price) || price < itemMinPrice) ? itemMinPrice : price - sales;

};


// 選択できるカレンダーの日付を取得
export function getCalendarDateList() {

  // year/monthまでカレンダーを作成できる
  const dateList = [];
  for (let m = 0; m < 12; m++) {

    const date = moment().add(m, 'M');
    const year = date.year();
    const month = date.month() + 1;

    // 対応した年まで
    if (year <= 2023) {
      dateList.push(year + ('00' + month).slice(-2));
    }

  }
  return dateList.map((v) => String(v));

};

// 各タイプのアイテムのリクエストを受け付けるならtrue
export const isCheckedAcceptingCalendarWithDate = (acceptingItem) => (acceptingItem & 0b1) !== 0;
export const isCheckedAcceptingCalendarNoDate = (acceptingItem) => (acceptingItem & 0b10) !== 0;
export const isCheckedAcceptingMagazine = (acceptingItem) => (acceptingItem & 0b100) !== 0;
