
// タッチイベントのある端末はhover要素を無効にする
export function disableHoverEventOnMobile() {

  const touch = 'ontouchstart' in document.documentElement
    || navigator.maxTouchPoints > 0
    | navigator.msMaxTouchPoints > 0;
  if (touch) {
    try {
      for (var si in document.styleSheets) {
        const styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;
        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;
          if (styleSheet.rules[ri].selectorText.match(':hover')) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    } catch (ex) {}
  }

};
