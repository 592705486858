import React from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import ItemList from "./ItemList";
import { FormattedMessage } from "react-intl";
import { MyPageContext } from "./MyPage";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const useStyles = makeStyles(theme => ({

  contents: {
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  emptyItems: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  help: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 3),
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  helpText: {
    position: 'relative',
    top: -7,
    left: 4,
  },

}));

const QUERY_LIMIT = 3 /* cols */ * 4 /* rows */;

export default function ItemListDialog(props) {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [items, setItems] = React.useState(null);

  // Ref
  const itemsRef = React.useRef([]);
  const lastVisibleItemRef = React.useRef(null);
  const isLoadedToEndRef = React.useRef(false);
  const isLoadingRef = React.useRef(false);
  const scrollElementRef = React.useRef(null);

  // Callback
  const { callbacks } = React.useContext(MyPageContext);

  const setItemsRef = React.useCallback((next) => {

    itemsRef.current = itemsRef.current.concat(next);
    setItems(itemsRef.current);

  }, []);

  const queryItems = React.useCallback(() => {

    isLoadingRef.current = true;
    const user = firebase.auth().currentUser;

    let query = firebase.firestore()
    .collection('profiles')
    .doc(user.uid)
    .collection('items')
    .orderBy('createdAt', 'desc');
    if (lastVisibleItemRef.current) {
      query = query.startAfter(lastVisibleItemRef.current)
    }
    query.limit(QUERY_LIMIT)
    .get()
    .then(querySnapshot => {

      const docs = querySnapshot.docs;

      lastVisibleItemRef.current = docs[docs.length - 1];

      if (docs.length < QUERY_LIMIT) {
        isLoadedToEndRef.current = true;
      }

      setItemsRef(docs.map(doc => {

        const data = doc.data();
        return {
          id: doc.id,
          userId: user.uid,
          page: data['pages'][0],
          ...data
        };

      }));

    })
    .catch(console.error)
    .finally(() => {
      isLoadingRef.current = false;
    });

  }, [setItemsRef]);

  // 無限スクロール
  const endlessScroll = React.useCallback(() => {

    const scrollElement = scrollElementRef.current;
    if (Math.round(scrollElement.clientHeight + scrollElement.scrollTop) >= scrollElement.scrollHeight
      && !isLoadedToEndRef.current
      && !isLoadingRef.current) {
      queryItems();
    }

  }, [queryItems]);

  // 無限スクロール解除
  const removeEndlessScrollListener = React.useCallback(() => {

    if (scrollElementRef.current !== null) {
      scrollElementRef.current.removeEventListener('scroll', endlessScroll);
    }

  }, [endlessScroll]);

  React.useEffect(() => {

    queryItems();
    return () => {
      removeEndlessScrollListener();
    }

  }, [queryItems, removeEndlessScrollListener]);

  const setScrollElement = React.useCallback((el) => {

    // 無限スクロール登録
    const addEndlessScrollListener = () => {
      scrollElementRef.current.addEventListener('scroll', endlessScroll);
    };

    if (el !== null && scrollElementRef.current === null) {

      scrollElementRef.current = el;
      addEndlessScrollListener();

    }

  }, [endlessScroll]);

  React.useEffect(() => {

    // アイテム作成画面のpopstateを保持しておく
    // 閉じるときに再設定がいるので
    const prevPopstate = window.onpopstate || null;

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = callbacks.closeDialog;
    return () => {
      window.onpopstate = prevPopstate;
    };

  }, [callbacks]);

  return (

    <>
      <Dialog
        maxWidth="lg"
        fullScreen={fullScreen}
        open
        onClose={callbacks.closeDialog}
      >
        <DialogTitle>
          <FormattedMessage id="My items" />
        </DialogTitle>
        { items === null ? (
          <LinearProgress color="secondary" />
        ) : (
          <DialogContent
            ref={setScrollElement}
            classes={{ root: classes.contents }}
          >
            { items !== null && items.length === 0 ? (
              <Typography
                variant="body2"
                component="div"
                className={classes.emptyItems}
              >
                <FormattedMessage id="There is no items." />
              </Typography>
            ) : (
              <>
                <Typography
                  component="div"
                  variant="body2"
                  gutterBottom
                  classes={{ root: classes.help }}
                >
                  <Link
                    href="https://kunimasu.notion.site/2d4897b11e6f452d99e0a1c78966cf64"
                    target="_blank"
                    underline="always"
                  >
                    <Help
                      className={classes.helpIcon}
                    />
                    <span
                      className={classes.helpText}
                    >
                      <FormattedMessage id="How to management of items?" />
                    </span>
                  </Link>
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  { items && items.map(item => <ItemList key={item['id']} item={item} forMyList />) }
                </Grid>
              </>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={callbacks.closeDialog}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );

}
