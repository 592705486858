import React from 'react';
import { Typography } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { FormattedMessage } from "react-intl";
import publyLogo from './publyLogo.png';

const useStyles = makeStyles(theme => ({

  root: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: 'center',
  },
  logo: {
    height: 20,
    textAlign: 'center',
  },

}));

export default function MaintenancePage(props) {

  const { maintenanceValue } = props;

  const classes = useStyles();

  // State
  const [value, setValue] = React.useState(-3009);

  const confirmPassword = React.useCallback(() => {

    if (window.prompt('Enter Password') === maintenanceValue) {

      sessionStorage.setItem('mainteanceLogin', maintenanceValue);
      window.location.reload();

    }

  }, [maintenanceValue]);

  const handleMaintenance = React.useCallback(() => {

    if (value === 0) return;
    setValue(value + 1000);

  }, [value]);

  const handleLogo = React.useCallback(() => {

    if (value === 0) confirmPassword();
    else setValue(value + 1);

  }, [value, confirmPassword]);

  return (

    <Grid container alignItems="center" justifyContent="center" classes={{ root: classes.root }}>
      <Container maxWidth="xs">
        <Typography
          variant="h5"
          paragraph
          onClick={handleMaintenance}
        >
          <FormattedMessage id="Currently undergoing maintenance" />
        </Typography>
        <img
          src={publyLogo}
          className={classes.logo}
          onClick={handleLogo}
          alt="Publy"
        />
      </Container>
    </Grid>

  );

}
