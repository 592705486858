import React from 'react';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { RotateLeft } from '@material-ui/icons';
import { RotateRight } from '@material-ui/icons';
import { Visibility } from '@material-ui/icons';

import { CreateItemContext } from './CreateItem';
import InteractiveButton from "./InteractiveButton";
import { FormattedMessage } from "react-intl";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import moment from "moment";
import { getS3Bucket } from './DownloadFile';
import { CALENDAR_PX } from './PrintSize';
import { A5_MAGAZINE_PX } from './PrintSize';

import { toBlob } from './ImageUtil';
import { rotate } from './ImageUtil';

const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
const revokeObjectURL = (window.URL || window.webkitURL).revokeObjectURL || window.revokeObjectURL;

const useStyles = makeStyles(theme => ({

  title: {
    paddingBottom: 0,
  },
  root: {
    paddingTop: 0,
  },
  preview: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
  previewImageWrap: {
    textAlign: 'center',
    fontSize: 0,
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      float: 'right',
      width: '50%',
    },
  },
  previewImage: {
    display: 'inline-block',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '65%',
    },
  },
  previewCropped: {
    borderLeft: '1px solid gray',
    borderTop: '1px solid gray',
    borderRight: '1px solid gray',
  },
  previewCroppedFull: {
    borderBottom: '1px solid gray',
  },
  previewCalendar: {
    borderLeft: '1px solid gray',
    borderBottom: '1px solid gray',
    borderRight: '1px solid gray',
  },
  crop: {
    backgroundColor: theme.palette.imageBg,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
  },
  previewButton: {
    flex: 1,
  },

}));

// カレンダーの日付画像を取得
const getNextMonth = () => {
  return moment().add(1, 'M');
};

const getCalendarDateImageUrl = () => {

  const calendarDate = getNextMonth().format("YYYYMM");
  const bucket = getS3Bucket(window.location.host);
  return `https://s3-ap-northeast-1.amazonaws.com/${bucket}/calendar_date/thumbnail/${calendarDate}.jpg`;

};

const getCroppedImg = async (image, crop) => {

  const canvas = document.createElement('canvas');

  const dpr = window.devicePixelRatio || 1;
  canvas.width = crop.width * dpr;
  canvas.height = crop.height * dpr;

  const ctx = canvas.getContext('2d');
  ctx.scale(dpr, dpr);

  const scaleX = Math.max(1, image.naturalWidth / image.width);
  const scaleY = Math.max(1, image.naturalHeight / image.height);

  ctx.fillStyle = 'white';
  ctx.fillRect(
    0, 0, canvas.width, canvas.height
  );
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );
  return toBlob(canvas);

};

const handleGoPreview = () => {

  document.getElementById("scrollContent").scroll({
    top: document.body.clientHeight,
    behavior: "smooth"
  });

};

const getDefaultCrop = (type, calendarDateOption) => {

  return {
    aspect: (function() {
      if (type === 'calendar') {
        return calendarDateOption && calendarDateOption === 'none' ? CALENDAR_PX.x / CALENDAR_PX.y : 1;
      } else {
        return A5_MAGAZINE_PX.x / A5_MAGAZINE_PX.y;
      }

    }()),
    width: 100,
  };

};

export default function CropImageDialog(props) {

  const { type, index, file, calendarDateOption } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [crop, setCrop] = React.useState(getDefaultCrop(type, calendarDateOption));
  const [srcImage, setSrcImage] = React.useState(null);
  const [croppedImage, setCroppedImage] = React.useState(null);
  const [isRotating, setIsRotating] = React.useState(false);

  // Ref
  const imageRef = React.useRef(null);
  const cropRef = React.useRef(null);
  const rotateDegreesRef = React.useRef(0);

  // Context
  const { callbacks } = React.useContext(CreateItemContext);

  // Crop
  const handleCrop = React.useCallback((callback) => {

    const crop = cropRef.current;
    const cropX = crop.x / 100.0;
    const cropY = crop.y / 100.0;
    const cropWidth = crop.width / 100.0;
    const cropHeight = crop.height / 100.0;

    async function _cropImage(img) {

      const srcW = img.width;
      const srcH = img.height;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const x = Math.round(srcW * cropX);
      const y = Math.round(srcH * cropY);
      const w = Math.round(srcW * cropWidth);
      const h = Math.round(srcH * cropHeight);

      canvas.width = w;
      canvas.height = h;

      ctx.clearRect(0, 0, w, h);
      ctx.fillStyle = 'white';
      ctx.fillRect(
        0,
        0,
        canvas.width,
        canvas.height
      );
      ctx.save();
      ctx.drawImage(
        img,
        x,
        y,
        w,
        h,
        0,
        0,
        canvas.width,
        canvas.height
      );
      ctx.restore();

      const blob = await toBlob(canvas).catch(console.error);
      callbacks.cropCallback(blob, index, cropRef.current);
      callback(true);

    }

    const img = new Image();
    img.onload = async function(e) {

      const rotateDegrees = rotateDegreesRef.current;
      if (rotateDegrees === 0) {
        _cropImage(img);
      } else {
        _cropImage(rotate(img, rotateDegrees));
      }

    }
    img.src = file;

  }, [callbacks, index, file]);

  // 画像ロードした
  const handleLoaded = React.useCallback((image) => {

    imageRef.current = image;
    return true;

  }, []);

  // クロップ移動
  const handleChange = React.useCallback((crop, percentCrop) => {
    setCrop(crop);
  }, []);

  const makeCrop = React.useCallback(async (crop) => {

    if (imageRef.current && crop.width && crop.height) {

      const croppedImage = await getCroppedImg(
        imageRef.current,
        crop
      )
      .catch(console.error);
      if (croppedImage) {
        setCroppedImage(croppedImage);
      }

    }

  }, []);

  // クロップ確定
  const handleComplete = React.useCallback((crop, percentCrop) => {

    makeCrop(crop);
    cropRef.current = percentCrop;

  }, [makeCrop]);

  // 画像を回転
  const _rotate = React.useCallback((rotation) => {

    if (isRotating) return;

    const img = new Image();
    img.onload = async function() {

      const canvas = rotate(img, rotation);
      const blob = await toBlob(canvas).catch(console.error);
      if (blob) {

        revokeObjectURL(srcImage);
        setSrcImage(createObjectURL(blob));

      }
      setIsRotating(false);
      setCrop(getDefaultCrop(type, calendarDateOption));

    }
    img.src = srcImage || file;
    setIsRotating(true);

  }, [isRotating, file, srcImage, calendarDateOption, type]);

  // 画像を左回転する
  const handleRotateLeft = React.useCallback(() => {

    _rotate(270);

    if (rotateDegreesRef.current === 0) {
      rotateDegreesRef.current = 270;
    } else {
      rotateDegreesRef.current -= 90;
    }

  }, [_rotate]);

  // 画像を右回転する
  const handleRotateRight = React.useCallback(() => {

    _rotate(90);

    rotateDegreesRef.current += 90;
    rotateDegreesRef.current %= 360;

  }, [_rotate]);

  return (

    <Dialog
      open
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={callbacks.closeDialog}
      maxWidth="md"
    >
      { isRotating ? <LinearProgress color="secondary" /> : null }
      <DialogTitle
        classes={{ root: classes.title }}
      >
        <FormattedMessage id="Rotate and crop image" />
      </DialogTitle>
      <DialogContent
        id="scrollContent"
        classes={{ root: classes.root }}
      >
        <Typography
          component="div"
          variant="body2"
          color="textSecondary"
        >
          <FormattedMessage id="You can specify crop area by clicking (tapping) on image and moving it." />
        </Typography>
        <div>
          <Typography
            component="span"
            variant="body2"
            color="textPrimary"
          >
            <FormattedMessage id="Rotate image" />
         </Typography>
          <IconButton
            size="small"
            onClick={handleRotateLeft}
          >
            <RotateLeft />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleRotateRight}
          >
            <RotateRight />
          </IconButton>
        </div>
        <ReactCrop
          src={srcImage || file}
          crop={crop}
          ruleOfThirds
          onImageLoaded={handleLoaded}
          onComplete={handleComplete}
          onChange={handleChange}
          className={classes.crop}
//          imageStyle={{ maxHeight: "70vh" }}
        />
        { croppedImage === null ? null : (
          <div
            className={classes.previewImageWrap}
          >
            <img
              src={createObjectURL(croppedImage)}
              className={classes.previewImage + ' ' + classes.previewCropped + ' ' + (calendarDateOption === 'none' || type === 'magazine' ? classes.previewCroppedFull : '')}
              alt="preview"
            />
            { type === 'calendar' && calendarDateOption !== 'none' &&
              <img
                src={getCalendarDateImageUrl()}
                className={classes.previewImage + ' ' + classes.previewCalendar}
                alt="calendar"
              />
            }
          </div>
        )}
      </DialogContent>
      <DialogActions>
        { fullScreen ? (
          <Button
            startIcon={<Visibility />}
            variant="outlined"
            color="default"
            classes={{ root: classes.previewButton }}
            onClick={handleGoPreview}
          >
            <FormattedMessage id="Preview" />
          </Button>
        ) : null}
        <Button
          autoFocus
          onClick={callbacks.closeDialog}
        >
          <FormattedMessage id="Cancel" />
        </Button>
        <InteractiveButton
          text={<FormattedMessage id="Crop" />}
          clickHandler={handleCrop}
        />
      </DialogActions>
    </Dialog>

  );

}
