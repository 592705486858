import React from 'react';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import InteractiveButton from "./InteractiveButton";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({

  dangerous: {
    color: '#FF3300',
  },

}));

function DeleteSecurityCriticalDialog(props) {

  const { intl, titleId, messageId, closeCallback } = props
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // アカウント削除
  const deleteAccount = React.useCallback(() => {

    const user = firebase.auth().currentUser;
    user.delete()
    .then(function() {

      alert(intl.formatMessage({ id: 'Deleted successfully' }));
      window.location.href = '/';

    })
    .catch(console.error);

  }, [intl]);

  // 再認証させて削除
  const handleDelete = React.useCallback(async () => {

    const provider = new firebase.auth.TwitterAuthProvider();
    firebase.auth().signInWithPopup(provider)
    .then(deleteAccount)
    .catch(console.error);

  }, [deleteAccount]);

  return (

    <Dialog
      open
      fullScreen={fullScreen}
        onClose={closeCallback}
      >
      <DialogTitle>
        <FormattedMessage id={titleId} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.dangerous }}>
          <FormattedMessage id={messageId} />
        </DialogContentText>
        <DialogContentText>
          <FormattedMessage id="For security-critical processing, we might ask you to authenticate again before delete it." />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeCallback}>
          <FormattedMessage id="Close" />
        </Button>
        <InteractiveButton
          text={<FormattedMessage id="Delete" />}
          color="secondary"
          clickHandler={handleDelete}
        />
      </DialogActions>
    </Dialog>

  );

}

export default injectIntl(DeleteSecurityCriticalDialog);
