import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

import ItemList from "./ItemList";
import Footer from "./Footer";
import Header from "./Header";
import { getTwitterProfileImageOriginUrl } from "./ImageUtil";

import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const QUERY_LIMIT = 10;
const ICON_SIZE = 10;

const useStyles = makeStyles(theme => ({

  main: {
    margin: theme.spacing(10, 0),
  },
  avatar: {
    margin: 'auto',
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
  },
  avatarIcon: {
    width: theme.spacing(ICON_SIZE),
    height: theme.spacing(ICON_SIZE),
  },
  avatarSingle: {
    marginLeft: 0,
  },
  items: {
    marginTop: theme.spacing(3),
    justifyContent: 'center',
  },
  emptyData: {
    padding: theme.spacing(2, 4),
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },

}));

export const ExplorePageContext = React.createContext({ callbacks: null });

function getProfile(profiles, profileId) {
  return profiles.find(profile => profile.id === profileId);
}

function ExplorePage(props) {

  const { intl } = props;
  const { username, tag } = useParams();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [title, setTitle] = React.useState(null);
  const [profiles, setProfiles] = React.useState(null);
  const [items, setItems] = React.useState(null);
  const [queryItemParameters, setQueryItemParameters] = React.useState(null);

  // Ref
  const itemsRef = React.useRef([]);
  const lastVisibleItemRef = React.useRef(null);
  const isLoadedToEndRef = React.useRef(false);
  const isLoadingRef = React.useRef(false);
  const isExploredTagRef = React.useRef(null);

  const setItemsRef = React.useCallback((next) => {

    itemsRef.current = itemsRef.current.concat(next);
    setItems(itemsRef.current);

  }, []);

  const queryItems = React.useCallback(async ({ tag = null, userId = null }) => {

    isLoadingRef.current = true;

    let query = await firebase.firestore();
    if (tag) {

      query = query.collectionGroup('items')
        .where('tags', 'array-contains', tag);

    } else if (userId) {

      query = query.collection('profiles')
      .doc(userId)
      .collection('items');

    } else {

      setItemsRef([]);
      return;

    }

    query = query.where('isPublic', '==', true)
      .orderBy('createdAt', 'desc');
    if (lastVisibleItemRef.current) {
      query = query.startAfter(lastVisibleItemRef.current)
    }

    query.limit(QUERY_LIMIT)
    .get()
    .then(querySnapshot => {

      const docs = querySnapshot.docs;
      lastVisibleItemRef.current = docs[docs.length - 1];

      if (docs.length < QUERY_LIMIT) {
        isLoadedToEndRef.current = true;
      }

      setItemsRef(docs.map(doc => {

        const data = doc.data();
        return {
          id: doc.id,
          userId: doc.ref.parent.parent.id,
          page: data['pages'][0],
          ...data
        };

      }));

    })
    .catch(console.error)
    .finally(() => {
      isLoadingRef.current = false;
    });

  }, [setItemsRef]);

  // 無限スクロール
  const endlessScroll = React.useCallback(() => {

    if (Math.round(document.documentElement.clientHeight + window.scrollY) >= document.documentElement.scrollHeight
      && !isLoadedToEndRef.current
      && !isLoadingRef.current) {
      queryItems(queryItemParameters);
    }

  }, [queryItems, queryItemParameters]);

  // 無限スクロール解除
  const removeEndlessScrollListener = React.useCallback((el) => {
    el.removeEventListener('scroll', endlessScroll);
  }, [endlessScroll]);

  const setScrollElement = React.useCallback((el) => {
    el.addEventListener('scroll', endlessScroll);
  }, [endlessScroll]);

  // ヘッダー
  const header = React.useMemo(() => {
    return (<Header />);
  }, []);

  // フッター
  const footer = React.useMemo(() => {
    return (<Footer />);
  }, []);

  // メイン
  const mainContents = React.useMemo(() => {

    return profiles && (
      <>
        <main
          className={classes.main}
        >
          <Container
            maxWidth="sm"
            classes={{ root: classes.profile }}
          >
            <AvatarGroup
              max={fullScreen ? 5 : 7}
              classes={{ root: classes.avatar }}
            >
              { profiles.map(profile => (
                <Avatar
                  key={profile.id}
                  alt="User icon"
                  src={getTwitterProfileImageOriginUrl(profile.profileImage)}
                  classes={{
                    root: [
                      classes.avatarIcon, profiles.length === 1 ? classes.avatarSingle : ''
                    ].join(' ')
                  }}
                />
              ))}
            </AvatarGroup>
            <Typography
              variant="h5"
              color="textPrimary"
              align="center"
              classes={{ root: classes.title }}
            >
              { title }
            </Typography>
          </Container>
          <Container
            maxWidth="lg"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              classes={{ root: classes.items }}
            >
              { items ? (
                <>
                  { items.length > 0 ? items.map(item => (
                    <ItemList
                      key={item['id']}
                      item={item}
                      profile={getProfile(profiles, item['userId'])}
                      isShowProfile={isExploredTagRef.current === null ? true : isExploredTagRef.current}
                    />
                  )) : (
                    <Typography
                      component="div"
                      variant="body2"
                      align="center"
                      classes={{ root: classes.emptyData }}
                    >
                      <FormattedMessage id="There is no items" />
                    </Typography>
                  )}
                </>
              ) : null}
            </Grid>
          </Container>
        </main>
      </>
    );

  }, [profiles, items, title, classes, fullScreen]);

  React.useEffect(() => {

    setScrollElement(window);
    return () => {
      removeEndlessScrollListener(window);
    }

  }, [setScrollElement, removeEndlessScrollListener]);

  React.useEffect(() => {

    if (queryItemParameters) {
      queryItems(queryItemParameters);
    }

  }, [queryItems, queryItemParameters]);

  React.useEffect(() => {

    async function _getProfiles({ tag = null, username = null }) {

      let query = await firebase.firestore()
      .collection('profiles');
      if (tag) {
        query = query.where('tags', 'array-contains', tag);
      } else if (username) {
        query = query.where('username', '==', username).limit(1);
      } else {
        return Promise.reject(null);
      }
      const querySnapshot = await query.get().catch(console.error);
      if (querySnapshot && !querySnapshot.empty) {

        return querySnapshot.docs.map(doc => {

          return {
            id: doc.id,
            ...doc.data()
          }

        });

      } else {
        return [];
      }

    }

    async function _handleTag(tag) {

      isExploredTagRef.current = true;

      const profiles = await _getProfiles({ tag: tag });
      setTitle(`#${tag}`);
      setProfiles(profiles);
      setQueryItemParameters({ tag });

    }

    async function _handleUsername(username) {

      isExploredTagRef.current = false;

      const profiles = await _getProfiles({ username: username });
      setProfiles(profiles);
      setTitle(profiles.length === 0 ? `@${username}` : `${profiles[0].name}`);
      setQueryItemParameters({ userId: profiles.length === 0 ? null : profiles[0].id });

    }

    if (tag && tag.length > 0) {
      _handleTag(tag.toLowerCase());
    } else if (username && username.length > 0) {
      _handleUsername(username.toLowerCase());
    }

  }, [intl, username, tag, queryItems]);

  return (
    <>
      { profiles === null ? <LinearProgress color="secondary" /> : (
        <>
          { header }
          { mainContents }
          { footer }
        </>
      )}
    </>

  );

}

export default injectIntl(ExplorePage);
