import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { FormattedMessage } from "react-intl";

import buttonArrow from './buttonArrow.png';
import twitterLogoWhiteOnBlue from './twitterLogoWhiteOnBlue.png';

const language = navigator.language.split(/[-_]/)[0];

export function getTwitterLoginButton(classes, signInWithTwitter) {
  return (
    <div
      className={classes.loginWrap + " clearfix"}
      id="twitterLogin"
    >
      <Link
        underline="none"
        onClick={signInWithTwitter}
        className={`${classes.buttonWrap} ${classes.buttonLogin}`}
      >
        <img
          alt="Twitter Login"
          src={twitterLogoWhiteOnBlue}
          className={classes.buttonTwitterImage}
          height="67"
        />
        <div>
          <div
            className={classes.buttonLoginText}
          >
            <FormattedMessage id="Register for free" />
          </div>
          <div
            className={classes.buttonSmallText}
          >
            <FormattedMessage id="Sign in with Twitter" />
          </div>
        </div>
        <img
          alt="arrow"
          src={buttonArrow}
          className={classes.buttonLoginArrowImage}
          width="12"
        />
      </Link>
    </div>
  );
};

export const termsOfUse = (
  <>
    <Typography
      variant="body1"
    >
      { language === 'ja' ? (
        <>
          Twitterでサインインすると、
          <br/>
          <Link
            href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/termsofuse.html"
            color="textPrimary"
            underline="always"
            target="_blank"
          >
            利用規約
          </Link>
          ・
          <Link
            href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/pp.html"
            color="textPrimary"
            underline="always"
            target="_blank"
          >
            プライバシーポリシー
          </Link>
          に同意したことになります。
        </>
      ) : (
        <>
          By clicking Sign in with Twitter,
          <br/>
          you agree &nbsp;
          <Link
            href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/termsofuse.html"
            color="textPrimary"
            underline="always"
            target="_blank"
          >
            term of use
          </Link>
          &nbsp;/&nbsp;
          <Link
            href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/pp.html"
            color="textPrimary"
            underline="always"
            target="_blank"
          >
            privacy policy
          </Link>
          .
        </>
      )}
    </Typography>
    <Typography
      component="div"
      variant="caption"
      align="center"
    >
      <FormattedMessage id="Publy does not post to Twitter timeline." />
    </Typography>
    <Typography
      component="div"
      variant="caption"
      align="center"
    >
      <FormattedMessage id="We use sign-in only." />
    </Typography>
  </>
);
