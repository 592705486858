import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

// サムネイルのリサイズサイズ
export const RESIZE_SIZE = '1280x720';

// ストレージのファイルのURLを取得する
export async function getDownloadUrl(remotePath, callback) {

  firebase
  .storage()
  .ref(remotePath)
  .getDownloadURL()
  .then(callback)
  .catch(function(error) {
    console.error(error);
  });

}

export function getS3Bucket(host) {

  const productionHosts = ['publy.fun', 'publy-prod.web.app', 'publy-prod.firebaseapp.com'];
  return productionHosts.indexOf(host) > -1 ? 'publy.fun' : 'publy-dev';

}

// CSVファイルをダウンロードする
export function downloadCsvFromLocal(data, filename) {

  // 文字化け対策のBOM
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, data], { type: 'text/csv'});
  if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
  } else {

    var reader = new FileReader();
    reader.onload = function(e) {

      const download = document.createElement('a');
      download.href = reader.result;
      download.download = filename;
      download.click();

    }
    reader.readAsDataURL(blob);

  }

}
