import React from 'react';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Link } from '@material-ui/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import moment from 'moment';
import { Promise } from "bluebird";
import { FormattedMessage } from "react-intl";
import { MyPageContext } from "./MyPage";

const QUERY_LIMIT = 10;

const useStyles = makeStyles(theme => ({

  purchaseHistory: {
    '&:nth-child(n+2)': {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
  },
  label: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  purchaseDate: {
    marginRight: theme.spacing(2),
  },
  itemTitle: {
    marginRight: theme.spacing(1),
  },

}));

// 作品を取得
async function getItem(userId, itemId) {

  if (!userId || !itemId) return null;

  const item = await firebase.firestore()
  .collection('profiles')
  .doc(userId)
  .collection('items')
  .doc(itemId)
  .get()
  .catch(console.error);
  return item.exists ? item : null;

}

// 住所を作る
function getAddressText(address) {

  return [
    `〒${address['zip']}`,
    address['province'],
    address['city'],
    address['address1'],
    address['address2'],
    '',
    address['name'],
  ].join(' ');

}

// 有効なDateに変換
// 無効ならnull
function getValidDate(dateString) {

  const date = dateString ? moment(dateString) : null;
  return date && date.isValid ? date : null;

}

export default function PurchaseHistoryDialog() {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [items, setItems] = React.useState(null);

  // Ref
  const itemsRef = React.useRef([]);
  const lastVisibleItemRef = React.useRef(null);
  const isLoadedToEndRef = React.useRef(false);
  const isLoadingRef = React.useRef(false);
  const scrollElementRef = React.useRef(null);

  // Context
  const { callbacks } = React.useContext(MyPageContext);

  const setItemsRef = React.useCallback((next) => {

    itemsRef.current = itemsRef.current.concat(next);
    setItems(itemsRef.current);

  }, []);

  const queryItems = React.useCallback(() => {

    isLoadingRef.current = true;
    const user = firebase.auth().currentUser;

    let query = firebase.firestore()
    .collection('orders')
    .where('purchaseUserId', '==', user.uid)
    .orderBy('orderedAt', 'desc');
    if (lastVisibleItemRef.current) {
      query = query.startAfter(lastVisibleItemRef.current)
    }
    query.limit(QUERY_LIMIT)
    .get()
    .then(async querySnapshot => {

      const docs = querySnapshot.docs;

      lastVisibleItemRef.current = docs[docs.length - 1];

      if (docs.length < QUERY_LIMIT) {
        isLoadedToEndRef.current = true;
      }

      setItemsRef(await Promise.map(docs, async doc => {

        // 作品に関する情報が注文データになければ取得する
        const orderData = doc.data();
        let itemTitle = orderData['itemTitle'];
        let itemType = orderData['itemType'];
        let calendarDate = orderData['calendarDate'];
        if (!itemTitle || !itemType) {

          const item = await getItem(
            orderData['authorUserId'],
            orderData['itemId']
          );
          if (item) {

            const itemData = item.data();
            itemTitle = itemData['title'];
            itemType = itemData['type'];

          }

        }

        return {
          id: doc.id,
          itemTitle: itemTitle,
          itemType: itemType,
          calendarDate: getValidDate(calendarDate),
          itemUrl: `/items/${orderData['authorUserId']}/${orderData['itemId']}`,
          ...orderData
        };

      }));

    })
    .catch(console.error)
    .finally(() => {
      isLoadingRef.current = false;
    });

  }, [setItemsRef]);

  // 無限スクロール
  const endlessScroll = React.useCallback(() => {

    const scrollElement = scrollElementRef.current;
    if (Math.round(scrollElement.clientHeight + scrollElement.scrollTop) >= scrollElement.scrollHeight
      && !isLoadedToEndRef.current
      && !isLoadingRef.current) {
      queryItems();
    }

  }, [queryItems]);

  // 無限スクロール解除
  const removeEndlessScrollListener = React.useCallback(() => {

    if (scrollElementRef.current !== null) {
      scrollElementRef.current.removeEventListener('scroll', endlessScroll);
    }

  }, [endlessScroll]);

  React.useEffect(() => {

    queryItems();
    return () => {
      removeEndlessScrollListener();
    }

  }, [queryItems, removeEndlessScrollListener]);

  const setScrollElement = React.useCallback((el) => {

    // 無限スクロール登録
    const addEndlessScrollListener = () => {
      scrollElementRef.current.addEventListener('scroll', endlessScroll);
    };

    if (el !== null && scrollElementRef.current === null) {

      scrollElementRef.current = el;
      addEndlessScrollListener();

    }

  }, [endlessScroll]);

  React.useEffect(() => {

    // アイテム作成画面のpopstateを保持しておく
    // 閉じるときに再設定がいるので
    const prevPopstate = window.onpopstate || null;

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = callbacks.closeDialog;
    return () => {
      window.onpopstate = prevPopstate;
    };

  }, [callbacks]);

  return (

    <>
      <Dialog
        maxWidth="md"
        fullScreen={fullScreen}
        open
        onClose={callbacks.closeDialog}
      >
        <DialogTitle>
          <FormattedMessage id="Purchase history" />
        </DialogTitle>
        { items === null ? (
          <LinearProgress color="secondary" />
        ) : (
          <DialogContent
            ref={setScrollElement}
          >
            { items.length === 0 ? (
              <>
                <Typography
                  component="div"
                  variant="body2"
                  color="textPrimary"
                  paragraph
                >
                  <FormattedMessage id="You haven't purchased yet." />
                </Typography>
              </>
            ) : (
              items.map(item => (
                <div
                  className={classes.purchaseHistory}
                  key={item['id']}
                >
                  <Typography
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.label }}
                    >
                      <FormattedMessage id="Purchase date" />
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      color="textSecondary"
                      classes={{ root: classes.purchaseDate }}
                    >
                      <FormattedMessage id="DD-MM, YYYY">
                        { format => (moment(item['orderedAt'].toDate()).format(format[0])) }
                      </FormattedMessage>
                    </Typography>
                  </Typography>
                  <Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.label }}
                    >
                      <FormattedMessage id="Payment amount" />
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      color="textSecondary"
                    >
                      <FormattedMessage
                        id="¥{ yen }"
                        values={{ yen: Number(item['totalPrice']).toLocaleString() }}
                      />
                    </Typography>
                  </Typography>
                  <Typography
                  >
                    <Typography
                      component="span"
                      variant="h6"
                      color="textPrimary"
                      classes={{ root: classes.itemTitle }}
                    >
                      <Link
                        target="_blank"
                        href={item['itemUrl']}
                      >
                        {item['itemTitle']}
                      </Link>
                    </Typography>
                    {item['calendarDate'] && (
                      <Typography
                        component="span"
                        variant="caption"
                        color="textSecondary"
                      >
                        <FormattedMessage id="MMM, YYYY">
                          { format => (moment(item['calendarDate']).format(format[0])) }
                        </FormattedMessage>
                        &nbsp;
                      </Typography>
                    )}
                    <Typography
                      component="span"
                      variant="caption"
                      color="textSecondary"
                    >
                      {item['itemType'] && (item['itemType'] === 'magazine' ? (
                        <FormattedMessage id="Magazine" />
                      ) : (
                        <FormattedMessage id="Calendar" />
                      ))}
                    </Typography>
                  </Typography>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textPrimary"
                  >
                    {getAddressText(item['shippingAddress'])}
                  </Typography>
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                  >
                    <FormattedMessage id="DD-MM, YYYY">
                      { format => (moment(item['orderedAt'].toDate()).add(2, 'w').format(format[0])) }
                    </FormattedMessage>
                    &nbsp;
                    <FormattedMessage id="Expected arrival" />
                  </Typography>
                </div>
              ))
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={callbacks.closeDialog}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );

}
