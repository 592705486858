import React from 'react';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel} from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { Switch } from '@material-ui/core';

import { red } from '@material-ui/core/colors';

import moment from "moment";
import Client from 'shopify-buy';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { firebaseApp } from './App';

import { RequestPageContext } from './RequestPage';
import InteractiveButton from './InteractiveButton';
import { FormattedMessage, injectIntl } from "react-intl";
import { getCalendarDateList } from './ItemUtil';
import { isCheckedAcceptingCalendarWithDate } from './ItemUtil';
import { isCheckedAcceptingCalendarNoDate } from './ItemUtil';
import { isCheckedAcceptingMagazine } from './ItemUtil';
import { ITEM_POSTAGE } from './ItemUtil';
import { computeSales } from './ItemUtil';
import calendarWithDateImageJa from './calendarWithDateImage_ja.png';
import calendarNoDateImageJa from './calendarNoDateImage_ja.png';
import calendarWithDateImageEn from './calendarWithDateImage_en.png';
import calendarNoDateImageEn from './calendarNoDateImage_en.png';
import { getShopifyDomain } from './ShopifyUtil';
import { getShopifyStorefrontAccessToken } from './ShopifyUtil';

const language = navigator.language.split(/[-_]/)[0];

// 一回あたりリクエストできる金額
const MAX_REQUEST_PRICE = 100000;

const useStyles = makeStyles(theme => ({

  contents: {
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(3),
    },
  },
  textField: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.textFieldBg,
  },
  textFieldWrap: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  textFieldWrapNoTopMargin: {
    display: 'flex',
  },
  textFieldMain: {
    backgroundColor: theme.palette.textFieldBg,
    display: 'inline-flex',
  },
  textFieldUnit: {
    display: 'inline-flex',
    marginLeft: 4,
    alignItems: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  aboutRequestHead: {
    padding: theme.spacing(1),
    color: red[500],
    border: `1px solid ${red[500]}`,
    borderRadius: 4,
  },
  aboutRequestTitle: {
    fontWeight: 'bold',
  },
  aboutRequestBody: {
  },
  agreed: {
    color: red[500],
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  agreedDivider: {
    marginTop: theme.spacing(2),
  },
  selectOption: {
    backgroundColor: theme.palette.textFieldBg,
    marginTop: theme.spacing(1),
  },
  itemTypeNote: {
    marginTop: theme.spacing(1),
  },
  createImage: {
    width: '100%',
  },
  totalLabel: {
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
  },
  totalValue: {
  },
  requestNote: {
    border: `1px solid ${red[500]}`,
    borderRadius: 6,
    color: red[500],
    padding: theme.spacing(1),
  },

}));

const getDefaultValidationMessages = () => {

  return {
    calendarDate: null,
    orderPrice: null,
    message: null,
  };

};

// リクエスト商品の送料
const REQUEST_ITEM_POSTAGE = {
  'calendarWithDate': ITEM_POSTAGE['calendar'],
  'calendarNoDate': ITEM_POSTAGE['calendar'],
  'magazine': ITEM_POSTAGE['magazine'],
};

function SendRequestDialog(props) {

  const { intl } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [snackbarOpenType, setSnackbarOpenType] = React.useState(null);
  const [creatorProfile, setCreatorProfile] = React.useState(null);
  const [clientProfile, setClientProfile] = React.useState(null);
  const [validationMessages, setValidationMessages] = React.useState(getDefaultValidationMessages());
  const [isAgreed, setAgreed] = React.useState(false);
  const [itemType, setItemType] = React.useState(null);
  const [calendarDate, setCalendarDate] = React.useState(null);
  const [isPrivate, setPrivate] = React.useState(false);
  const [price, setPrice] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [isShowAgreement, setShowAgreement] = React.useState(false);
  const [isSending, setSending] = React.useState(false);

  // Ref
  const clientProfileRef = React.useRef(null);
  const calendarDateRef = React.useRef(null);
  const orderPriceRef = React.useRef(null);
  const messageRef = React.useRef(null);
  const sendButtonHandlerRef = React.useRef(null);

  const itemPostage = React.useMemo(() => REQUEST_ITEM_POSTAGE[itemType] || 0, [itemType]);
  const itemMinPrice = React.useMemo(() => {
    return {
      'calendarWithDate': creatorProfile ? parseInt(creatorProfile['requestCalendarWithDateMinOrderPrice']) : null,
      'calendarNoDate': creatorProfile ? parseInt(creatorProfile['requestCalendarNoDateMinOrderPrice']) : null,
      'magazine': creatorProfile ? parseInt(creatorProfile['requestMagazineMinOrderPrice']) : null,
    };
  }, [creatorProfile]);
  const itemServiceFee = React.useMemo(() => {
    return {
      'calendarWithDate': creatorProfile ? parseFloat(creatorProfile['requestCalendarServiceFee']) : null,
      'calendarNoDate': creatorProfile ? parseFloat(creatorProfile['requestCalendarServiceFee']) : null,
      'magazine': creatorProfile ? parseFloat(creatorProfile['requestMagazineServiceFee']) : null,
    };
  }, [creatorProfile]);
  const itemCost = React.useMemo(() => {
    return {
      'calendarWithDate': creatorProfile ? parseInt(creatorProfile['requestCalendarItemCost']) : null,
      'calendarNoDate': creatorProfile ? parseInt(creatorProfile['requestCalendarItemCost']) : null,
      'magazine': creatorProfile ? parseInt(creatorProfile['requestMagazineItemCost']) : null,
    };
  }, [creatorProfile]);

  // 設定した金額に応じてクリエーターの売上を計算する
  const sales = React.useMemo(() => {

    return computeSales(
      price,
      itemCost[itemType],
      itemServiceFee[itemType],
      itemMinPrice[itemType]
    );

  }, [price, itemCost, itemServiceFee, itemMinPrice, itemType]);

  // Context
  const { callbacks, creatorUserId } = React.useContext(RequestPageContext);

  // 利用規約表示をトグルさせる
  const handleClickToggleAgreement = React.useCallback(() => {
    setShowAgreement(!isShowAgreement);
  }, [isShowAgreement]);

  // Snackbar非表示
  const handleCloseSnackbar = React.useCallback(() => {
    setSnackbarOpenType(null);
  }, []);

  // 同意チェックボックス変更
  const handleChangeAgreement = React.useCallback(() => {
    setAgreed(!isAgreed);
  }, [isAgreed]);

  // リクエスト合計額を計算
  React.useEffect(() => {

    if (isNaN(price)) {
      setTotalPrice(null);
    } else {
      setTotalPrice(price + itemPostage);
    }

  }, [price, itemPostage]);

  // リクエスト金額を変動したとき
  const handleChangePrice = React.useCallback((event) => {

    const price = Math.min(MAX_REQUEST_PRICE, parseInt(event.target.value.trim()));
    setPrice(price);

  }, []);

  // リクエスト金額のonBlur
  const handleBlurRequestPrice = React.useCallback((event, minValue) => {

    const value = parseInt(event.target.value.trim());
    const newValue = isNaN(value) ? minValue : Math.min(MAX_REQUEST_PRICE, Math.max(value, minValue));
    setPrice(newValue);

  }, []);

  // 作品タイプ変更
  const handleChangeItemType = React.useCallback((event) => {

    const newItemType = event.target.value;

    setPrice(itemMinPrice[newItemType]);
    setItemType(newItemType);

  }, [itemMinPrice]);

  // 公開設定変更
  const handleChangeScope = React.useCallback((event) => {
    setPrivate(!isPrivate);
  }, [isPrivate]);

  // カレンダー日付変更
  const handleChangeCalendarDate = React.useCallback((event) => {
    setCalendarDate(event.target.value);
  }, []);

  // 決済画面表示
  const openShopifyPayment = React.useCallback((requestId, productId, clientUserId, creatorUserId, calendarDate, callback) => {

    const client = Client.buildClient({
      domain: getShopifyDomain(),
      storefrontAccessToken: getShopifyStorefrontAccessToken()
    });
    client.product.fetch(btoa(`gid://shopify/Product/${productId}`)).then((product) => {

      const vid = product.variants[0].id;
      const input = {
        lineItems: [
          {
            variantId: vid,
            quantity: 1
          }
        ],
        customAttributes: [
          {
            key: "data",
            value: JSON.stringify({
              userId: clientUserId,
              authorUserId: creatorUserId,
              calendarDate,
              requestId,
              clientUserId,
              creatorUserId,
            })
          }
        ]
      };
      client.checkout.create(input).then((checkout) => {

        var checkOutUrl = checkout.webUrl;
        if (checkOutUrl.indexOf('?') === -1) {
          checkOutUrl += '?';
        } else {
          checkOutUrl += '&';
        }
        checkOutUrl += `locale=${window.navigator.language.split(/[-_]/)[0]}`;
        window.location.href = checkOutUrl;

      });

    });

  }, []);

  // 送る
  const handleSend = React.useCallback(async (callback) => {

    const user = firebase.auth().currentUser;
    if (user === null
      || clientProfileRef.current === null) return;

    const isItemTypeCalendar = ['calendarWithDate', 'calendarNoDate'].indexOf(itemType) > -1;

    const orderPrice = orderPriceRef.current.value.trim();
    const message = messageRef.current.value.trim();

    const validationMessages = getDefaultValidationMessages();
    let focusField = null;
    if (isItemTypeCalendar && calendarDate === null) {

      focusField = focusField || calendarDateRef.current;
      validationMessages['calendarDate'] = intl.formatMessage({ id: 'Enter field' });

    }
    if (orderPrice.length === 0) {

      focusField = focusField || orderPriceRef.current;
      validationMessages['orderPrice'] = intl.formatMessage({ id: 'Enter field' });

    }
    if (message.length === 0) {

      focusField = focusField || messageRef.current;
      validationMessages['message'] = intl.formatMessage({ id: 'Enter field' });

    }
    setValidationMessages(validationMessages);

    if (focusField !== null) {

      focusField.focus();
      callback(false);
      return;

    }

    // チェックOK
    // 登録開始
    // クライアント
    setSending(true);
    const clientUsername = clientProfile['username'];
    const clientName = clientProfile['name'];
    const clientProfileImage = clientProfile['profileImage'];
    const clientUserId = user.uid;
    const calendarDateObj = isItemTypeCalendar ? moment(calendarDate).toDate() : null;

    // クリエーター
    const creatorUserName = creatorProfile['username'];
    const creatorName = creatorProfile['name'];
    const creatorProfileImage = creatorProfile['profileImage'];
    const submissionDays = creatorProfile['requestSubmissionDays'];

    // Shopify Product登録
    const createShopifyProduct = firebaseApp.functions('asia-northeast1').httpsCallable('createShopifyProduct');
    const shopifyProductData = await createShopifyProduct({
      title: intl.formatMessage({ id: "Request to { name }" }, { name: creatorName }),
      totalPrice,
      sku: `request-${clientUserId}/${creatorUserId}`
    })
    .catch(console.error);

    if (!shopifyProductData || !shopifyProductData['data']) {

      alert(intl.formatMessage({ id: 'Something error occurred. Please retry again' }));
      callback(false);
      setSending(false);
      return;

    }

    const shopifyProductId = shopifyProductData['data'];
    const requestId = shopifyProductId.toString();
    const batch = firebase.firestore().batch();
    batch.update(
      clientProfileRef.current.ref,
      {
        isAgreedClientRequest: true,
      }
    );
    batch.set(
      firebase
      .firestore()
      .collection('requests')
      .doc(requestId),
      {
        clientUsername,
        clientName,
        clientProfileImage,
        creatorUserId,
        creatorUserName,
        creatorName,
        creatorProfileImage,
        clientUserId,
        price: parseInt(orderPrice),
        totalPrice: parseInt(totalPrice),
        postage: itemPostage,
        itemCost: itemCost[itemType],
        serviceFee: itemServiceFee[itemType],
        sales,
        message,
        itemType,
        submissionDays,
        isPublic: !isPrivate,
        calendarDate: calendarDateObj,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      {
        merge: true
      }
    );

    batch.commit()
    .then(function() {

      setSnackbarOpenType('save');
      openShopifyPayment(
        requestId,
        shopifyProductId,
        clientUserId,
        creatorUserId,
        isItemTypeCalendar ? calendarDate : null,
        callback
      );

    })
    .catch(error => {

      console.error(error);
      callback(false);
      setSending(false);

    });

  }, [
    intl, isPrivate, itemType, calendarDate, creatorUserId, totalPrice,
    itemPostage, creatorProfile, clientProfile,
    openShopifyPayment, itemServiceFee, itemCost, sales,
  ]);

  const showErrorAlert = React.useCallback((reason) => {

    console.error(reason);
    window.alert(intl.formatMessage({ id: 'Not found user' }));
    window.location.href = '/';

  }, [intl]);

  React.useEffect(() => {

    async function getProfile(userId) {

      return firebase.firestore()
      .collection('profiles')
      .doc(userId)
      .get()
      .catch(showErrorAlert);

    };

    async function _setCreatorProfile() {

      const creatorProfile = await getProfile(creatorUserId);
      if (creatorProfile.exists) {
        setCreatorProfile(creatorProfile.data());
      }

    };

    async function _setClientProfile(clientUserId) {

      const clientProfile = await getProfile(clientUserId);
      if (clientProfile.exists) {
        clientProfileRef.current = clientProfile;
        setClientProfile(clientProfile.data());
      }

    };

    firebase.auth().onAuthStateChanged(async function(user) {

      if (user === null) {
        window.location.href = '/';
      } else {
        _setClientProfile(user.uid);
      }

    });

    _setCreatorProfile();

  }, [creatorUserId, showErrorAlert]);

  React.useEffect(() => {

    // アイテム作成画面のpopstateを保持しておく
    // 閉じるときに再設定がいるので
    const prevPopstate = window.onpopstate || null;

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = callbacks.closeDialog;
    return () => {
      window.onpopstate = prevPopstate;
    };

  }, [callbacks]);

  const isAgreedClientRequest = React.useMemo(() => clientProfile && clientProfile['isAgreedClientRequest'] === true, [clientProfile]);
  const isNoAcceptingItem = React.useMemo(() => creatorProfile && creatorProfile.requestAcceptingItem === 0, [creatorProfile]);
  const isSelectedItemType = React.useMemo(() => itemType !== null, [itemType]);

  return (

    <>
      <Snackbar open={snackbarOpenType !== null} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          { snackbarOpenType === 'save' ? (
            <FormattedMessage id="Payment page will be displayed." />
          ) : null}
        </Alert>
      </Snackbar>
      <Dialog
        maxWidth="sm"
        fullScreen={fullScreen}
        open
        onClose={callbacks.closeDialog}
        disableBackdropClick={isSending}
        disableEscapeKeyDown={isSending}
      >
        <DialogTitle>
          <FormattedMessage id="Request settings" />
        </DialogTitle>
        { (creatorProfile === null || clientProfile === null) ? <LinearProgress color="secondary" /> : (
          <>
            <DialogContent>
              { /* 常に表示する */ }
              { (!isAgreedClientRequest || isShowAgreement) ? (
                <>
                  <div className={classes.contents}>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      align="center"
                      classes={{ root: classes.aboutRequestHead }}
                      paragraph
                    >
ご利用前に必ずお読みください。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
リクエストとは
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
好きなクリエーターに対して有料で「こういうカレンダーが欲しい」といったリクエストを送ることができるサービスです。
リクエストを送ってクリエーターが承諾すると、リクエスト内容に応じた作品を作ってくれます。
作成後は、通常の作品購入と同じようにPublyが生産してお届けします。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
質問・打ち合わせ・リテイク一切なしの一発勝負
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
すべてのクリエーターが等しく柔軟な対応できないため、受け取ったリクエストから分かる内容でクリエーターの裁量で作成されます。
もしクリエーターが受けられない内容だった場合はキャンセルされますが、その場合は返金されますので、安心してリクエストください。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
作品データの著作権はクリエーターのもの
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
リクエストした作品データの著作権は、クリエーターに帰属しますが、
届いたカレンダー・雑誌といった商品については、複製し再販売することを除き、自由にお使い頂けます。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
Publyの印刷品質
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
新聞や多くの出版物は「オフセット印刷」という方式で印刷されており、写真やイラスト、文字などを細密且つ鮮明に表現することが得意ですが、1部から印刷することはほとんどありません。
Publyが採用する「オンデマンド印刷」は、本サービスのように欲しい作品を1部から印刷できる特徴を持っていますが、印刷品質はオフセット印刷に比べ劣ります。
そのためPublyの商品を受け取ったときに画質が荒いと感じることがあるかもしれませんが、それはクリエーターが作成したデータの問題ではなくPublyが提供する品質の限界です。
日々品質については改善や調整を加えてより良いサービス提供を目指していますので、ご理解頂けますと幸いです。
                    </Typography>
                    { isAgreedClientRequest ? (
                      <>
                        <Typography
                          component="div"
                          variant="body1"
                          color="textPrimary"
                          classes={{ root: classes.agreed }}
                        >
                          <FormattedMessage id="You have been agreed" />
                        </Typography>
                      </>
                    ) : (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={isAgreed}
                              onChange={handleChangeAgreement}
                            />
                          }
                          label={
                            <Typography
                              component="div"
                              variant="body2"
                            >
                              <FormattedMessage id="Agree and create about above" />
                            </Typography>
                          }
                        />
                      </div>
                    )}
                  </div>
                  <Divider
                    light
                    classes={{ root: classes.agreedDivider }}
                  />
                </>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClickToggleAgreement}
                >
                  <FormattedMessage id="Show agreement for request feature" />
                </Button>
              )}
              <div className={classes.contents}>
                <FormControl
                  variant="filled"
                  classes={{ root: classes.selectOption }}
                  fullWidth
                >
                  <InputLabel id="request-item-option">
                    <FormattedMessage id="Request item type" />
                  </InputLabel>
                  <Select
                    value={isNoAcceptingItem ? "" : itemType}
                    onChange={handleChangeItemType}
                  >
                    {isNoAcceptingItem && (
                      <MenuItem
                        value=""
                        key={"request-item-option-none"}
                      >
                        <em>
                          <FormattedMessage id="There is no item type being accepted" />
                        </em>
                      </MenuItem>
                    )}
                    {isCheckedAcceptingCalendarWithDate(creatorProfile.requestAcceptingItem) && (
                      <MenuItem
                        value="calendarWithDate"
                        key={"request-item-option-calendarWithDate"}
                      >
                        <FormattedMessage id="Calendar with date" />
                      </MenuItem>
                    )}
                    {isCheckedAcceptingCalendarNoDate(creatorProfile.requestAcceptingItem) && (
                      <MenuItem
                        value="calendarNoDate"
                        key={"request-item-option-calendarNoDate"}
                      >
                        <FormattedMessage id="Calendar with no date" />
                      </MenuItem>
                    )}
                    {isCheckedAcceptingMagazine(creatorProfile.requestAcceptingItem) && (
                      <MenuItem
                        value="magazine"
                        key={"request-item-option-magazine"}
                      >
                        <FormattedMessage id="Magazine (12P with front/back covers)" />
                      </MenuItem>
                    )}
                  </Select>
                  <FormHelperText>
                    <FormattedMessage id="Select type you want to request" />
                  </FormHelperText>
                </FormControl>
                <Typography
                  component="div"
                  variant="body2"
                  color="textPrimary"
                  classes={{ root: classes.itemTypeNote }}
                >
                  { itemType === 'calendarWithDate' ? (
                    <>
                      <img
                        alt="Calendar with date"
                        src={language === 'ja' ? calendarWithDateImageJa : calendarWithDateImageEn}
                        className={classes.createImage}
                      />
                      <FormattedMessage id="Calendar with date is request to have creator create calendar with date. Select year and month you want calendar." />
                    </>
                  ) : ( itemType === 'calendarNoDate' ? (
                    <>
                      <img
                        alt="Calendar with no date"
                        src={language === 'ja' ? calendarNoDateImageJa : calendarNoDateImageEn}
                        className={classes.createImage}
                      />
                      <FormattedMessage id="Calendar with no date is request for creator to make it without date. Date on calendar selected here and image created by creator will be combined and delivered to you." />
                    </>
                  ) : ( itemType === 'magazine' && (
                    <FormattedMessage id="Send request 12-page magazine, including front and back covers." />
                  )))}
                </Typography>
                { ['calendarWithDate', 'calendarNoDate'].indexOf(itemType) > -1 && (
                  <>
                    <TextField
                      value={calendarDate}
                      onChange={handleChangeCalendarDate}
                      inputRef={calendarDateRef}
                      error={validationMessages.calendarDate != null}
                      helperText={validationMessages.calendarDate}
                      select
                      fullWidth
                      label=<FormattedMessage id="Calendar date" />
                      classes={{ root: classes.selectOption }}
                      variant="filled"
                    >
                      { getCalendarDateList().map((date, index) => (
                        <MenuItem
                          value={date}
                          key={"calendar-date-option-" + date}
                        >
                          <FormattedMessage id="MMM, YYYY">
                            { format => (moment(date).format(format[0])) }
                          </FormattedMessage>
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </div>
              { isSelectedItemType && (
                <div className={classes.contents}>
                  <div
                    className={classes.textFieldWrap}
                  >
                    <FormattedMessage id="e.g. 500">
                    { placeholder => (
                      <TextField
                        autoComplete="off"
                        label={<FormattedMessage id="Request amount including tax (Currency: yen)" />}
                        inputRef={orderPriceRef}
                        value={price}
                        placeholder={placeholder[0]}
                        inputProps={{
                          max: MAX_REQUEST_PRICE,
                          min: itemMinPrice[itemType],
                        }}
                        variant="filled"
                        fullWidth
                        type="number"
                        error={validationMessages.orderPrice != null}
                        helperText={validationMessages.orderPrice}
                        required
                        classes={{ root: classes.textFieldMain }}
                        onBlur={(event) => handleBlurRequestPrice(event, itemMinPrice[itemType])}
                        onChange={handleChangePrice}
                        disabled={!isSelectedItemType}
                      />
                    )}
                    </FormattedMessage>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textSecondary"
                      classes={{ root: classes.textFieldUnit }}
                    >
                      <FormattedMessage id="Yen" />
                    </Typography>
                  </div>
                  <FormHelperText>
                    <FormattedMessage
                      id="Request amount can be set between ¥{ itemMinPrice } and ¥{ itemMaxPrice }."
                      values={{
                        itemMinPrice: Number(itemMinPrice[itemType]).toLocaleString(),
                        itemMaxPrice: Number(MAX_REQUEST_PRICE).toLocaleString()
                      }}
                    />
                  </FormHelperText>
                </div>
              )}
              { isSelectedItemType && (
                <div className={classes.contents}>
                  <FormattedMessage id="e.g. I would like item that makes you feel summer. I will leave other details to you.">
                  { placeholder => (
                    <TextField
                      label={<FormattedMessage id="Request message - Up to 250 characters" />}
                      autoComplete="off"
                      placeholder={placeholder[0]}
                      inputRef={messageRef}
                      inputProps={{
                        maxLength: 250,
                      }}
                      variant="filled"
                      fullWidth
                      error={validationMessages.message != null}
                      helperText={validationMessages.message}
                      required
                      multiline
                      classes={{ root: classes.textField }}
                      rows={5}
                      disabled={!isSelectedItemType}
                    />
                  )}
                  </FormattedMessage>
                  <FormHelperText>
                    <FormattedMessage id="Write clearly and concisely what you want to request." />
                  </FormHelperText>
                </div>
              )}
              { isSelectedItemType && (
                <div
                  className={classes.contents}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isPrivate}
                        onChange={handleChangeScope}
                        disabled={!isSelectedItemType}
                      />
                    }
                    label={<FormattedMessage id="Deny to be listed" />}
                  />
                  <Typography
                    variant="caption"
                    component="div"
                  >
                    { isPrivate ? <FormattedMessage id="This request will not be published anywhere." /> : <FormattedMessage id="This request will be published on creator page." /> }
                  </Typography>
                </div>
              )}
              { isSelectedItemType && (
                <div
                  className={classes.contents}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    classes={{ root: classes.totalLabel }}
                  >
                    <FormattedMessage id="Total request amount" />
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    classes={{ root: classes.totalValue }}
                  >
                    <FormattedMessage id="¥{ yen }" values={{
                      yen: Number(totalPrice).toLocaleString()
                    }} />
                  </Typography>
                  <Typography
                    variant="caption"
                    component="span"
                    color="textSecondary"
                    classes={{ root: classes.sales }}
                  >
                    <FormattedMessage id="(Material cost and fee: ¥{ serviceCost })" values={{
                      serviceCost: Number(price - sales).toLocaleString()
                    }} />
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    classes={{ root: classes.postage }}
                  >
                    <FormattedMessage id="Postage ¥{ postage } included" values={{
                      postage: Number(itemPostage).toLocaleString()
                    }} />
                  </Typography>
                </div>
              )}
              { isSelectedItemType && (
                <div
                  className={classes.contents}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    classes={{ root: classes.totalLabel }}
                  >
                    <FormattedMessage id="Creator works period" />
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    classes={{ root: classes.totalValue }}
                  >
                    <FormattedMessage id="{ days }day(s)" values={{
                      days: creatorProfile['requestSubmissionDays']
                    }} />
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    classes={{ root: classes.postage }}
                  >
                    <FormattedMessage id="After that, Publy product will be delivered after production (about 10 days)." />
                  </Typography>
                </div>
              )}
              { isSelectedItemType && (
                <div className={classes.contents}>
                  <div className={classes.button}>
                    <InteractiveButton
                      text={
                        <>
                          <FormattedMessage id="Pay for request" />
                          <Typography
                            component="div"
                            variant="caption"
                            className={classes.openShopify}
                          >
                            <FormattedMessage id="(Open Shopify)" />
                          </Typography>
                        </>
                      }
                      disabled={(!isAgreedClientRequest && !isAgreed) || !isSelectedItemType}
                      clickHandler={handleSend}
                      handlerRef={sendButtonHandlerRef}
                    />
                  </div>
                </div>
              )}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={callbacks.closeDialog}
            disabled={isSending}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );

}

export default injectIntl(SendRequestDialog);
