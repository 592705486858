import moment from 'moment';
import { getS3Bucket } from './DownloadFile';
import { getPrintAspectRatio } from './PrintSize';
import { CALENDAR_PX } from './PrintSize';

// 画像からExifを読んでオリエンテーションを取得する
export const getOrientation = (buffer) => {

  const dv = new DataView(buffer);
  let app1MarkerStart = 2;

  // もし JFIF で APP0 Marker がある場合は APP1 Marker の取得位置をずらす
  if (dv.getUint16(app1MarkerStart) !== 65505) {

    const length = dv.getUint16(4);
    app1MarkerStart += length + 2;

  }
  if (dv.getUint16(app1MarkerStart) !== 65505) {
    return 0;
  }

  // エンディアンを取得
  const littleEndian = dv.getUint8(app1MarkerStart + 10) === 73;

  // フィールドの数を確認
  const count = dv.getUint16(app1MarkerStart + 18, littleEndian);
  for (let i = 0; i < count; i++) {

    const start = app1MarkerStart + 20 + i * 12;
    const tag = dv.getUint16(start, littleEndian);
    // Orientation の Tag は 274
    // Orientation は Type が SHORT なので 2byte だけ読む
    if (tag === 274) {
      return dv.getUint16(start + 8, littleEndian);
    }

  }
  return 0;

}

// オリエンテーションに応じて画像を回転する

// 他参考:
// https://github.com/blueimp/JavaScript-Load-Image/blob/master/js/load-image-orientation.js

export const createTransformedCanvas = (orientation, img) => {

  // flipは未対応ということで
  // とりあえず縦にする
  return rotate(img, 0);

  // 以下参考にしたけどおかしな回転になる
  // https://qiita.com/zaru/items/0ce7757c721ebd170683
  /*
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if ([5, 6, 7, 8].indexOf(orientation) > -1) {

    canvas.width = img.height;
    canvas.height = img.width;

  } else {

    canvas.width = img.width;
    canvas.height = img.height;

  }

  switch (orientation) {
    case 2: ctx.transform(-1, 0, 0, 1, img.width, 0); break;
    case 3: ctx.transform(-1, 0, 0, -1, img.width, img.height); break;
    case 4: ctx.transform(1, 0, 0, -1, 0, img.height); break;
    case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
    case 6: ctx.transform(0, 1, -1, 0, img.height, 0); break;
    case 7: ctx.transform(0, -1, -1, 0, img.height, img.width); break;
    case 8: ctx.transform(0, -1, 1, 0, 0, img.width); ;break;
  }
  ctx.drawImage(img, 0, 0);
  return canvas;
  */

}

// CanvsをBlobにする
export function toBlob(canvas, mimeType) {

  return new Promise((resolve, reject) => {

    if (canvas.msToBlob) {

      const blob = canvas.msToBlob();
      if (blob) {
        resolve(blob);
      } else {
        reject('Canvas is empty');
      }

    } else if (canvas.toBlob) {

      canvas.toBlob(blob => {

        if (blob) {
          resolve(blob);
        } else {
          reject('Canvas is empty');
        }

      }, mimeType || 'image/jpeg');

    }

  });

};

// 画像を回転する
export function rotate(img, rotation) {
  return _rotate(img, rotation);
}

function _rotate(img, rotation) {

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const w = img.width;
  const h = img.height;
  const isLandscape = [90, 270].indexOf(Math.abs(rotation)) > -1;

  if (isLandscape) {

    canvas.width = h;
    canvas.height = w

  } else {

    canvas.width = w;
    canvas.height = h;

  }

  const cx = canvas.width / 2;
  const cy = canvas.height / 2;
  ctx.clearRect(0, 0, w, h);
  ctx.fillStyle = 'white';
  ctx.fillRect(
    0, 0, canvas.width, canvas.height
  );
  ctx.save();
  ctx.translate(cx, cy);
  ctx.rotate(rotation * (Math.PI / 180));
  ctx.drawImage(img, isLandscape ? -cy : -cx, isLandscape ? -cx : -cy);
  ctx.translate(-cx, -cy);
  ctx.restore();

  return canvas;

}

// Twitterプロフィール画像のオリジナルURLを取得
export function getTwitterProfileImageOriginUrl(url) {

  if (url) {

    const urlComponents = url.split('/');
    const lastComponent = urlComponents[urlComponents.length - 1];
    urlComponents[urlComponents.length - 1] = lastComponent.replaceAll('_normal', '');
    return urlComponents.join('/');

  } else {
    return null;
  }

}

// カレンダーもしくはマガジンのプレビュー画像を取得
export function getPreviewStyleImage(url, item, page, calendarDate, onFinish) {

  const originImage = new Image();
  originImage.crossOrigin = "anonymous";
  originImage.src = url;
  originImage.onload = function() {

    const getCalendarDateImageUrl = () => {

      const bucket = getS3Bucket(window.location.host);
      return `https://s3-ap-northeast-1.amazonaws.com/${bucket}/calendar_date/thumbnail/${calendarDate || moment().add(1, 'month').format('YYYYMM')}.jpg?t=${Date.now()}`;

    };

    const dpr = window.devicePixelRatio || 1;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.scale(dpr, dpr);

    const isBlur = page['blur'];
    const type = item['type'];
    const calendarDateOption = item['calendarDateOption'];
    const aspect = getPrintAspectRatio(type, calendarDateOption);

    // 短辺に合わせる
    let width = originImage.width > originImage.height ? originImage.height : originImage.width;
    let height = width * aspect;

    // リサイズ
    if (width > originImage.width) {

      const ratio = originImage.width / width;
      width *= ratio;
      height *= ratio;

    }
    if (height > originImage.height) {

      const ratio = originImage.height / height;
      width *= ratio;
      height *= ratio;

    }

    const x = (originImage.width - width) / 2;
    const y = (originImage.height - height) / 2;

    canvas.width = 350 * dpr;
    canvas.height = canvas.width * aspect;
    context.fillStyle = 'white';
    context.fillRect(
      0, 0, canvas.width, canvas.height
    );

    context.drawImage(
      originImage,
      x,
      y,
      width,
      height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // ウォーターマーク
    // Blurすると文字見えないのでしない
    if (isBlur !== true) {

      context.font = "40px verdana";

      const text = 'Publy';
      const textWidth = context.measureText(text).width;
      context.globalAlpha = 0.5;
      context.fillStyle = 'white';
      context.fillText(text, canvas.width - textWidth - 30, 60);
      context.fillStyle = 'black';
      context.fillText(text, canvas.width - textWidth - 30, 60 + 2);

    }

    if (calendarDateOption === 'next') {

      // カレンダー日付を合成する
      const calendarAspect = CALENDAR_PX.y / CALENDAR_PX.x;
      const calendarBaseImage = new Image();
      calendarBaseImage.crossOrigin = "anonymous";
      calendarBaseImage.src = getCalendarDateImageUrl();
      calendarBaseImage.onload = function() {

        const canvasWithCalendar = document.createElement("canvas");
        const contextWithCalendar = canvasWithCalendar.getContext('2d');
        contextWithCalendar.scale(dpr, dpr);
        canvasWithCalendar.width = canvas.width;
        canvasWithCalendar.height = canvas.width * calendarAspect;
        contextWithCalendar.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
        contextWithCalendar.drawImage(
          calendarBaseImage,
          0,
          0,
          calendarBaseImage.width,
          calendarBaseImage.height,
          0,
          canvas.height,
          canvasWithCalendar.width,
          canvasWithCalendar.height - canvas.height
        );
        onFinish(canvasWithCalendar.toDataURL());

      };

    } else {

      onFinish(canvas.toDataURL());
    }

  };

}
