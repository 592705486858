import React from 'react';

import { AppBar } from '@material-ui/core';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import { FormattedMessage } from "react-intl";
import { MyPageContext } from './MyPage';
import publyLogo from "./publyLogo.png";
import headerLoginButton from "./headerLoginButton.png";
import { getTwitterProfileImageOriginUrl } from "./ImageUtil";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const useStyles = makeStyles(theme => ({

  appBar: {
    backgroundColor: theme.palette.appBarBg,
  },
  toolbar: {
    display: 'flex',
  },
  logo: {
    width: 117,
    position: 'relative',
    top: 5,
  },
  title: {
    flexGrow: 1,
    padding: `${theme.spacing(1)}px 0`,
  },
  loginButton: {
    cursor: 'pointer',
    backgroundColor: 'white',
    color: 'black',
    width: 80,
    height: 35,
    marginTop: 12,
    borderRadius: 8,
    "&:hover": {
      opacity: 0.6,
    },
  },
  loginImage: {
    height: 22,
    position: 'relative',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  loginText: {
    position: 'relative',
    left: theme.spacing(1),
    fontSize: 10,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  requestMenu: {
    padding: theme.spacing(1, 0),
  },

}));

export default function Header(props) {

  const classes = useStyles();
  const { signInHandler } = props;

  // State
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);

  // Context
  const { callbacks, profile } = React.useContext(MyPageContext);

  // メニュークリック
  const handleAccountMenu = React.useCallback((event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  }, []);

  // メニュー閉じる
  const handleCloseAccountMenu = React.useCallback(() => {
    setAccountMenuAnchorEl(null);
  }, []);

  // マイ作品
  const handleMyItems = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleMyItems();

  }, [callbacks, handleCloseAccountMenu]);

  // マイアカウント
  const handleMyAccount = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleMyAccount();

  }, [callbacks, handleCloseAccountMenu]);

  // リクエスト設定
  const handleRequestPage = React.useCallback(() => {

    handleCloseAccountMenu();

    const user = firebase.auth().currentUser;
    if (user !== null) {
      window.open(`/request/${user.uid}`, '_blank');
    }

  }, [handleCloseAccountMenu]);

  // 公開作品
  const handlePublicItems = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleExplorePublishItems();

  }, [callbacks, handleCloseAccountMenu]);

  // リクエスト設定
  const handleRequestSettings = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleRequestSettings();

  }, [callbacks, handleCloseAccountMenu]);

  // 受けたリクエスト
  const handleRequestInbox = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleRequestInbox();

  }, [callbacks, handleCloseAccountMenu]);

  // 送ったリクエスト
  const handleSentRequest = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleSentRequest();

  }, [callbacks, handleCloseAccountMenu]);

  // 購入履歴
  const handlePurchaseHistory = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handlePurchaseHistory();

  }, [callbacks, handleCloseAccountMenu]);

  // お知らせ
  const handleNews = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleNews();

  }, [callbacks, handleCloseAccountMenu]);

  const handleSignOut = React.useCallback(() => {

    handleCloseAccountMenu();
    callbacks.handleSignOut();

  }, [callbacks, handleCloseAccountMenu]);

  // ログイン
  const handleLogin = React.useCallback(() => {
    signInHandler();
  }, [signInHandler]);

  // マイページ
  const handleMyPage = React.useCallback(() => {
    window.location.href = '/myPage';
  }, []);

  const user = firebase.auth().currentUser;
  const isEnabledRequestFunction = React.useMemo(() => profile && profile['isEnabledRequestFunction'], [profile]);

  return (
    <>
      <AppBar
        position="static"
        className={classes.appBar}
      >
        <Container
          maxWidth="md"
          className={classes.toolbar}
        >
          <Typography
            variant="h6"
            className={classes.title}
          >
            <Link href="/">
              <img
                src={publyLogo}
                alt="Publy"
                className={classes.logo}
              />
            </Link>
          </Typography>
          { /*!callbacks && !signInHandler ? null :*/ (user === null) ?
            signInHandler && (
              <div
                onClick={handleLogin}
                className={classes.loginButton}
              >
                <img
                  alt="Login"
                  src={headerLoginButton}
                  className={classes.loginImage}
                />
                <span
                  className={classes.loginText}
                >
                  <FormattedMessage id="Log In" />
                </span>
              </div>
            )
          : (
            <>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleAccountMenu}
              >
                <Avatar
                  classes={{ root: classes.avatar }}
                  src={getTwitterProfileImageOriginUrl(user.photoURL)}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={accountMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(accountMenuAnchorEl)}
                onClose={handleCloseAccountMenu}
              >
                { callbacks ? (
                  <>
                    <MenuItem onClick={handleMyItems}><FormattedMessage id="My items" /></MenuItem>
                    <MenuItem onClick={handleMyAccount}><FormattedMessage id="My account" /></MenuItem>
                    <MenuItem onClick={handlePurchaseHistory}><FormattedMessage id="Purchase history" /></MenuItem>
                    <MenuItem onClick={handleNews}><FormattedMessage id="News" /></MenuItem>
                    <Divider
                      classes={{ root: classes.divider }}
                      light
                    />
                    <MenuItem onClick={handlePublicItems}><FormattedMessage id="Public items" /></MenuItem>
                    <Divider
                      classes={{ root: classes.divider }}
                      light
                    />
                    {isEnabledRequestFunction && (
                      <>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="center"
                          classes={{ root: classes.requestMenu }}
                        >
                          <FormattedMessage id="Request" />
                        </Typography>
                        <MenuItem onClick={handleRequestPage}><FormattedMessage id="Reception page" /></MenuItem>
                        <MenuItem onClick={handleRequestSettings}><FormattedMessage id="Request settings" /></MenuItem>
                        <MenuItem onClick={handleSentRequest}><FormattedMessage id="Sent request" /></MenuItem>
                        <MenuItem onClick={handleRequestInbox}><FormattedMessage id="Request inbox" /></MenuItem>
                        <Divider
                          classes={{ root: classes.divider }}
                          light
                        />
                      </>
                    )}
                    <MenuItem onClick={handleSignOut}><FormattedMessage id="Sign out" /></MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem onClick={handleMyPage}><FormattedMessage id="My Page" /></MenuItem>
                  </>
                )}
              </Menu>
            </>
          )}
        </Container>
      </AppBar>
    </>
  );

}
