import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Container } from '@material-ui/core';

import { FormattedMessage } from "react-intl";
import publyLogo from "./publyLogo.png";

const useStyles = makeStyles(theme => ({

  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  footerLinks: {
    margin: 0,
    padding: 0,
  },
  footerLink: {
    display: 'inline-block',
    '&:nth-child(n+2)': {
      paddingLeft: theme.spacing(1),
    },
  },
  footerLogo: {
    height: 15,
    position: 'relative',
    top: 3,
  },

}));

export default function Footer(props) {

  const classes = useStyles();

  return (

    <>
      <Grid
        container
        justifyContent="center"
      >
        <Container
          maxWidth="md"
          classes={{ root: classes.footer }}
        >
          <ul
            className={classes.footerLinks}
          >
            <li
              className={classes.footerLink}
            >
              <Link
                href="/"
              >
                <img
                  src={publyLogo}
                  className={classes.footerLogo}
                  alt="Publy"
                />
              </Link>
            </li>
            <li
              className={classes.footerLink}
            >
              <Link
                href="https://kunimasu.studio.site/"
                color="textSecondary"
                target="_blank"
              >
                <FormattedMessage id="About us" />
              </Link>
            </li>
            <li
              className={classes.footerLink}
            >
              <Link
                href="/"
                color="textSecondary"
                target="_blank"
              >
                <FormattedMessage id="About Publy" />
              </Link>
            </li>
            <li
              className={classes.footerLink}
            >
              <Link
                href="https://kunimasu.notion.site/Publy-a4f0a64d405940beb302aee4cc02c3e1"
                color="textSecondary"
                target="_blank"
              >
                <FormattedMessage id="Help" />
              </Link>
            </li>
            <li
              className={classes.footerLink}
            >
              <Link
                href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/termsofuse.html"
                color="textSecondary"
                target="_blank"
              >
                <FormattedMessage id="Term of use" />
              </Link>
            </li>
            <li
              className={classes.footerLink}
            >
              <Link
                href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/pp.html"
                color="textSecondary"
                target="_blank"
              >
                <FormattedMessage id="Privacy policy" />
              </Link>
            </li>
            <li
              className={classes.footerLink}
            >
              <Link
                href="https://s3-ap-northeast-1.amazonaws.com/publy.fun/misc/law.html"
                color="textSecondary"
                target="_blank"
              >
                <FormattedMessage id="Legal policy" />
              </Link>
            </li>
          </ul>
        </Container>
      </Grid>
    </>

  );

}
