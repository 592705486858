import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';

import Top from './Top';
import Item from './Item';
import MyPage from './MyPage';
import RequestPage from './RequestPage';
import NotFoundPage from './NotFoundPage';
import MaintenancePage from './MaintenancePage';
import ExplorePage from './ExplorePage';
import { IntlProvider } from 'react-intl'
import messages_ja from "./translations/ja.json";
import messages_en from "./translations/en.json";
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/remote-config';
import 'firebase/compat/analytics';
import firebaseConfig from './plugins/firebase';

export const firebaseApp = firebase.initializeApp(firebaseConfig);

// GA
firebase.analytics();

// Remote Config
const firebaseRemoteConfig = firebase.remoteConfig();
const getMaintenanceValue = () => firebaseRemoteConfig.getString('maintenance');
const isMaintenanceMode = () => {

  const value = getMaintenanceValue();
  return value !== '' && sessionStorage.getItem('mainteanceLogin') !== value;

};

const messages = {
  'ja': messages_ja,
  'en': messages_en
};

// ブラウザから言語を特定する
const language = navigator.language.split(/[-_]/)[0];

export default function App() {

  // State
  const [remoteConfigStatus, setRemoteConfigStatus] = React.useState('no-fetch-yet');

  // ブラウザの言語に従う
  firebase.auth().useDeviceLanguage();

  // 常にライトモード
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = false;
  const theme = React.useMemo(() => createTheme({

    typography: {
      fontFamily: 'system-ui',
    },
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      appBarBg: prefersDarkMode ? '#f8f0e8' : '#f8f0e8',
      textFieldBg: prefersDarkMode ? '#f0f0f0' : '#f0f0f0',
      helpLinkBg: prefersDarkMode ? 'white' : 'white',
      imageBg: prefersDarkMode ? "white" : "white",
      primary: {
        main: '#005AA0',
      },
    },

  }), [prefersDarkMode]);

  React.useEffect(() => {

    // RemoteConfigを取得できたら画面表示
    firebaseRemoteConfig.ensureInitialized()
    .then(async () => {

      firebaseRemoteConfig.settings = {
        minimumFetchIntervalMillis: 60 * 60 * 1000,
      };
      firebaseRemoteConfig.defaultConfig = {
        // 空文字以外がメンテ中
        'maintenance': '',
      };
      await firebaseRemoteConfig.fetchAndActivate()
      .catch(console.error);
      setRemoteConfigStatus(firebaseRemoteConfig.lastFetchStatus);

    })
    .catch(console.error);

  }, []);

  theme.overrides = {
    MuiFilledInput: {
      root: {
        backgroundColor: 'unset',
        "&:hover": {
          backgroundColor: '#E6E6E6',
        },
        "&$focused": {
          backgroundColor: "#F0F0F0",
        }
      }
    }
  };

  return (

    <ThemeProvider theme={theme}>
      <IntlProvider locale={language} messages={messages[language]}>
        <React.Fragment>
          <CssBaseline />
          { 'no-fetch-yet' === remoteConfigStatus ? (
            <LinearProgress color="secondary" />
          ) : (
            <BrowserRouter>
              <Routes>
                { isMaintenanceMode() ? (
                  <Route
                    path="*"
                    element={<MaintenancePage maintenanceValue={getMaintenanceValue()} />}
                  />
                ) : (
                  <>
                    <Route index path='/' element={<Top />} />
                    <Route path='/myPage' element={<MyPage />} />
                    <Route path='/explore/@:username' element={<ExplorePage />} />
                    <Route path='/explore/tags/:tag' element={<ExplorePage />} />
                    <Route path='/request/:userId' element={<RequestPage />} />
                    <Route path='/items/:userId/:itemId/' element={<Item />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </>
                )}
              </Routes>
            </BrowserRouter>
          )}
        </React.Fragment>
      </IntlProvider>
    </ThemeProvider>

  );

}

