import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { Divider } from '@material-ui/core';

import twitterSocialIconRoundedSquare from "./twitterSocialIconRoundedSquare.png";
import { getTwitterProfileImageOriginUrl } from "./ImageUtil";
import { termsOfUse } from './WidgetUtil';
import { getTwitterLoginButton } from './WidgetUtil';
import Footer from "./Footer";
import Header from "./Header";
import SendRequestDialog from "./SendRequestDialog";

import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import moment from 'moment';
import { useParams } from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const ICON_SIZE = 10;

const useStyles = makeStyles(theme => ({

  main: {
    margin: theme.spacing(10, 0),
  },
  avatar: {
    width: theme.spacing(ICON_SIZE),
    margin: 'auto',
    marginBottom: theme.spacing(1),
  },
  avatarIcon: {
    width: theme.spacing(ICON_SIZE),
    height: theme.spacing(ICON_SIZE),
    marginLeft: 0,
  },
  twitterIcon: {
    width: 20,
    height: 20,
  },
  description: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(5, 3),
    backgroundColor: theme.palette.appBarBg,
    [theme.breakpoints.up('sm')]: {
      borderRadius: 6,
    },
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  makeRequest: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  noAcceptingRequest: {
    marginTop: theme.spacing(1),
  },
  pastRequestDivider: {
    marginTop: theme.spacing(5),
  },
  pastRequests: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  pastRequestLabel: {
    textAlign: 'center',
  },
  pastRequestMessage: {
    textDecoration: 'underline',
  },
  pastRequest: {
    display: 'block',
    color: 'black',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(4),
    backgroundColor: theme.palette.appBarBg,
    borderRadius: 6,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  requestFromName: {
    marginRight: 4,
  },
  requestMedia: {
    [theme.breakpoints.only('xs')]: {
      width: '50%',
      height: 100,
    },
    [theme.breakpoints.up('sm')]: {
      width: 100,
    },
  },
  loginWrap: {
    marginTop: theme.spacing(4),
    margin: 'auto',
    width: 290,
    height: 80,
  },
  buttonWrap: {
    cursor: 'pointer',
    fontFamily: "HiraKakuProN-W6, sans-serif",
    display: "block",
    float: "left",
    position: "relative",
    borderRadius: 8,
    border: "2px solid black",
    textAlign: "center",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  buttonLogin: {
    width: 290,
    height: 71,
    boxShadow: "-5px 7px 0px 0px black",
  },
  buttonLoginText: {
    fontSize: 20,
    marginTop: 12,
    float: 'left',
    marginLeft: 20,
  },
  buttonSmallText: {
    fontFamily: "Hiragino Kaku Gothic ProN, sans-serif",
    fontSize: 12,
    float: "left",
    marginLeft: 55,
  },
  buttonLoginArrowImage: {
    position: "relative",
    top: 26,
  },
  buttonTwitterImage: {
    float: "left",
  },
  agreementWrap: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
  },

}));

export const RequestPageContext = React.createContext({ callbacks: null });

function RequestPage(props) {

  const { intl } = props;
  const { userId } = useParams();

  const classes = useStyles();

  // State
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [dialog, setDialog] = React.useState(null);
  const [pastRequests, setPastRequests] = React.useState(null);

  // リクエストを送るダイアログ表示
  const showSendRequestDialog = React.useCallback(() => {
    setDialog(<SendRequestDialog />);
  }, []);

  // リクエストを送るを選択
  const handleClickSendRequest = React.useCallback(() => {
    showSendRequestDialog();
  }, [showSendRequestDialog]);

  // リクエストを送るを選択
  const handleClickSignIn = React.useCallback(() => {
    window.location.href = `/?redirectTo=${window.location.href}`;
  }, []);

  // ダイアログを閉じる
  const closeDialog = React.useCallback(() => {
    setDialog(null);
  }, []);

  // アップロードしたリクエスト作品を見る
  const handleClickSeeItem = React.useCallback((item) => {

    const creatorUserId = item['creatorUserId'];
    const requestId = item['id'];

    // itemsを検索する
    firebase.firestore()
    .collection('profiles')
    .doc(creatorUserId)
    .collection('items')
    .where('requestId', '==', requestId)
    .limit(1)
    .get()
    .catch(console.error)
    .then(querySnapshot => {

      if (!querySnapshot.empty) {

        const doc = querySnapshot.docs[0];
        const itemId = doc.id;
        const win = window.open(`/items/${creatorUserId}/${itemId}`, '_blank');
        if (!win) {
          alert(intl.formatMessage({ id: 'Blocked pop-up. Allow pop-up.' }));
        }

      }

    });

  }, [intl]);

  // メイン
  const mainContents = React.useMemo(() => {

    if (profile === null) return null;
    return (
      <>
        <main
          className={classes.main}
        >
          <Container
            maxWidth="sm"
            classes={{ root: classes.profile }}
          >
            <div
              className={classes.avatar}
            >
              <AvatarGroup>
                <Avatar
                  src={getTwitterProfileImageOriginUrl(profile.profileImage)}
                  className={classes.avatarIcon}
                />
              </AvatarGroup>
            </div>
            <Typography
              variant="h5"
              align="center"
              paragraph
            >
              <div>
                {profile.name}
              </div>
              <div>
                <Link
                  underline="none"
                  href={`https://twitter.com/${profile['username']}`}
                  target="_blank"
                >
                  <img
                    alt={`${profile['username']} Twitter`}
                    src={twitterSocialIconRoundedSquare}
                    className={classes.twitterIcon}
                  />
                </Link>
              </div>
            </Typography>
          </Container>
          { profile.requestDescription && (
            <Container
              maxWidth="sm"
              classes={{ root: classes.description }}
            >
              <Typography
                variant="body1"
                classes={{ root: classes.descriptionText }}
              >
                {profile.requestDescription}
              </Typography>
            </Container>
          )}
          <Container
            maxWidth="sm"
            classes={{ root: classes.makeRequest }}
          >
            { user === null ? (
              <>
                { getTwitterLoginButton(classes, handleClickSignIn) }
                <Typography
                  variant="body1"
                  classes={{ root: classes.agreementWrap }}
                >
                  { termsOfUse }
                </Typography>
              </>
            ) : (!profile.requestAcceptingItem || profile.requestAcceptingItem === 0 || profile.isEnabledRequestFunction !== true) ? (
              <Typography
                variant="body1"
                classes={{ root: classes.noAcceptingRequest }}
                paragraph
              >
                <FormattedMessage id="Currently not accepting requests" />&nbsp;😢
              </Typography>
            ) : (
              <Button
                disabled={profile.requestAcceptingItem === 0}
                variant="contained"
                color="primary"
                onClick={handleClickSendRequest}
              >
                <FormattedMessage id="Send request" />
              </Button>
            )}
          </Container>
          {pastRequests && (
            <>
              <Container
                maxWidth="md"
                classes={{ root: classes.pastRequests }}
              >
              <Divider
                light
                classes={{ root: classes.pastRequestDivider }}
              />
              </Container>
              <Container
                maxWidth="sm"
                classes={{ root: classes.pastRequests }}
              >
                <Typography
                  variant="h5"
                  classes={{ root: classes.pastRequestLabel }}
                >
                  <FormattedMessage id="Past requests" />
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  classes={{ root: classes.pastRequestLabel }}
                  paragraph
                >
                  <FormattedMessage id="Display up to 10 most recent requests" />
                </Typography>
                {pastRequests.map(request => (
                  <Link
                    underline="none"
                    target="_blank"
                    className={classes.pastRequest}
                    onClick={() => handleClickSeeItem(request)}
                  >
                    <Typography
                      variant="body2"
                      classes={{ root: classes.pastRequestMessage }}
                    >
                      {request.message}
                    </Typography>
                    <Typography
                      align="right"
                    >
                      <Typography
                        component="span"
                        variant="caption"
                        color="textSecondary"
                        classes={{ root: classes.requestFromName }}
                      >
                        {request.clientName}
                      </Typography>
                      <Typography
                        component="span"
                        variant="caption"
                        color="textSecondary"
                      >
                        <FormattedMessage id="DD-MM, YYYY">
                          { format => (moment(request['createdAt'].toDate()).format(format[0])) }
                        </FormattedMessage>
                      </Typography>
                    </Typography>
                  </Link>
                ))}
              </Container>
            </>
          )}
        </main>
      </>
    );

  }, [user, profile, classes, handleClickSendRequest, handleClickSignIn, pastRequests, handleClickSeeItem]);

  // ヘッダー
  const header = React.useMemo(() => {
    return (<Header />);
  }, []);

  // フッター
  const footer = React.useMemo(() => {
    return (<Footer />);
  }, []);

  const showErrorAlert = React.useCallback((reason) => {

    console.error(reason);
    window.alert(intl.formatMessage({ id: 'Not found user' }));
    window.location.href = '/';

  }, [intl]);

  React.useEffect(() => {

    async function _setPastRequests() {

      const requests = await firebase.firestore()
      .collection('requests')
      .where('creatorUserId', '==', userId)
      .limit(10)
      .orderBy('submittedAt', 'desc')
      .get()
      .catch(console.error);
      if (requests && requests.size > 0) {
        setPastRequests(requests.docs.map(e => {
          return { id: e.id, ...e.data() };
        }));
      }

    };

    async function _setProfile() {

      const profile = await firebase.firestore()
      .collection('profiles')
      .doc(userId)
      .get()
      .catch(showErrorAlert);
      if (profile && profile.exists) {
        setProfile(profile.data());
      }

    };

    firebase.auth().onAuthStateChanged(async function(user) {
      setUser(user);
    });

    _setProfile();
    _setPastRequests();

  }, [userId, showErrorAlert]);

  // コールバック
  const callbacks = React.useMemo(() => {

    return {
      closeDialog: closeDialog,
    };

  }, [closeDialog]);

  return (

    <RequestPageContext.Provider value={{
      callbacks: callbacks,
      creatorUserId: userId,
    }}>
      { profile === null ? <LinearProgress color="secondary" /> : (
        <>
          { header }
          { mainContents }
          { footer }
          { dialog }
        </>
      )}
    </RequestPageContext.Provider>

  );

}

export default injectIntl(RequestPage);
