import React from 'react';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

import moment from 'moment';
import { MyPageContext } from './MyPage';
import InteractiveButton from './InteractiveButton';
import { FormattedMessage, injectIntl } from "react-intl";
import { isValidEmail } from "./Validation";

const QUERY_LIMIT = 10;

const useStyles = makeStyles(theme => ({

  textField: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.textFieldBg,
  },
  button: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
  noNews: {
    marginTop: theme.spacing(2),
  },
  news: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '&:nth-child(n+2)': {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(1),
    },
  },
  text: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },

}));

const getDefaultValidationMessages = () => {

  return {
    emailForNews: null,
  };

};

function NewsDialog(props) {

  const { intl } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [snackbarOpenType, setSnackbarOpenType] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [validationMessages, setValidationMessages] = React.useState(getDefaultValidationMessages());
  const [items, setItems] = React.useState(null);

  // Ref
  const emailForNewsRef = React.useRef(null);
  const saveButtonHandlerRef = React.useRef(null);
  const itemsRef = React.useRef([]);
  const lastVisibleItemRef = React.useRef(null);
  const isLoadedToEndRef = React.useRef(false);
  const isLoadingRef = React.useRef(false);
  const scrollElementRef = React.useRef(null);

  // Context
  const { callbacks } = React.useContext(MyPageContext);

  // Snackbar非表示
  const handleCloseSnackbar = React.useCallback(() => {
    setSnackbarOpenType(null);
  }, []);

  const setItemsRef = React.useCallback((next) => {

    itemsRef.current = itemsRef.current.concat(next);
    setItems(itemsRef.current);

  }, []);

  // 保存
  const handleSave = React.useCallback((callback) => {

    const emailForNews = emailForNewsRef.current.value.trim();
    const validationMessages = getDefaultValidationMessages();

    // ひとまず今は必須なし
    // コードを残しているだけ
    let focusField = null;
    if (emailForNews.length > 0 && !isValidEmail(emailForNews)) {

      focusField = emailForNewsRef.current;
      validationMessages.emailForNews = intl.formatMessage({ id: 'Enter email format' });

    }

    setValidationMessages(validationMessages);

    if (focusField !== null) {

      focusField.focus();
      callback(false);
      return;

    }

    const newData = {
      emailForNews: emailForNews
    };

    const user = firebase.auth().currentUser;
    firebase.firestore()
    .collection('profiles')
    .doc(user.uid)
    .set(newData, { merge: true })
    .then(function() {

      setSnackbarOpenType('save');
      callback(true);

    })
    .catch(function(error) {

      console.error(error);
      callback(false);

    });

  }, [intl]);

  const queryItems = React.useCallback(() => {

    isLoadingRef.current = true;
    const user = firebase.auth().currentUser;

    let query = firebase.firestore()
    .collection('mail')
    .where('userId', '==', user.uid)
    .orderBy('createdAt', 'desc');
    if (lastVisibleItemRef.current) {
      query = query.startAfter(lastVisibleItemRef.current)
    }
    query.limit(QUERY_LIMIT)
    .get()
    .then(async querySnapshot => {

      const docs = querySnapshot.docs;

      lastVisibleItemRef.current = docs[docs.length - 1];

      if (docs.length < QUERY_LIMIT) {
        isLoadedToEndRef.current = true;
      }
      setItemsRef(docs.map(doc => {

        // 作品に関する情報が注文データになければ取得する
        const mailData = doc.data();
        return {
          id: doc.id,
          subject: mailData['newsSubject'],
          text: mailData['newsBody'],
          createdAt: mailData['createdAt'],
        };

      }));

    })
    .catch(console.error)
    .finally(() => {
      isLoadingRef.current = false;
    });

  }, [setItemsRef]);

  // 無限スクロール
  const endlessScroll = React.useCallback(() => {

    const scrollElement = scrollElementRef.current;
    if (Math.round(scrollElement.clientHeight + scrollElement.scrollTop) >= scrollElement.scrollHeight
      && !isLoadedToEndRef.current
      && !isLoadingRef.current) {
      queryItems();
    }

  }, [queryItems]);

  // 無限スクロール解除
  const removeEndlessScrollListener = React.useCallback(() => {

    if (scrollElementRef.current !== null) {
      scrollElementRef.current.removeEventListener('scroll', endlessScroll);
    }

  }, [endlessScroll]);

  React.useEffect(() => {

    queryItems();
    return () => {
      removeEndlessScrollListener();
    }

  }, [queryItems, removeEndlessScrollListener]);

  const setScrollElement = React.useCallback((el) => {

    // 無限スクロール登録
    const addEndlessScrollListener = () => {
      scrollElementRef.current.addEventListener('scroll', endlessScroll);
    };

    if (el !== null && scrollElementRef.current === null) {

      scrollElementRef.current = el;
      addEndlessScrollListener();

    }

  }, [endlessScroll]);

  React.useEffect(() => {

    const user = firebase.auth().currentUser;

    // プロフィールを取得
    const getProfile = () => {

      return firebase.firestore()
      .collection('profiles')
      .doc(user.uid)
      .get()
      .then(function(doc) {

        const profileData = doc.data() || {};
        setProfile({
          emailForNews: profileData.emailForNews || '',
        });

      })
      .catch(console.error);

    };

    getProfile();

  }, []);

  React.useEffect(() => {

    // アイテム作成画面のpopstateを保持しておく
    // 閉じるときに再設定がいるので
    const prevPopstate = window.onpopstate || null;

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = callbacks.closeDialog;
    return () => {
      window.onpopstate = prevPopstate;
    };

  }, [callbacks]);

  return (

    <>

      <Snackbar open={snackbarOpenType !== null} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          { snackbarOpenType === 'save' ? (
            <FormattedMessage id="Saved successfully" />
          ) : null}
        </Alert>
      </Snackbar>
      <Dialog
        maxWidth="md"
        fullScreen={fullScreen}
        open
        onClose={callbacks.closeDialog}
      >
        <DialogTitle>
          <FormattedMessage id="News" />
        </DialogTitle>
        { profile === null ? <LinearProgress color="secondary" /> : (
          <>
            <DialogContent
              ref={setScrollElement}
            >
              <div className={classes.contents}>
                <TextField
                  defaultValue={profile && profile.emailForNews}
                  label={<FormattedMessage id="Email to receive news" />}
                  inputRef={emailForNewsRef}
                  variant="filled"
                  fullWidth
                  error={validationMessages.emailForNews != null}
                  helperText={validationMessages.emailForNews}
                  classes={{ root: classes.textField }}
                />
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  <FormattedMessage id="You will be notified by email when your item is sold. If you do not need email notification, empty your email address." />
                </Typography>
                <div className={classes.button}>
                  <InteractiveButton
                    text={<FormattedMessage id="Save" />}
                    clickHandler={handleSave}
                    handlerRef={saveButtonHandlerRef}
                  />
                </div>
              </div>
              <Divider
                classes={{ root: classes.divider }}
                light
              />
              { items === null ? null : (
                items.length === 0 ? (
                  <>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.noNews }}
                      paragraph
                    >
                      <FormattedMessage id="There is no news." />
                    </Typography>
                  </>
                ) : (
                  <div>
                    { items.map(item => (
                      <div
                        className={classes.news}
                        key={item['id']}
                      >
                        <Typography
                          component="div"
                          variant="h6"
                          color="textPrimary"
                          classes={{ root: classes.subject }}
                          gutterBottom
                        >
                          {item['subject']}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body2"
                          color="textSecondary"
                          classes={{ root: classes.text }}
                        >
                          {item['text']}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body2"
                          color="textSecondary"
                          align="right"
                        >
                          <FormattedMessage id="DD-MM, YYYY">
                            { format => (moment(item['createdAt'].toDate()).format(format[0])) }
                          </FormattedMessage>
                        </Typography>
                      </div>
                    ))}
                  </div>
                )
              )}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={callbacks.closeDialog}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );

}

export default injectIntl(NewsDialog);
