import React from 'react';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({

  mybook: {
    margin: 'auto',
    top: '25%',
  },
  actionContainer: {
    textAlign: 'center',
    position: 'relative',
    top: '25%',
  },
  actionButton: {
    margin: theme.spacing(1),
  },
  blurImage: {
    filter: 'blur(3px)',
  },
  unblurImage: {
  },

}));

export function PagePreviewDialog(props) {

  const { blurList, direction, closeCallback } = props
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // Ref
  const dialogRef = React.useRef(null);

  const getPreviewPages = React.useCallback(() => {

    if (dialogRef.current === null) {
      return;
    }

    const maxWidth = (dialogRef.current.clientWidth / 2);
    const html = `

      function renderPreviewPages() {

        // ぼかすところ
        const blurList = [${blurList}];

        // 前ページそろってから
        let pageImages = $(".pageImage");
        if (pageImages.length != ${blurList.length}) {
          return;
        }

        // 右綴じなら反転
        if ("${direction}" === 'right') {
          pageImages = $(pageImages.get().reverse());
        }

        // 表示する画像
        const elms = pageImages.map((i, p) => {

          return $('<img>')
            .attr('src', p.src)
            .attr('class', blurList[i] === true ? '${classes.blurImage}' : '${classes.unblurImage}');

        }).get();

        // 空にしてから追加
        $('#mybook').empty();
        elms.forEach((e) => $('#mybook').append(e));

        // 画面幅を考慮しつつ、プレビューを構築
        const dialogPadding = 24 * 1;
        const width = Math.min(${maxWidth}, pageImages[0].width) - dialogPadding;
        const height = width * pageImages[0].height / pageImages[0].width;

        $('#mybook').turn({
          autoCenter: true,
          width: width * 2,
          height: height,
          page: "${direction}" === 'right' ? ${blurList.length} : 1,
        });

        // 他も描画
        const actionContainer = $('<div>').attr('class', "${classes.actionContainer}").appendTo($('#mybook').parent());
        $('<button>').text('←').attr('class', "${classes.actionButton}")
          .click(() => $('#mybook').turn('previous')).appendTo(actionContainer);
        $('<button>').text('→').attr('class', "${classes.actionButton}")
          .click(() => $('#mybook').turn('next')).appendTo(actionContainer);
        $(window).bind('keydown', function(e) {
          if (e.keyCode == 37) {
            $('#mybook').turn('previous');
          } else if (e.keyCode == 39) {
            $('#mybook').turn('next');
          }
        });

      };
      function loadScript(url, id, onload) {

        const script = document.createElement('script');
        script.src = url;
        script.id = id;
        script.onload = onload;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);

      };
      function onloadedJQuery() {
        loadScript('/js/turn.min.js', 'turnScript', renderPreviewPages);
      };
      // jQueryからロード
      if (document.getElementById('jQueryScript')) {
        renderPreviewPages();
      } else {
        loadScript('/js/jquery-3.5.1.min.js', 'jQueryScript', onloadedJQuery);
      }

    `;

    const s = document.createElement("script");
    s.id = 'previewPageScript';
    s.innerHTML = html;
    dialogRef.current.appendChild(s);

  }, [blurList, classes, direction]);

  const setDialogRef = React.useCallback((ref) => {

    dialogRef.current = ref;
    getPreviewPages();

  }, [getPreviewPages]);

  return (

    <Dialog
      open
      fullScreen={fullScreen}
      onClose={closeCallback}
      ref={setDialogRef}
    >
      <DialogContent
      >
        <div
          id="mybook"
          className={classes.mybook}
        >
          <center>Loading...</center>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeCallback}
          autoFocus
        >
          <FormattedMessage id="Close" />
        </Button>
      </DialogActions>
    </Dialog>

  );

}
