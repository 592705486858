import React from 'react';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Link } from '@material-ui/core';

import { red } from '@material-ui/core/colors';

import twitterSocialIconRoundedSquare from "./twitterSocialIconRoundedSquare.png";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import moment from 'moment';
import { Promise } from "bluebird";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import { MyPageContext } from "./MyPage";

const QUERY_LIMIT = 10;

const useStyles = makeStyles(theme => ({

  inboxList: {
    '&:nth-child(n+2)': {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
  },
  label: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  requestDate: {
    marginRight: theme.spacing(1),
  },
  itemTitle: {
    marginRight: theme.spacing(1),
  },
  clientIcon: {
    position: 'relative',
    top: 3,
  },
  clientName: {
    marginLeft: 4,
  },
  message: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.appBarBg,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    borderRadius: 6,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  yet: {
    fontWeight: 'bold',
    color: red[500],
  },
  done: {
  },
  expired: {
  },
}));

// リクエスト期限を取得
function getLimitDate(item) {
  return moment(item['createdAt'].toDate()).add(item['submissionDays'], 'd');
};

function RequestInboxDialog(props) {

  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [items, setItems] = React.useState(null);

  // Ref
  const itemsRef = React.useRef([]);
  const lastVisibleItemRef = React.useRef(null);
  const isLoadedToEndRef = React.useRef(false);
  const isLoadingRef = React.useRef(false);
  const scrollElementRef = React.useRef(null);

  // Context
  const { callbacks } = React.useContext(MyPageContext);

  // アップロードボタン
  const handleClickUploadData = React.useCallback((item) => {

    const requestId = item['id'];
    const requestItemType = item['itemType'];
    let itemType = null;
    if (['calendarWithDate', 'calendarNoDate'].indexOf(requestItemType) > -1) {
      itemType = 'calendar';
    } else if ('magazine' === requestItemType) {
      itemType = 'magazine';
    }
    if (itemType !== null) {
      callbacks.handleUploadRequest(requestId, itemType);
    }

  }, [callbacks]);

  // アップロードしたリクエスト作品を見る
  const handleClickSeeItem = React.useCallback((item) => {

    const creatorUserId = item['creatorUserId'];
    const requestId = item['id'];

    // itemsを検索する
    firebase.firestore()
    .collection('profiles')
    .doc(creatorUserId)
    .collection('items')
    .where('requestId', '==', requestId)
    .limit(1)
    .get()
    .catch(console.error)
    .then(querySnapshot => {

      if (!querySnapshot.empty) {

        const doc = querySnapshot.docs[0];
        const itemId = doc.id;
        const win = window.open(`/items/${creatorUserId}/${itemId}`, '_blank');
        if (!win) {
          alert(intl.formatMessage({ id: 'Blocked pop-up. Allow pop-up.' }));
        }

      }

    });

  }, [intl]);

  const setItemsRef = React.useCallback((next) => {

    itemsRef.current = itemsRef.current.concat(next);
    setItems(itemsRef.current);

  }, []);

  const queryItems = React.useCallback(() => {

    isLoadingRef.current = true;
    const user = firebase.auth().currentUser;

    let query = firebase.firestore()
    .collection('requests')
    .where('creatorUserId', '==', user.uid)
    .where('paidAt', '!=', null)
    .orderBy('paidAt', 'desc');
    if (lastVisibleItemRef.current) {
      query = query.startAfter(lastVisibleItemRef.current)
    }
    query.limit(QUERY_LIMIT)
    .get()
    .then(async querySnapshot => {

      const docs = querySnapshot.docs;

      lastVisibleItemRef.current = docs[docs.length - 1];

      if (docs.length < QUERY_LIMIT) {
        isLoadedToEndRef.current = true;
      }

      setItemsRef(await Promise.map(docs, async doc => {

        // 作品に関する情報が注文データになければ取得する
        const requestData = doc.data();
        return {
          id: doc.id,
          ...requestData
        };

      }));

    })
    .catch(console.error)
    .finally(() => {
      isLoadingRef.current = false;
    });

  }, [setItemsRef]);

  // 無限スクロール
  const endlessScroll = React.useCallback(() => {

    const scrollElement = scrollElementRef.current;
    if (Math.round(scrollElement.clientHeight + scrollElement.scrollTop) >= scrollElement.scrollHeight
      && !isLoadedToEndRef.current
      && !isLoadingRef.current) {
      queryItems();
    }

  }, [queryItems]);

  // 無限スクロール解除
  const removeEndlessScrollListener = React.useCallback(() => {

    if (scrollElementRef.current !== null) {
      scrollElementRef.current.removeEventListener('scroll', endlessScroll);
    }

  }, [endlessScroll]);

  React.useEffect(() => {

    // 検索する
    queryItems();
    return () => {
      removeEndlessScrollListener();
    }

  }, [queryItems, removeEndlessScrollListener]);

  const setScrollElement = React.useCallback((el) => {

    // 無限スクロール登録
    const addEndlessScrollListener = () => {
      scrollElementRef.current.addEventListener('scroll', endlessScroll);
    };

    if (el !== null && scrollElementRef.current === null) {

      scrollElementRef.current = el;
      addEndlessScrollListener();

    }

  }, [endlessScroll]);

  React.useEffect(() => {

    // アイテム作成画面のpopstateを保持しておく
    // 閉じるときに再設定がいるので
    const prevPopstate = window.onpopstate || null;

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = callbacks.closeDialog;
    return () => {
      window.onpopstate = prevPopstate;
    };

  }, [callbacks]);

  return (

    <>
      <Dialog
        maxWidth="md"
        fullScreen={fullScreen}
        open
        onClose={callbacks.closeDialog}
      >
        <DialogTitle>
          <FormattedMessage id="Request inbox" />
        </DialogTitle>
        { items === null ? (
          <LinearProgress color="secondary" />
        ) : (
          <DialogContent
            ref={setScrollElement}
          >
            { items.length === 0 ? (
              <>
                <Typography
                  component="div"
                  variant="body2"
                  color="textPrimary"
                  paragraph
                >
                  <FormattedMessage id="You haven't received yet." />
                </Typography>
              </>
            ) : (
              items.map(item => (
                <div
                  className={classes.inboxList}
                  key={item['id']}
                >
                  <Typography
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.label }}
                    >
                      <FormattedMessage id="Request date" />
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      classes={{ root: classes.requestDate }}
                      paragraph
                    >
                      <FormattedMessage id="DD-MM, YYYY">
                        { format => (moment(item['createdAt'].toDate()).format(format[0])) }
                      </FormattedMessage>
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    classes={{ root: classes.label }}
                  >
                    <FormattedMessage id="Request item type" />
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    paragraph={!item['calendarDate']}
                  >
                    {item['itemType'] && item['itemType'] === 'magazine' ? (
                      <FormattedMessage id="Magazine" />
                    ) : item['itemType'] === 'calendarWithDate' ? (
                      <FormattedMessage id="Calendar with date" />
                    ) : (
                      <FormattedMessage id="Calendar with no date" />
                    )}
                  </Typography>
                  {item['calendarDate'] && (
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      paragraph
                    >
                      <FormattedMessage id="MMM, YYYY">
                        { format => (moment(item['calendarDate'].toDate()).format(format[0])) }
                      </FormattedMessage>
                      &nbsp;
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    classes={{ root: classes.label }}
                  >
                    <FormattedMessage id="Sales" />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage
                      id="¥{ yen }"
                      values={{ yen: Number(item['sales']).toLocaleString() }}
                    />
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    paragraph
                  >
                    <FormattedMessage id="Sales are calculated by subtracting service fee from requested amount of ¥{ amount }" values={{ amount: Number(item['price']).toLocaleString() }}  />
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    classes={{ root: classes.label }}
                  >
                    <FormattedMessage id="Request message" />&nbsp;💎
                  </Typography>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textPrimary"
                    classes={{ root: classes.message }}
                    gutterBottom
                  >
                    {item['message']}
                  </Typography>
                  <Typography
                    component="div"
                    paragraph
                  >
                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                      classes={{ root: classes.label }}
                    >
                      <FormattedMessage id="from" />
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      color="textPrimary"
                    >
                      <Link
                        target="_blank"
                        href={`https://twitter.com/${item['clientUsername']}`}
                      >
                        <img
                          alt="Twitter"
                          height="20"
                          src={twitterSocialIconRoundedSquare}
                          className={classes.clientIcon}
                        />
                        <span
                          className={classes.clientName}
                        >
                          {item['clientName']}
                        </span>
                      </Link>
                    </Typography>
                  </Typography>
                  {item['submittedAt'] ? (
                    <>
                      <Typography
                        component="div"
                        variant="caption"
                        color="textSecondary"
                        classes={{ root: classes.done }}
                        gutterBottom
                      >
                        <FormattedMessage id="DD-MM, YYYY">
                          { format => (
                            <FormattedMessage
                              id="You have responded to this request at { date }"
                              values={{ date: moment(item['submittedAt'].toDate()).format(format[0]) }}
                            />
                          )}
                        </FormattedMessage>
                      </Typography>
                      <Button
                        variant="outlined"
                        color="default"
                        size="small"
                        onClick={() => handleClickSeeItem(item)}
                      >
                        <FormattedMessage id="See item" />
                      </Button>
                    </>
                  ) : item['cancelledAt'] ? (
                    <>
                      <Typography
                        component="div"
                        color="textSecondary"
                        variant="caption"
                        classes={{ root: classes.cancelled }}
                        gutterBottom
                      >
                        <FormattedMessage id="DD-MM, YYYY">
                          { format => (
                            <FormattedMessage
                              id="Cancelled at { date }"
                              values={{ date: moment(item['cancelledAt'].toDate()).format(format[0]) }}
                            />
                          )}
                        </FormattedMessage>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        component="div"
                        variant="body2"
                        classes={{ root: classes.yet }}
                        gutterBottom
                      >
                        <FormattedMessage id="DD-MM, YYYY">
                          { format => (
                            <FormattedMessage id="Upload deadline is { date }" values={{
                              date: moment(item['createdAt'].toDate()).add(item['submissionDays'], 'd').format(format[0])
                            }} />
                          )}
                        </FormattedMessage>
                        {getLimitDate(item) < moment() && (
                          <Typography
                            component="div"
                            variant="caption"
                            color="textSecondary"
                            classes={{ root: classes.expired }}
                            gutterBottom
                          >
                            <FormattedMessage id="Request deadline has expired and may be cancelled" />
                          </Typography>
                        )}
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleClickUploadData(item)}
                      >
                        <FormattedMessage id="Go creation page" />
                      </Button>
                    </>
                  )}
                </div>
              ))
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={callbacks.closeDialog}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );

}

export default injectIntl(RequestInboxDialog);

