import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

  root: {
    display: 'inline-block',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    color: 'white',
  },
  buttonDisabledSuccess: {
    color: 'rgba(255, 255, 255, .8)!important',
  },
  buttonFullWidth: {
    width: '100%',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

}));

export default function InteractiveButton(props) {

  const { text, color, variant, startIcon, disabled, fullWidth, buttonClassName, clickHandler, handlerRef, buttonClasses } = props;
  const classes = useStyles();

  // State
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const thisButtonClassName = clsx({
    [classes.buttonSuccess]: success,
    [classes.buttonFullWidth]: fullWidth || false,
    [classes.buttonDisabledSuccess]: disabled && success,
  });

  const buttonFullWidthClassName = clsx({
    [classes.buttonFullWidth]: fullWidth || false,
  });

  const stopLoading = React.useCallback((result) => {

    setSuccess(result);
    setLoading(false);

  }, []);

  const handleButtonClick = React.useCallback(() => {

    if (!loading) {

      setSuccess(false);
      setLoading(true);
      clickHandler(stopLoading)

    }

  }, [loading, clickHandler, stopLoading]);

  if (handlerRef) {
    handlerRef.current = handleButtonClick;
  }

  return (

    <div className={buttonFullWidthClassName + ' ' + classes.root}>
      <div className={buttonFullWidthClassName + ' ' + classes.wrapper}>
        <Button
          variant={variant || "contained"}
          color={color || "primary"}
          className={thisButtonClassName + ' ' + (buttonClassName || '')}
          disabled={loading || disabled}
          onClick={handleButtonClick}
          startIcon={startIcon}
          classes={buttonClasses}
        >
          {text}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>

  );
}

