import React from 'react';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

import { red } from '@material-ui/core/colors';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

import { MyPageContext } from './MyPage';
import InteractiveButton from './InteractiveButton';
import { FormattedMessage, injectIntl } from "react-intl";
import { ITEM_MIN_PRICE } from './ItemUtil';
import { SERVICE_FEE } from './ItemUtil';
import { ITEM_COST } from './ItemUtil';
import { computeServiceFee } from './ItemUtil';
import { isCheckedAcceptingCalendarWithDate } from './ItemUtil';
import { isCheckedAcceptingCalendarNoDate } from './ItemUtil';
import { isCheckedAcceptingMagazine } from './ItemUtil';

// 一回あたりリクエストできる金額
const MAX_REQUEST_PRICE = 100000;

const useStyles = makeStyles(theme => ({

  contents: {
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(3),
    },
  },
  textField: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.textFieldBg,
  },
  textFieldWrap: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  textFieldWrapNoTopMargin: {
    display: 'flex',
  },
  textFieldMain: {
    backgroundColor: theme.palette.textFieldBg,
    display: 'inline-flex',
  },
  textFieldUnit: {
    display: 'inline-flex',
    marginLeft: 4,
    alignItems: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  listOfApplications: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
  listOfSalesApplicationsAnchorEl: {
    marginTop: theme.spacing(7),
  },
  emptySalesApplications: {
    marginTop: theme.spacing(2),
  },
  helpText: {
    position: 'relative',
    top: -7,
    left: 4,
  },
  updateAmazonGiftCode: {
    cursor: 'pointer',
  },
  verifiedUser: {
    position: 'relative',
    left: -2,
    marginBottom: theme.spacing(1),
  },
  verifiedUserIcon: {
    color: 'green',
    marginRight: 4,
  },
  verifiedUserText: {
    color: 'green',
    fontWeight: 'bold',
    position: 'relative',
    top: -7,
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  aboutRequestHead: {
    padding: theme.spacing(1),
    color: red[500],
    border: `1px solid ${red[500]}`,
    borderRadius: 4,
  },
  aboutRequestTitle: {
    fontWeight: 'bold',
  },
  aboutRequestBody: {
  },
  agreed: {
    color: red[500],
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  agreedDivider: {
    marginTop: theme.spacing(2),
  },
  yourSales: {
    color: red[500],
    fontWeight: 'bold',
  },
  noteList: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: theme.spacing(1),
  },
  suspendedRequest: {
    margin: theme.spacing(0, 1),
  },

}));

const getDefaultValidationMessages = () => {

  return {
    submissionDays: null,
    magazineMinOrderPrice: null,
    calendarNoDateMinOrderPrice: null,
    calendarWithDateMinOrderPrice: null,
    description: null
  };

};

const calendarMinPrice = ITEM_MIN_PRICE['calendar'];
const calendarServiceFee = SERVICE_FEE['calendar'];
const calendarItemCost = ITEM_COST['calendar'];
const magazineMinPrice = ITEM_MIN_PRICE['magazine'];
const magazineServiceFee = SERVICE_FEE['magazine'];
const magazineItemCost = ITEM_COST['magazine'];

const defaultSubmissionDays = 5;

function RequestSettingsDialog(props) {

  const { intl } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [snackbarOpenType, setSnackbarOpenType] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [validationMessages, setValidationMessages] = React.useState(getDefaultValidationMessages());
  const [isAgreed, setAgreed] = React.useState(false);
  const [calendarWithDateSales, setCalendarWithDateSales] = React.useState(null);
  const [calendarNoDateSales, setCalendarNoDateSales] = React.useState(null);
  const [magazineSales, setMagazineSales] = React.useState(null);
  const [acceptingItem, setAcceptingItem] = React.useState(0);
  const [isShowAgreement, setShowAgreement] = React.useState(false);

  // Ref
  const submissionDaysRef = React.useRef(null);
  const magazineMinOrderPriceRef = React.useRef(null);
  const calendarNoDateMinOrderPriceRef = React.useRef(null);
  const calendarWithDateMinOrderPriceRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const saveButtonHandlerRef = React.useRef(null);

  const calendarWithDateServiceCost = React.useMemo(() => computeServiceFee(calendarWithDateSales, calendarWithDateMinOrderPriceRef, calendarMinPrice), [calendarWithDateSales]);
  const calendarNoDateServiceCost = React.useMemo(() => computeServiceFee(calendarNoDateSales, calendarNoDateMinOrderPriceRef, calendarMinPrice), [calendarNoDateSales]);
  const magazineServiceCost = React.useMemo(() => computeServiceFee(magazineSales, magazineMinOrderPriceRef, magazineMinPrice), [magazineSales]);

  // Context
  const { callbacks } = React.useContext(MyPageContext);

  // 利用規約表示をトグルさせる
  const handleClickToggleAgreement = React.useCallback(() => {
    setShowAgreement(!isShowAgreement);
  }, [isShowAgreement]);

  // Snackbar非表示
  const handleCloseSnackbar = React.useCallback(() => {
    setSnackbarOpenType(null);
  }, []);

  // 同意チェックボックス変更
  const handleChangeAgreement = React.useCallback(() => {
    setAgreed(!isAgreed);
  }, [isAgreed]);

  // 売上を計算する
  const computeSales = React.useCallback((id, price, itemMinPrice, itemCost, serviceFee, salesSetter) => {

    const isTooLowPrice = isNaN(price) || price < itemMinPrice;
    const fee = Math.floor(price * serviceFee);
    const sales = isTooLowPrice ? 0 : Math.max(0, price - itemCost - fee);
    salesSetter(sales);

  }, []);

  // 販売価格を変動したとき
  const handleChangePrice = React.useCallback((event, itemMinPrice, itemCost, serviceFee, salesSetter) => {

    const price = Math.min(MAX_REQUEST_PRICE, parseInt(event.target.value.trim()));
    event.target.value = price;
    computeSales(event.target.id, price, itemMinPrice, itemCost, serviceFee, salesSetter);

  }, [computeSales]);

  // 整数入力テキストフィールドからフォーカスを外したとき
  const handleNumberTextFieldBlur = React.useCallback((event, minValue) => {

    const value = parseInt(event.target.value.trim());
    event.target.value = isNaN(value) ? '' : Math.min(MAX_REQUEST_PRICE, Math.max(value, minValue));

  }, []);

  // カレンダー日付あり受付変更
  const handleChangeAcceptingCalendarWithDate = React.useCallback(() => {

    let newAcceptingItem = acceptingItem & 0b110;
    newAcceptingItem += ((acceptingItem & 0b1) === 0) ? 0b1 : 0;
    setAcceptingItem(newAcceptingItem);

  }, [acceptingItem]);

  // カレンダー日付なし受付変更
  const handleChangeAcceptingCalendarNoDate = React.useCallback(() => {

    let newAcceptingItem = acceptingItem & 0b101;
    newAcceptingItem += ((acceptingItem & 0b10) === 0) ? 0b10 : 0;
    setAcceptingItem(newAcceptingItem);

  }, [acceptingItem]);

  // 雑誌受付変更
  const handleChangeAcceptingMagazine = React.useCallback(() => {

    let newAcceptingItem = acceptingItem & 0b11;
    newAcceptingItem += ((acceptingItem & 0b100) === 0) ? 0b100 : 0;
    setAcceptingItem(newAcceptingItem);

  }, [acceptingItem]);

  // 保存
  const handleSave = React.useCallback((callback) => {

    const submissionDays = submissionDaysRef.current.value.trim();
    const magazineMinOrderPrice = magazineMinOrderPriceRef.current.value.trim();
    const calendarNoDateMinOrderPrice = calendarNoDateMinOrderPriceRef.current.value.trim();
    const calendarWithDateMinOrderPrice = calendarWithDateMinOrderPriceRef.current.value.trim();
    const description = descriptionRef.current.value.trim();

    const validationMessages = getDefaultValidationMessages();
    let focusField = null;
    if (submissionDays.length === 0) {

      focusField = focusField || submissionDaysRef.current;
      validationMessages['submissionDays'] = intl.formatMessage({ id: 'Enter field' });

    }
    if (magazineMinOrderPrice.length === 0) {

      focusField = focusField || magazineMinOrderPriceRef.current;
      validationMessages['magazineMinOrderPrice'] = intl.formatMessage({ id: 'Enter field' });

    }
    if (calendarNoDateMinOrderPrice.length === 0) {

      focusField = focusField || calendarNoDateMinOrderPriceRef.current;
      validationMessages['calendarNoDateMinOrderPrice'] = intl.formatMessage({ id: 'Enter field' });

    }
    if (calendarWithDateMinOrderPrice.length === 0) {

      focusField = focusField || calendarWithDateMinOrderPriceRef.current;
      validationMessages['calendarWithDateMinOrderPrice'] = intl.formatMessage({ id: 'Enter field' });

    }
    if (description.length === 0) {

      focusField = focusField || descriptionRef.current;
      validationMessages['description'] = intl.formatMessage({ id: 'Enter field' });

    }

    setValidationMessages(validationMessages);

    if (focusField !== null) {

      focusField.focus();
      callback(false);
      return;

    }

    const newData = {
      requestSubmissionDays: parseInt(submissionDays),
      requestMagazineMinOrderPrice: parseInt(magazineMinOrderPrice),
      requestCalendarNoDateMinOrderPrice: parseInt(calendarNoDateMinOrderPrice),
      requestCalendarWithDateMinOrderPrice: parseInt(calendarWithDateMinOrderPrice),
      requestDescription: description,
      isAgreedCreatorRequest: true,
      requestAcceptingItem: acceptingItem,
      requestCalendarItemCost: calendarItemCost,
      requestMagazineItemCost: magazineItemCost,
      requestCalendarServiceFee: calendarServiceFee,
      requestMagazineServiceFee: magazineServiceFee,
    };

    const user = firebase.auth().currentUser;
    firebase.firestore()
    .collection('profiles')
    .doc(user.uid)
    .set(newData, { merge: true })
    .then(function() {

      setSnackbarOpenType('save');
      callback(true);

    })
    .catch(function(error) {

      console.error(error);
      callback(false);

    });

  }, [acceptingItem, intl]);

  React.useEffect(() => {

    const user = firebase.auth().currentUser;

    // プロフィールを取得
    const getProfile = () => {

      return firebase.firestore()
      .collection('profiles')
      .doc(user.uid)
      .get()
      .then(function(doc) {

        const profileData = doc.data() || {};
        setProfile({
          ...profileData,
          id: doc.id,
          requestCalendarWithDateMinOrderPrice : profileData.requestCalendarWithDateMinOrderPrice || calendarMinPrice,
          requestCalendarNoDateMinOrderPrice : profileData.requestCalendarNoDateMinOrderPrice || calendarMinPrice,
          requestMagazineMinOrderPrice : profileData.requestMagazineMinOrderPrice || magazineMinPrice,
        });
        setAcceptingItem(profileData['requestAcceptingItem']);

      })
      .catch(console.error);

    };

    getProfile();

  }, []);

  React.useEffect(() => {

    // アイテム作成画面のpopstateを保持しておく
    // 閉じるときに再設定がいるので
    const prevPopstate = window.onpopstate || null;

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = callbacks.closeDialog;
    return () => {
      window.onpopstate = prevPopstate;
    };

  }, [callbacks]);

  const isAgreedCreatorRequest = React.useMemo(() => profile && profile['isAgreedCreatorRequest'] === true, [profile]);
  const defaultAcceptingItem = React.useMemo(() => profile ? profile['requestAcceptingItem'] : null, [profile]);

  React.useEffect(() => {

    if (profile !== null) {

      computeSales('calendarWithDate', profile.requestCalendarWithDateMinOrderPrice, calendarMinPrice, calendarItemCost, calendarServiceFee, setCalendarWithDateSales);
      computeSales('calendarNoDate', profile.requestCalendarNoDateMinOrderPrice, calendarMinPrice, calendarItemCost, calendarServiceFee, setCalendarNoDateSales);
      computeSales('magazine', profile.requestMagazineMinOrderPrice, magazineMinPrice, magazineItemCost, magazineServiceFee, setMagazineSales);

    }

  }, [profile, computeSales]);

  return (

    <>

      <Snackbar open={snackbarOpenType !== null} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          { snackbarOpenType === 'save' ? (
            <>
              <Link
                href={`/request/${profile.id}`}
                underline="always"
                target="_blank"
              >
                <FormattedMessage id="Saved it. Request page is here." />
              </Link>
            </>
          ) : null}
        </Alert>
      </Snackbar>

      <Dialog
        maxWidth="sm"
        fullScreen={fullScreen}
        open
        onClose={callbacks.closeDialog}
      >
        <DialogTitle>
          <FormattedMessage id="Request settings" />
        </DialogTitle>
        { profile === null ? <LinearProgress color="secondary" /> : (
          <>
            <DialogContent>
              { /* 常に表示する */ }
              { (!isAgreedCreatorRequest || isShowAgreement) ? (
                <>
                  <div className={classes.contents}>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      align="center"
                      classes={{ root: classes.aboutRequestHead }}
                      paragraph
                    >
ご利用前に必ずお読みください。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
リクエストとは
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
あなたのファンがあなた（クリエーター）に対して有料で「こういうカレンダーが欲しい」といったリクエストを送ることができるサービスです。
リクエストを受け取ったら、リクエスト内容や金額、ファンとの関係値などから自身の裁量で作品を作ってデータを入稿することで報酬を受けることができます。
入稿後は、Publyの通常作品の売買と同じように私たちが生産・出荷を行いますので安心して制作いただけます。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
対応可能なリクエストのみでOK
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
受信したリクエストの中で対応できるものだけ制作することができます。
もしリクエストの内容が不明瞭だったり、支払われた金額の中で制作が難しいと判断した場合は、キャンセルすることができます。
キャンセルしても、リクエストしたファンに理由や説明などは一切行いません。
またペナルティもありませんので、安心してご利用いただけます。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
報酬は漏れなくお支払い
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
ファンがリクエストを送信するときに決済が行われるため、クリエーターの報酬は事前にPublyが受け取っています。
リクエスト作品のデータ入稿後、クリエーターに報酬が支払われるので、売上未回収のリスクはありません。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
作品データの著作権はクリエーターのもの
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
ファンがリクエストした作品データの著作権は、クリエーターに帰属するので他でも利用ができますが、
リクエスト利用で起きたトラブルなど一切の責任はクリエーターに負っていただくことになります。
届いたカレンダー・雑誌といった商品については、複製し再販売することを除き、ファンが自由に扱えます。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
入稿した作品について
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
入稿したリクエストは、リクエスト時にファンが設定した公開非公開の条件に応じてクリエーターの過去リクエスト作品に掲載するかどうかが決まります。
ファンのその設定以外に掲載を決める方法はありません。
掲載する内容にリクエストの金額は含まれませんので安心してください。
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestTitle }}
                      gutterBottom
                    >
DM・打ち合わせ禁止
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.aboutRequestBody }}
                      paragraph
                    >
すべてのクリエーターが柔軟に対応できるわけではないので、「リクエストする」と「入稿する」の往復のみが本サービスの運用ルールとご理解いただき、
いかなる理由であっても事前事後問わず、完成度や進捗、内容などの打ち合わせを禁止します。
                    </Typography>
                    { isAgreedCreatorRequest ? (
                      <Typography
                        component="div"
                        variant="body1"
                        color="textPrimary"
                        classes={{ root: classes.agreed }}
                      >
                        <FormattedMessage id="You have been agreed" />
                      </Typography>
                    ) : (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={isAgreed}
                              onChange={handleChangeAgreement}
                            />
                          }
                          label={
                            <Typography
                              component="div"
                              variant="body2"
                            >
                              <FormattedMessage id="Agree and create about above" />
                            </Typography>
                          }
                        />
                      </div>
                    )}
                  </div>
                  <Divider
                    light
                    classes={{ root: classes.agreedDivider }}
                  />
                </>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClickToggleAgreement}
                >
                  <FormattedMessage id="Show agreement for request feature" />
                </Button>
              )}
              <div className={classes.contents}>
                <div
                  className={classes.textFieldWrap}
                >
                  <FormattedMessage id="e.g. 5">
                  { placeholder => (
                    <TextField
                      defaultValue={profile.requestSubmissionDays || defaultSubmissionDays}
                      label={<FormattedMessage id="Estimated days for submission" />}
                      autoComplete="off"
                      placeholder={placeholder[0]}
                      inputRef={submissionDaysRef}
                      variant="filled"
                      type="number"
                      fullWidth
                      inputProps={{
                        max: 30,
                        min: 1,
                      }}
                      error={validationMessages.submissionDays != null}
                      helperText={validationMessages.submissionDays}
                      classes={{ root: classes.textFieldMain }}
                      onBlur={(event) => handleNumberTextFieldBlur(event, defaultSubmissionDays)}
                    />
                  )}
                  </FormattedMessage>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    classes={{ root: classes.textFieldUnit }}
                  >
                    <FormattedMessage id="Days" />
                  </Typography>
                </div>
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                >
                  <FormattedMessage id="Enter estimated number of days required for production after receiving request so that fan can make request with confidence." />
                </Typography>
              </div>
              <div className={classes.contents}>
                <Typography
                  component="div"
                  variant="body1"
                  color="textPrimary"
                >
                  <FormattedMessage id="Minimum selling price to receive request" />
                </Typography>
                <div
                  className={classes.textFieldWrapNoTopMargin}
                >
                  <FormattedMessage id="e.g. 500">
                  { placeholder => (
                    <TextField
                      id="calendarWithDate"
                      defaultValue={profile.requestCalendarWithDateMinOrderPrice}
                      label={<FormattedMessage id="Calendar with date" />}
                      autoComplete="off"
                      placeholder={placeholder[0]}
                      inputRef={calendarWithDateMinOrderPriceRef}
                      variant="filled"
                      type="number"
                      fullWidth
                      inputProps={{
                        max: MAX_REQUEST_PRICE,
                        min: calendarMinPrice,
                      }}
                      error={validationMessages.calendarWithDateMinOrderPrice != null}
                      helperText={validationMessages.calendarWithDateMinOrderPrice}
                      classes={{ root: classes.textFieldMain }}
                      onBlur={(event) => handleNumberTextFieldBlur(event, calendarMinPrice)}
                      onChange={(event) => handleChangePrice(event, calendarMinPrice, calendarItemCost, calendarServiceFee, setCalendarWithDateSales)}
                      disabled={!isCheckedAcceptingCalendarWithDate(acceptingItem)}
                    />
                  )}
                  </FormattedMessage>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    classes={{ root: classes.textFieldUnit }}
                  >
                    <FormattedMessage id="Yen" />
                  </Typography>
                  <Tooltip
                    title={<FormattedMessage id="If you check it, you will receive request" />}
                  >
                    <Checkbox
                      color="primary"
                      checked={isCheckedAcceptingCalendarWithDate(acceptingItem)}
                      onChange={handleChangeAcceptingCalendarWithDate}
                    />
                  </Tooltip>
                </div>
                { isCheckedAcceptingCalendarWithDate(acceptingItem) ? (
                  <div
                    className={classes.infoArea}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.yourSales}
                    >
                      <FormattedMessage id="Creator sales is " />
                    </Typography>
                    <Typography
                      className={classes.yourSales}
                      component="span"
                      variant="body2"
                    >
                      <FormattedMessage
                        id="¥{ yen }"
                        values={{
                          yen: Number(calendarWithDateSales).toLocaleString()
                        }}
                      />
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      <FormattedMessage
                        id="(Material cost and fee: ¥{ serviceCost })"
                        values={{
                          serviceCost: Number(calendarWithDateServiceCost).toLocaleString()
                        }}
                      />
                    </Typography>
                  </div>
                ) : (
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage id="Requests will not be accepted" />
                  </Typography>
                )}
                <div
                  className={classes.textFieldWrapNoTopMargin}
                >
                  <FormattedMessage id="e.g. 500">
                  { placeholder => (
                    <TextField
                      id="calendarNoDate"
                      defaultValue={profile.requestCalendarNoDateMinOrderPrice}
                      label={<FormattedMessage id="Calendar with no date" />}
                      autoComplete="off"
                      placeholder={placeholder[0]}
                      inputRef={calendarNoDateMinOrderPriceRef}
                      variant="filled"
                      type="number"
                      fullWidth
                      inputProps={{
                        max: MAX_REQUEST_PRICE,
                        min: calendarMinPrice,
                      }}
                      error={validationMessages.calendarNoDateMinOrderPrice != null}
                      helperText={validationMessages.calendarNoDateMinOrderPrice}
                      classes={{ root: classes.textField }}
                      onBlur={(event) => handleNumberTextFieldBlur(event, calendarMinPrice)}
                      onChange={(event) => handleChangePrice(event, calendarMinPrice, calendarItemCost, calendarServiceFee, setCalendarNoDateSales)}
                      disabled={!isCheckedAcceptingCalendarNoDate(acceptingItem)}
                    />
                  )}
                  </FormattedMessage>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    classes={{ root: classes.textFieldUnit }}
                  >
                    <FormattedMessage id="Yen" />
                  </Typography>
                  <Tooltip
                    title={<FormattedMessage id="If you check it, you will receive request" />}
                  >
                    <Checkbox
                      color="primary"
                      checked={isCheckedAcceptingCalendarNoDate(acceptingItem)}
                      onChange={handleChangeAcceptingCalendarNoDate}
                    />
                  </Tooltip>
                </div>
                { isCheckedAcceptingCalendarNoDate(acceptingItem) ? (
                  <div
                    className={classes.infoArea}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.yourSales}
                    >
                      <FormattedMessage id="Creator sales is " />
                    </Typography>
                    <Typography
                      className={classes.yourSales}
                      component="span"
                      variant="body2"
                    >
                      <FormattedMessage
                        id="¥{ yen }"
                        values={{
                          yen: Number(calendarNoDateSales).toLocaleString()
                        }}
                      />
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      <FormattedMessage
                        id="(Material cost and fee: ¥{ serviceCost })"
                        values={{
                          serviceCost: Number(calendarNoDateServiceCost).toLocaleString()
                        }}
                      />
                    </Typography>
                  </div>
                ) : (
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage id="Requests will not be accepted" />
                  </Typography>
                )}
                <div
                  className={classes.textFieldWrapNoTopMargin}
                >
                  <FormattedMessage id="e.g. 500">
                  { placeholder => (
                    <TextField
                      id="magazine"
                      defaultValue={profile.requestMagazineMinOrderPrice}
                      label={<FormattedMessage id="Magazine (12P with front/back covers)" />}
                      autoComplete="off"
                      placeholder={placeholder[0]}
                      inputRef={magazineMinOrderPriceRef}
                      variant="filled"
                      type="number"
                      fullWidth
                      inputProps={{
                        max: MAX_REQUEST_PRICE,
                        min: magazineMinPrice,
                      }}
                      error={validationMessages.magazineMinOrderPrice != null}
                      helperText={validationMessages.magazineMinOrderPrice}
                      classes={{ root: classes.textField }}
                      onBlur={(event) => handleNumberTextFieldBlur(event, magazineMinPrice)}
                      onChange={(event) => handleChangePrice(event, magazineMinPrice, magazineItemCost, magazineServiceFee, setMagazineSales)}
                      disabled={!isCheckedAcceptingMagazine(acceptingItem)}
                    />
                  )}
                  </FormattedMessage>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    classes={{ root: classes.textFieldUnit }}
                  >
                    <FormattedMessage id="Yen" />
                  </Typography>
                  <Tooltip
                    title={<FormattedMessage id="If you check it, you will receive request" />}
                  >
                    <Checkbox
                      color="primary"
                      checked={isCheckedAcceptingMagazine(acceptingItem)}
                      onChange={handleChangeAcceptingMagazine}
                    />
                  </Tooltip>
                </div>
                { isCheckedAcceptingMagazine(acceptingItem) ? (
                  <div
                    className={classes.infoArea}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.yourSales}
                    >
                      <FormattedMessage id="Creator sales is " />
                    </Typography>
                    <Typography
                      className={classes.yourSales}
                      component="span"
                      variant="body2"
                    >
                      <FormattedMessage
                        id="¥{ yen }"
                        values={{
                          yen: Number(magazineSales).toLocaleString()
                        }}
                      />
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      <FormattedMessage
                        id="(Material cost and fee: ¥{ serviceCost })"
                        values={{
                          serviceCost: Number(magazineServiceCost).toLocaleString()
                        }}
                      />
                    </Typography>
                  </div>
                ) : (
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage id="Requests will not be accepted" />
                  </Typography>
                )}
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                >
                  <ul
                    className={classes.noteList}
                  >
                    <li>
                      <FormattedMessage id="You can uncheck checkbox on right side if you want to not accept request individually." />
                    </li>
                    <li>
                      <FormattedMessage id="Selling price can be set between Calendar ¥{ calendarMinPrice }, Magazine ¥{ magazineMinPrice } and ¥{ requestMaxPrice }."
                        values={{
                          calendarMinPrice: Number(calendarMinPrice).toLocaleString(),
                          magazineMinPrice: Number(magazineMinPrice).toLocaleString(),
                          requestMaxPrice: Number(MAX_REQUEST_PRICE).toLocaleString()
                        }}
                      />
                    </li>
                    <li>
                      <FormattedMessage id="Buyer will pay postage when purchase." />
                    </li>
                    <li>
                      <FormattedMessage id="Creator sales can only you be seen." />
                    </li>
                  </ul>
                </Typography>
              </div>
              <div className={classes.contents}>
                <TextField
                  defaultValue={profile.requestDescription}
                  label={<FormattedMessage id="Message to fans - Up to 500 characters" />}
                  autoComplete="off"
                  inputRef={descriptionRef}
                  inputProps={{
                    maxLength: 500,
                  }}
                  variant="filled"
                  fullWidth
                  error={validationMessages.description != null}
                  helperText={validationMessages.description}
                  required
                  multiline
                  classes={{ root: classes.textField }}
                  rows={5}
                />
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                >
                  <FormattedMessage id="Write advice on how to request it." />
                </Typography>
              </div>
              <div className={classes.contents}>
                <div className={classes.button}>
                  <InteractiveButton
                    text={defaultAcceptingItem === 0 && acceptingItem === 0 ? (
                      <FormattedMessage id="Save" />
                    ) : (defaultAcceptingItem === 0 && acceptingItem > 0 ? (
                      <FormattedMessage id="Save & start accepting requests" />
                    ) : (defaultAcceptingItem > 0 && acceptingItem > 0 ? (
                      <FormattedMessage id="Save" />
                    ) : (
                      <FormattedMessage id="Save & suspend accepting requests" />
                    )))}
                    disabled={!isAgreedCreatorRequest && !isAgreed}
                    clickHandler={handleSave}
                    handlerRef={saveButtonHandlerRef}
                  />
                </div>
              </div>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={callbacks.closeDialog}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );

}

export default injectIntl(RequestSettingsDialog);
