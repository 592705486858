import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { CardActionArea } from '@material-ui/core';
import { CardMedia } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';

import { red } from '@material-ui/core/colors';
import { teal } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';

import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { getDownloadUrl } from "./DownloadFile";
import { RESIZE_SIZE } from "./DownloadFile";
import { getPrintAspectRatio } from './PrintSize';
import { getTwitterProfileImageOriginUrl } from "./ImageUtil";

const ICON_SIZE = 4;

const useStyles = makeStyles(theme => ({

  ownItem: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      '&:nth-child(n+2)': {
        marginTop: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
      width: 295,
    },
  },
  actionAreaRoot: {
    backgroundColor: theme.palette.action.selected,
  },
  actionsRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    cursor: 'pointer',
  },
  contentRoot: {
    width: '100%',
  },
  itemMedia: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 200,
    width: 200,
    margin: 'auto',
  },
  itemTitle: {
    lineHeight: 1.3,
    wordBreak: 'break-all',
    [theme.breakpoints.only('xs')]: {
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(7),
    },
  },
  privateStatus: {
    display: 'inline-block',
    padding: `4px ${theme.spacing(1)}px`,
    backgroundColor: 'white',
    border: `1px solid ${grey[300]}`,
    color: grey[700],
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
  anybodySeeRequestStatus: {
    display: 'inline-block',
    padding: `4px ${theme.spacing(1)}px`,
    backgroundColor: red[100],
    border: `1px solid ${red[200]}`,
    color: 'black',
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
  nowOnSalesStatus: {
    display: 'inline-block',
    padding: `4px ${theme.spacing(1)}px`,
    backgroundColor: red[500],
    color: 'white',
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
  preparingStatus: {
    display: 'inline-block',
    padding: `4px ${theme.spacing(1)}px`,
    border: `1px solid ${red[500]}`,
    color: red[500],
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
  purchaseCount: {
    display: 'flex',
    color: 'white',
    marginTop: theme.spacing(1),
    float: 'left',
  },
  purchaseCountHidden: {
    visibility: 'hidden',
  },
  purchaseCountText: {
    padding: `4px ${theme.spacing(1)}px`,
    backgroundColor: grey[700],
    borderRadius: '4px 0 0 4px',
  },
  purchaseCountValue: {
    backgroundColor: teal[500],
    borderRadius: '0 4px 4px 0',
  },
  salesStatus: {
    clear: 'both',
  },
  requestItem: {
    fontStyle: 'italic',
    fontWeight: 300,
  },
  requestClientName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 300,
  },
  banStatus: {
    position: 'relative',
    top: 8,
  },
  profile: {
    height: theme.spacing(ICON_SIZE),
  },
  iconLarge: {
    float: 'left',
    width: theme.spacing(ICON_SIZE),
    height: theme.spacing(ICON_SIZE),
    marginRight: theme.spacing(1),
  },
  profileName: {
    float: 'left',
    lineHeight: `${theme.spacing(ICON_SIZE)}px`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: `calc(100% - ${theme.spacing(ICON_SIZE)}px - ${theme.spacing(1)}px)`,
  },
  priceDisplay: {
    fontWeight: 'bold',
    color: red[500],
  },

}));

function ItemList(props) {

  const { item, intl, profile, forMyList, isShowProfile } = props;
  const classes = useStyles();

  // State
  const [image, setImage] = React.useState(null);

  // Callbacks
  const handleClick = React.useCallback(() => {

    const win = window.open(`/items/${item['userId']}/${item['id']}`, '_blank');
    if (!win) {
      alert(intl.formatMessage({ id: 'Blocked pop-up. Allow pop-up.' }));
    }

  }, [item, intl]);

  React.useEffect(() => {

    // キャッシュする
    const cacheKey = `${item['id']}-thumbnail`;
    const restoreUrlImage = () => {

      try {
        return sessionStorage.getItem(cacheKey);
      } catch(_) {}

    };

    const storeUrlImage = (canvas) => {

      const imageUrl = canvas.toDataURL("image/png");
      setImage(imageUrl);

      try {
        sessionStorage.setItem(cacheKey, imageUrl);
      } catch(_) {}

    };

    const thumbnailUrl = item['page']['remotePath'].replace('origin', `origin_${RESIZE_SIZE}`);
    const thumbnailUrlImage = restoreUrlImage(thumbnailUrl);
    if (thumbnailUrlImage) {

      setImage(thumbnailUrlImage);
      return;

    }

    getDownloadUrl(thumbnailUrl, (url) => {

      const originImage = new Image();
      originImage.crossOrigin = "anonymous";
      originImage.src = url;
      originImage.onload = function() {

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const type = item['type'];
        const calendarDateOption = item['calendarDateOption'];
        const aspect = getPrintAspectRatio(type, calendarDateOption);

        // 短辺に合わせる
        let width = originImage.width > originImage.height ? originImage.height : originImage.width;
        let height = width * aspect;

        // リサイズ
        if (width > originImage.width) {

          let ratio = originImage.width / width;
          width *= ratio;
          height *= ratio;

        }
        if (height > originImage.height) {

          let ratio = originImage.height / height;
          width *= ratio;
          height *= ratio;

        }

        const x = (originImage.width - width) / 2;
        const y = (originImage.height - height) / 2;

        canvas.width = width;
        canvas.height = height;
        context.fillStyle = 'white';
        context.fillRect(
          0, 0, canvas.width, canvas.height
        );
        context.drawImage(
          originImage,
          x,
          y,
          width,
          height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        storeUrlImage(canvas);

      };

    });

  }, [item]);

  // 閉じ方向テキスト表示取得
  const getBindingDirectionText = React.useCallback((direction) => {
    return 'right' === direction ? intl.formatMessage({ id: 'Right binding' }) : intl.formatMessage({ id: 'Left binding' });
  }, [intl]);

  const reportedReason = item['reportedReason'];

  return (

    <Card
      className={classes.ownItem}
    >
      <CardActionArea
        classes={{ root: classes.actionAreaRoot }}
        onClick={handleClick}
      >
        { image !== null ? (
          <CardMedia
            className={classes.itemMedia}
            image={image}
          />
        ) : (
          <Skeleton
            variant="rect"
            className={classes.itemMedia}
          />
        )}
      </CardActionArea>
      <CardActions
        classes={{ root: classes.actionsRoot }}
        onClick={handleClick}
      >
        <CardContent
          classes={{ root: classes.contentRoot }}
        >

          {item['requestId'] ? (
            <Typography
              variant="h6"
              color="textPrimary"
              component="div"
              classes={{ root: [classes.itemTitle, classes.requestItem].join(' ') }}
            >
              <FormattedMessage id="Request item" />
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                classes={{ root: classes.requestClientName }}
              >
              <FormattedMessage id="from: { name }" values={{ name: item['requestClientName'] }}  />
              </Typography>
            </Typography>
          ) : (
            <Typography
              variant="h6"
              color="textPrimary"
              component="div"
              classes={{ root: classes.itemTitle }}
            >
              {item['title']}
            </Typography>
          )}
          <Typography
            variant="body1"
            color="textSecondary"
          >
            { item['type'] === 'calendar' ? <FormattedMessage id="Calendar type" /> : <FormattedMessage id="Magazine type" /> }
          </Typography>
          { forMyList && (
           <Typography
              variant="body2"
              color="textSecondary"
              component="div"
            >
              <div>
                <span>
                  <FormattedMessage id="Price: ¥{ price }" values={{ price: Number(item['itemPrice']).toLocaleString() }} />
                </span>
                <span>
                  <FormattedMessage id="(Sales: ¥{ sales })" values={{ sales: item['sales'] }}  />
                </span>
              </div>
            </Typography>
          )}
          { forMyList && (
           <Typography
              variant="body2"
              color="textSecondary"
              component="div"
            >
              <div>
                <FormattedMessage id="Created at: " />
                <FormattedMessage id="DD-MM, YYYY">
                  { format => (moment(item['createdAt'].toDate()).format(format[0])) }
                </FormattedMessage>
              </div>
            </Typography>
          )}
          { forMyList && (
            <>
              { item['type'] === 'magazine' ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  <FormattedMessage
                    id="Binding direction: { direction }"
                    values={{ direction: getBindingDirectionText(item['bindingDirection']) }}
                  />
                </Typography>
              ) : <div>&nbsp;</div>}
            </>
          )}
          { forMyList && (
            <Typography
              variant="caption"
              color="textSecondary"
              component="div"
              classes={{
                root: [classes.purchaseCount, item['requestId'] ? classes.purchaseCountHidden : ''].join(' ')
              }}
              align="center"
            >
              <div
                className={classes.purchaseCountText}
              >
                <FormattedMessage id="Purchase count" />
              </div>
              <div
                className={[classes.purchaseCountValue, classes.purchaseCountText].join(' ')}
              >
                {Number(item['purchaseCount'] || 0).toLocaleString()}
              </div>
            </Typography>
          )}
          { !forMyList && (
            <Typography
              variant="body1"
              color="textSecondary"
              component="div"
              classes={{ root: classes.priceDisplay }}
              gutterBottom
            >
              <FormattedMessage id="¥{ yen }" values={{ yen: Number(item['itemPrice']).toLocaleString() }} />
            </Typography>
          )}
          <Typography
            variant="caption"
            color="textSecondary"
            component="div"
            classes={{ root: classes.salesStatus }}
            paragraph={!forMyList}
          >
            {item['requestId'] ? (
              item['isPublic'] ? (
                <div
                  className={classes.anybodySeeRequestStatus}
                >
                  <FormattedMessage id="Everyone can see" />
                </div>
              ) : (
                <div
                  className={classes.privateStatus}
                >
                  <FormattedMessage id="Only see to client" />
                </div>
              )
            ) : (
              <>
                { reportedReason ? (
                  <div
                    className={classes.banStatus}
                  >
                    <FormattedMessage id="This item became private due to reported" />
                  </div>
                ) : (
                  item['shopifyProductId'] ? (
                    item['isPublic'] === false ? (
                      <div
                        className={classes.privateStatus}
                      >
                        <FormattedMessage id="Only open to myself" />
                      </div>
                    ) : (
                      <div
                        className={classes.nowOnSalesStatus}
                      >
                        <FormattedMessage id="Now on sale" />
                      </div>
                    )
                  ) : (
                    <div
                      className={classes.preparingStatus}
                    >
                      <FormattedMessage id="Preparing to purchase" />
                    </div>
                  )
                )}
              </>
            )}
          </Typography>
          { profile && isShowProfile === true && (
            <div
              className={classes.profile + ' clearfix'}
            >
              <Avatar
                alt="Creator icon"
                src={getTwitterProfileImageOriginUrl(profile.profileImage)}
                classes={{ root: classes.iconLarge }}
              />
              <Typography
                compoent="span"
                variant="body2"
                color="textSecondary"
                classes={{ root: classes.profileName }}
              >
                {profile.name}
              </Typography>
            </div>
          )}
        </CardContent>
      </CardActions>
    </Card>
  );

}

export default injectIntl(ItemList);
