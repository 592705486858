import React from 'react';
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { Divider } from '@material-ui/core';

import { VerifiedUser } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import { Money } from '@material-ui/icons';
import { Help } from '@material-ui/icons';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

import moment from 'moment';
import { MyPageContext } from './MyPage';
import DeleteSecurityCriticalDialog from './DeleteSecurityCriticalDialog';
import InteractiveButton from './InteractiveButton';
import { FormattedMessage, injectIntl } from "react-intl";
import { downloadCsvFromLocal } from './DownloadFile';

// 一回あたり売上申請できる金額
const MAX_AMAZON_GIFT_POINT = 100000;

// アマゾンギフトの最低額
const MIN_AMAZON_GIFT_POINT = 1;

const useStyles = makeStyles(theme => ({

  contents: {
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(3),
    },
  },
  textField: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.textFieldBg,
  },
  textFieldWrap: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  textFieldMain: {
    backgroundColor: theme.palette.textFieldBg,
    display: 'inline-flex',
  },
  textFieldUnit: {
    display: 'inline-flex',
    marginLeft: 4,
    alignItems: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  listOfApplications: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
  listOfSalesApplicationsAnchorEl: {
    marginTop: theme.spacing(7),
  },
  emptySalesApplications: {
    marginTop: theme.spacing(2),
  },
  helpText: {
    position: 'relative',
    top: -7,
    left: 4,
  },
  updateAmazonGiftCode: {
    cursor: 'pointer',
  },
  verifiedUser: {
    position: 'relative',
    left: -2,
    marginBottom: theme.spacing(1),
  },
  verifiedUserIcon: {
    color: 'green',
    marginRight: 4,
  },
  verifiedUserText: {
    color: 'green',
    fontWeight: 'bold',
    position: 'relative',
    top: -7,
  },
  divider: {
    marginTop: theme.spacing(3),
  },

}));

const getDefaultValidationMessages = () => {

  return {
    selfIntroduction: null,
    receiveSales: null
  };

};

function AccountSettingsDialog(props) {

  const { intl, additionalUserInfo } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // State
  const [dialog, setDialog] = React.useState(null);
  const [snackbarOpenType, setSnackbarOpenType] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [currentAvailableApplication, setCurrentAvailableApplication] = React.useState(null);
  const [isUpdatedAvailableApplication, setUpdatedAvailableApplication] = React.useState(false);
  const [validationMessages, setValidationMessages] = React.useState(getDefaultValidationMessages());
  const [listOfSalesApplicationsAnchorEl, setListOfSalesApplicationsAnchorEl] = React.useState(null);
  const [listOfSalesApplicationsDone, setListOfSalesApplicationsDone] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  // Ref
  const selfIntroductionRef = React.useRef(null);
  const receiveSalesRef = React.useRef(null);
  const saveButtonHandlerRef = React.useRef(null);
  const receiveSalesButtonHandlerRef = React.useRef(null);
  const selectedDateOfSalesApplicationRef = React.useRef(null);

  // Context
  const { callbacks } = React.useContext(MyPageContext);

  // 過去の売上受取申請の一覧取得のメニュー表示非表示
  const handleCloseToGetListOfSalesApplications = React.useCallback(() => {
    setListOfSalesApplicationsAnchorEl(null);
  }, []);

  // 直近の売上申請した一覧を取得する
  const getRecentSalesApplicationsDone = React.useCallback(() => {

    // この件数を上限にする
    const limit = 50;
    const user = firebase.auth().currentUser;

    firebase.firestore()
    .collection('profiles')
    .doc(user.uid)
    .collection('salesApplications')
    .where('method', '==', 'amazonGiftPoint')
    .where('status', '==', 'done')
    .limit(limit)
    .orderBy('createdAt', 'desc')
    .get()
    .then((querySnapshots) => {

      const docs = querySnapshots.docs;
      const listData = docs.map(doc => {

        const data = doc.data();
        return {
          key: doc.id,
          value: [
            intl.formatMessage({ id: '¥{ yen }' }, { yen: Number(data['receiveSales']).toLocaleString() }),
            moment(data['createdAt'].toDate()).format('YYYY-MM-DD HH:mm:ss')
          ].join(' - ')
        };

      });
      setListOfSalesApplicationsDone(listData);

    })
    .catch(console.error);

  }, [intl]);

  // 申請した日を選択した
  const handleDateOfApplication = React.useCallback((event) => {

    // onClickだと値に変更がない場合（同じ選択する）で0になってしまう
    const id = event.target.value;
    if (id !== undefined) {

      if (id !== 0) {
        selectedDateOfSalesApplicationRef.current = id;
      }
      setListOfSalesApplicationsAnchorEl(document.getElementById("dateOfApplicationSelect"));

    } else {
      handleCloseToGetListOfSalesApplications();
    }

  }, [handleCloseToGetListOfSalesApplications]);

  // 過去の売上受取申請の一覧をCSVでまとめる
  const createSalesApplicationsCsv = React.useCallback(async (lf) => {

    const salesApplicationId = selectedDateOfSalesApplicationRef.current;
    if (salesApplicationId === null) return;

    // ギフト情報を取得する
    const getAmazonGiftCode = firebase.app()
    .functions('asia-northeast1')
    .httpsCallable('getAmazonGiftCode');

    const getAmazonGiftCodeResult = await getAmazonGiftCode({
      salesApplicationId: salesApplicationId
    }).catch(console.error);

    if (getAmazonGiftCodeResult && getAmazonGiftCodeResult.data) {

      function parseDate({ _nanoseconds, _seconds }) {
        return new firebase.firestore.Timestamp(_seconds, _nanoseconds);
      }
      const resultData = JSON.parse(getAmazonGiftCodeResult.data);
      const code = resultData['amazon']['code'];
      const { receiveSales } = resultData;

      const createdAt = parseDate(resultData['createdAt']);
      const expiredAt = parseDate(resultData['amazon']['expiredAt']);

      const csvSep = ', ';
      const colString = [
        intl.formatMessage({ id: 'Application Date' }),
        intl.formatMessage({ id: 'Amount' }),
        intl.formatMessage({ id: 'Amazon Gift Claim Code' }),
        intl.formatMessage({ id: 'Expiration Date' }),
      ].join(csvSep);
      let body = `${colString}${lf()}`;
      body += [
        moment(createdAt.toDate()).format('YYYY-MM-DD HH:mm:ss'),
        Number(receiveSales).toLocaleString(),
        code,
        moment(expiredAt.toDate()).format('YYYY-MM-DD')
      ].join(csvSep)
      body += lf(code);
      return body;

    } else {
      return null;
    }

  }, [intl]);

  // 過去の売上受取申請の一覧をメール
  const handleEmailListOfSalesApplications = React.useCallback(async () => {

    setOpenBackdrop(true);

    const winTitle = intl.formatMessage({ id: 'Sales receipt information' });
    const csvText = await createSalesApplicationsCsv(() => '%0D%0A');
    if (csvText === null) {
      alert(intl.formatMessage({ id: 'Failed to get Amazon Gift' }));
    } else {

      const a = document.createElement('a');
      a.href = `mailto:?subject=[Publy] ${winTitle}&body=${csvText}`;
      a.target = '_blank';
      a.click();

    }

    handleCloseToGetListOfSalesApplications();
    setOpenBackdrop(false);

  }, [intl, createSalesApplicationsCsv, handleCloseToGetListOfSalesApplications]);

  // 過去の売上受取申請の一覧をダウンロード
  const handleDownloadListOfSalesApplications = React.useCallback(async () => {

    setOpenBackdrop(true);

    const csvText = await createSalesApplicationsCsv(() => '\n');
    if (csvText === null) {
      alert(intl.formatMessage({ id: 'Failed to get Amazon Gift' }));
    } else {
      downloadCsvFromLocal(csvText, `Publy-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`);
    }

    handleCloseToGetListOfSalesApplications();
    setOpenBackdrop(false);

  }, [intl, createSalesApplicationsCsv, handleCloseToGetListOfSalesApplications]);

  // 過去の売上受取申請の一覧を別タブでHTML表示する
  const handleOpenAsNewTabListOfSalesApplications = React.useCallback(async () => {

    setOpenBackdrop(true);

    const copyButtonText = intl.formatMessage({ id: 'Copy gift claim code' });
    const winTitle = intl.formatMessage({ id: 'Sales receipt information' });
    const copiedText = intl.formatMessage({ id: 'Copied' });
    const copyLabel = intl.formatMessage({ id: 'Copy Amazon Gift Claim Code' });

    const csvText = await createSalesApplicationsCsv(
      (c) => (c ? `<button style="margin: 0 8px;" onClick="copy('${c}');">${copyButtonText}</button>` : '') + '<br/><br/>');
    if (csvText === null) {
      alert(intl.formatMessage({ id: 'Failed to get Amazon Gift' }));
    } else {

      const win = window.open();
      if (win) {

        win.document.open();
        win.document.write(`
          <html><head>
          <meta charset="utf-8" />
          <title>${winTitle} - Publy</title>
          <meta name="viewport" content="width=device-width,initial-scale=1">
          </head><body>
        `);
        win.document.write(`<h1>${copyLabel}</h1>`);
        win.document.write(csvText);
        win.document.write('<hr/><div id="txt"></div>');
        win.document.write('<script>');
        win.document.write(`
          function copy(code) {
            const i = document.createElement('input');
            i.type = 'text';
            i.value = code.trim();
            i.style.position = 'absolute';
            i.style.left = '-10000000px';
            document.body.appendChild(i);
            i.select();
            document.execCommand("copy");
            document.getElementById('txt').innerHTML = "${copiedText}「" + i.value + "」";
          };
        `);
        win.document.write('</script>');
        win.document.write('</body></html>');
        win.document.close();

      } else {
        alert(intl.formatMessage({ id: 'Blocked pop-up. Allow pop-up.' }));
      }

    }

    handleCloseToGetListOfSalesApplications();
    setOpenBackdrop(false);

  }, [intl, createSalesApplicationsCsv, handleCloseToGetListOfSalesApplications]);

  // 過去の売上受取申請の一覧をWebShareする（モバイルだけ）
  const handleWebShareListOfSalesApplications = React.useCallback(async () => {

    setOpenBackdrop(true);

    const title = intl.formatMessage({ id: 'Sales receipt information' });
    const shareLabel = intl.formatMessage({ id: 'Send Publy sales to other apps.' });
    const shareButton = intl.formatMessage({ id: 'Open other app' });
    const csvText = await createSalesApplicationsCsv(() => '\n');
    if (csvText === null) {
      alert(intl.formatMessage({ id: 'Failed to get Amazon Gift' }));
    } else {

      const win = window.open();
      if (win) {

        win.document.open();
        win.document.write(`
          <html><head>
          <meta charset="utf-8" />
          <title>${title} - Publy</title>
          <meta name="viewport" content="width=device-width,initial-scale=1">
          </head><body>
        `);
        win.document.write(`<h1>${shareLabel}</h1>`);
        win.document.write(`<div><input type="button" onclick="shareCode();" value="${shareButton}"/></div>`);
        win.document.write('<script>');
        win.document.write([
          'function shareCode() {',
          '  if (window.navigator.share) {',
          '    return window.navigator.share({',
          '      title: "[Publy] ' + title + '",',
          '      text: `' + csvText + '`',
          '    }).catch(console.error);',
          '  }',
          '};',
        ].join('\n'));
        win.document.write('</script>');
        win.document.write('</body></html>');
        win.document.close();

      } else {
        alert(intl.formatMessage({ id: 'Blocked pop-up. Allow pop-up.' }));
      }

    }

    handleCloseToGetListOfSalesApplications();
    setOpenBackdrop(false);

  }, [intl, createSalesApplicationsCsv, handleCloseToGetListOfSalesApplications]);

  // ダイアログを閉じる
  const closeDialog = React.useCallback(() => {
    setDialog(null);
  }, []);

  // Snackbar非表示
  const handleCloseSnackbar = React.useCallback(() => {
    setSnackbarOpenType(null);
  }, []);

  // 保存
  const handleSave = React.useCallback((callback) => {

    const selfIntroduction = selfIntroductionRef.current.value.trim();
    const validationMessages = getDefaultValidationMessages();

    // ひとまず今は必須なし
    // コードを残しているだけ
    let focusField = null;
    setValidationMessages(validationMessages);

    if (focusField !== null) {

      focusField.focus();
      callback(false);
      return;

    }

    const newData = {
      selfIntroduction: selfIntroduction,
    };
    if (additionalUserInfo) {
      newData['username'] = additionalUserInfo.username;
    }

    const user = firebase.auth().currentUser;
    firebase.firestore()
    .collection('profiles')
    .doc(user.uid)
    .set(newData, { merge: true })
    .then(function() {

      setSnackbarOpenType('save');
      callback(true);

    })
    .catch(function(error) {

      console.error(error);
      callback(false);

    });

  }, [additionalUserInfo]);

  // 売上を受け取る
  const handleReceiveSales = React.useCallback(async (callback) => {

    const user = firebase.auth().currentUser;

    const receiveSales = Math.min(MAX_AMAZON_GIFT_POINT, parseInt(receiveSalesRef.current.value.trim()));

    const validationMessages = getDefaultValidationMessages();

    const currentSales = profile['sales'] || 0;

    let focusField = null;
    if (isNaN(receiveSales) || receiveSales < MIN_AMAZON_GIFT_POINT) {

      focusField = receiveSalesRef.current;
      validationMessages['receiveSales'] = intl.formatMessage({ id: 'Enter ¥{ min } or more' }, { min: Number(MIN_AMAZON_GIFT_POINT).toLocaleString() });

    } else if (receiveSales > currentSales) {

      focusField = receiveSalesRef.current;
      validationMessages['receiveSales'] = intl.formatMessage({ id: 'Enter less or equal than ¥{ max }' }, { max: Number(currentSales).toLocaleString() });

    }
    setValidationMessages(validationMessages);

    if (focusField !== null) {

      focusField.focus();
      callback(false);
      return;

    }

    firebase.firestore()
    .collection('profiles')
    .doc(user.uid)
    .collection('salesApplications')
    .add({
      method: 'amazonGiftPoint',
      status: 'doing',
      receiveSales: receiveSales,
      partnerId: 'KK9eb',
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })
    .then(async function(doc) {

      callback(true);
      setCurrentAvailableApplication({ id: doc.id, receiveSales: receiveSales });
      setSnackbarOpenType('salesApplication');

    })
    .catch(function(error) {

      console.error(error);
      callback(false);

    });

  }, [profile, intl]);

  // アカウント削除表示
  const handleOpenDeleteAccountDialog = React.useCallback(() => {

    setDialog(
      <DeleteSecurityCriticalDialog
        titleId={"Delete account"}
        messageId={"This operation cannot be undone after it has been deleted. All items will be deleted and you will not be able to receive sales."}
        closeCallback={closeDialog}
      />
    );

  }, [closeDialog]);

  React.useEffect(() => {

    const user = firebase.auth().currentUser;

    // プロフィールを取得
    const getProfile = () => {

      return firebase.firestore()
      .collection('profiles')
      .doc(user.uid)
      .get()
      .then(function(doc) {

        const profileData = doc.data() || {};
        if (!profileData['selfIntroduction'] && additionalUserInfo) {
          profileData['selfIntroduction'] = additionalUserInfo.profile.description;
        }
        setProfile(profileData);

      })
      .catch(console.error);

    };

    // 最近の申請を取得する
    function getRecentApplications() {

      const limit = 1;
      firebase.firestore()
      .collection('profiles')
      .doc(user.uid)
      .collection('salesApplications')
      .where('method', '==', 'amazonGiftPoint')
      .where('status', '==', 'doing')
      .limit(limit)
      .get()
      .then(querySnapshot => {

        const firstApplicationDoc = querySnapshot.docs[0];
        if (firstApplicationDoc) {

          const firstApplicationData = firstApplicationDoc.data();
          setCurrentAvailableApplication({ id: firstApplicationDoc.id, receiveSales: firstApplicationData['receiveSales']});

        } else {
          setCurrentAvailableApplication(null);
        }

      })
      .catch(console.error);

    }

    getProfile();
    getRecentApplications();
    getRecentSalesApplicationsDone();

  }, [additionalUserInfo, getRecentSalesApplicationsDone]);


  // Amazonギフト再取得
  const updateAmazonGiftCode = React.useCallback(() => {

    if (currentAvailableApplication === null) {
      return;
    } else {

      // 要求したら成功と見なしておく
      setSnackbarOpenType('salesApplication');
      setUpdatedAvailableApplication(true);

      // ギフト情報を取得する
      const getAmazonGiftCode = firebase.app()
      .functions('asia-northeast1')
      .httpsCallable('getAmazonGiftCode');

      return getAmazonGiftCode({
        salesApplicationId: currentAvailableApplication['id']
      }).catch(console.error);

    }

  }, [currentAvailableApplication]);

  // 今売上振込申請しているならtrue
  const isDoingApplication = currentAvailableApplication !== null;

  // 申請可能額
  const currentMySales = (profile && profile['sales']) || 0;

  // 過去申請の履歴
  const hasListOfSalesApplicationsDone = listOfSalesApplicationsDone !== null && listOfSalesApplicationsDone.length > 0;

  // 申請額の初期値
  const defaultApplicationAmount = isDoingApplication ? currentAvailableApplication['receiveSales'] : Math.min(currentMySales, MAX_AMAZON_GIFT_POINT);

  // 申請中の場合は申請額を表示したい
  // defaultValueに設定する額を上書きする必要があるのでrefで設定する
  if (receiveSalesRef.current && isDoingApplication) {
    receiveSalesRef.current.value = defaultApplicationAmount;
  }

  // 売上申請額を変更したとき
  const handleChangeReceiveSales = React.useCallback((event) => {
    event.target.value = Math.min(MAX_AMAZON_GIFT_POINT, parseInt(event.target.value.trim()));
  }, []);

  // 現金受け取りならtrue
  const isSalesCashPayment = React.useMemo(() => profile && profile['isSalesCashPayment'] === true, [profile]);

  React.useEffect(() => {

    // アイテム作成画面のpopstateを保持しておく
    // 閉じるときに再設定がいるので
    const prevPopstate = window.onpopstate || null;

    // 表示時に何も遷移しない履歴をいれることで
    // 戻るを押されても何もしない
    window.history.pushState(null, null, null);
    window.onpopstate = callbacks.closeDialog;
    return () => {
      window.onpopstate = prevPopstate;
    };

  }, [callbacks]);

  return (

    <>

      <Snackbar open={snackbarOpenType !== null} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          { snackbarOpenType === 'save' ? (
            <FormattedMessage id="Saved successfully" />
          ) : snackbarOpenType === 'salesApplication' ? (
            <>
              <Link
                href="https://kunimasu.notion.site/154fb63ffb8c4b89b60d464c34ad631e"
                underline="always"
                target="_blank"
              >
                <FormattedMessage id="We have received your request. It may take up to 48 hours." />
              </Link>
            </>
          ) : null}
        </Alert>
      </Snackbar>

      <Dialog
        maxWidth="xs"
        fullScreen={fullScreen}
        open
        onClose={callbacks.closeDialog}
      >
        <DialogTitle>
          <FormattedMessage id="My account" />
        </DialogTitle>
        { profile === null ? <LinearProgress color="secondary" /> : (
          <>
            <DialogContent>
              <div className={classes.contents}>
                {profile['verifiedUser'] === true && (
                  <div
                    className={classes.verifiedUser}
                  >
                    <VerifiedUser
                      className={classes.verifiedUserIcon}
                    />
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.verifiedUserText}}
                    >
                      <FormattedMessage id="You are official account." />
                    </Typography>
                  </div>
                )}
                <Typography
                  component="div"
                  variant="body1"
                  color="textPrimary"
                >
                  <FormattedMessage id="Profile" />
                </Typography>
                <TextField
                  defaultValue={profile.selfIntroduction}
                  label={<FormattedMessage id="Self-introduction - Up to 250 characters" />}
                  autoComplete="off"
                  inputRef={selfIntroductionRef}
                  inputProps={{
                    maxLength: 250,
                  }}
                  variant="filled"
                  fullWidth
                  error={validationMessages.selfIntroduction != null}
                  helperText={validationMessages.selfIntroduction}
                  required
                  multiline
                  classes={{ root: classes.textField }}
                  rows={5}
                />
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                >
                  <FormattedMessage id="Self introduction text that appears on your sales page." />
                </Typography>
                <div className={classes.button}>
                  <InteractiveButton
                    text={<FormattedMessage id="Save" />}
                    clickHandler={handleSave}
                    handlerRef={saveButtonHandlerRef}
                  />
                </div>
              </div>
              <Divider
                light
                classes={{ root: classes.divider }}
              />
              <div className={classes.contents}>
                <Typography
                  component="div"
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                >
                  <FormattedMessage id="Sales" />
                </Typography>
                { !isSalesCashPayment && (
                  <Typography
                    component="div"
                    variant="body2"
                    gutterBottom
                  >
                    <Link
                      href="https://kunimasu.notion.site/154fb63ffb8c4b89b60d464c34ad631e"
                      target="_blank"
                      underline="always"
                    >
                      <Help
                        className={classes.helpIcon}
                      />
                      <span
                        className={classes.helpText}
                      >
                        <FormattedMessage id="How to receive sales?" />
                      </span>
                    </Link>
                  </Typography>
                )}
                { currentMySales === 0 ? (
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage id="Currently no sales are available." />
                  </Typography>
                ) : (
                  <>
                    <div>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textSecondary"
                      >
                        <FormattedMessage id="Receivable sales is" />
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        &nbsp;¥{Number(currentMySales).toLocaleString()}
                      </Typography>
                    </div>
                    { isSalesCashPayment ? (
                      <>
                        <Typography
                          component="div"
                          variant="body2"
                          color="textSecondary"
                        >
                          <FormattedMessage id="In case of cash payment, we will pay by another method." />
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          component="div"
                          variant="body2"
                          color="textSecondary"
                        >
                          <FormattedMessage id="¥1 = 1 Amazon Gift Point" />
                        </Typography>
                        <div
                          className={classes.textFieldWrap}
                        >
                          <TextField
                            type="number"
                            label={<FormattedMessage id="Amount to receive" />}
                            defaultValue={defaultApplicationAmount}
                            autoComplete="off"
                            inputRef={receiveSalesRef}
                            inputProps={{
                              max: MAX_AMAZON_GIFT_POINT,
                              min: MIN_AMAZON_GIFT_POINT,
                            }}
                            variant="filled"
                            disabled={isDoingApplication}
                            fullWidth
                            error={validationMessages.receiveSales != null}
                            helperText={validationMessages.receiveSales}
                            classes={{ root: classes.textField }}
                            onChange={handleChangeReceiveSales}
                          />
                          <Typography
                            component="div"
                            variant="body2"
                            color="textSecondary"
                            classes={{ root: classes.textFieldUnit }}
                          >
                            <FormattedMessage id="Yen" />
                          </Typography>
                        </div>
                        <Typography
                          component="div"
                          variant="caption"
                          color="textSecondary"
                        >
                          <FormattedMessage id="Upper limit is 100,000 yen each time." />
                        </Typography>
                        <div
                          className={classes.button}
                        >
                          <InteractiveButton
                            text={<FormattedMessage id="Apply for receipt of sales" />}
                            variant="outlined"
                            color="default"
                            disabled={isDoingApplication}
                            classes={{ root: classes.button }}
                            startIcon={<Money />}
                            clickHandler={handleReceiveSales}
                            handlerRef={receiveSalesButtonHandlerRef}
                          />
                          { isDoingApplication ? (
                            <div>
                              <Typography
                                component="span"
                                variant="caption"
                                color="textSecondary"
                              >
                                <FormattedMessage id="In process" />
                                （
                                  { isUpdatedAvailableApplication ? (
                                    <Typography
                                      component="span"
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      <FormattedMessage id="Updating" />
                                    </Typography>
                                  ) : (
                                    <>
                                      <Link
                                        onClick={updateAmazonGiftCode}
                                        underline="always"
                                        classes={{ root: classes.updateAmazonGiftCode }}
                                      >
                                        <FormattedMessage id="Update status" />
                                      </Link>
                                    </>
                                  )}
                                )
                              </Typography>
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </>
                )}
                { hasListOfSalesApplicationsDone ? (
                  <>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textPrimary"
                      classes={{ root: classes.listOfApplications }}
                    >
                      <FormattedMessage id="You can get sales your applications for in CSV format. Next, continue selecting how to receive it." />
                    </Typography>
                    <div>
                      <TextField
                        id="dateOfApplicationSelect"
                        select
                        label=<FormattedMessage id="Date of application" />
                        variant="filled"
                        onClick={handleDateOfApplication}
                        fullWidth
                      >
                        { listOfSalesApplicationsDone.map(({ key, value }) => (
                          <MenuItem
                            value={key}
                            key={"salesApplication-" + key}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </>
                ) : null }
                <Menu
                  id="list-sales-papplications-menu"
                  anchorEl={listOfSalesApplicationsAnchorEl}
                  keepMounted
                  open={Boolean(listOfSalesApplicationsAnchorEl)}
                  onClose={handleCloseToGetListOfSalesApplications}
                  className={classes.listOfSalesApplicationsAnchorEl}
                >
                  <MenuItem
                    onClick={handleEmailListOfSalesApplications}
                  >
                    <FormattedMessage id="Email it" />
                  </MenuItem>
                  <MenuItem
                    onClick={handleDownloadListOfSalesApplications}
                  >
                    <FormattedMessage id="Download it" />
                  </MenuItem>
                  <MenuItem
                    onClick={handleOpenAsNewTabListOfSalesApplications}
                  >
                    <FormattedMessage id="Open it as new tab" />
                  </MenuItem>
                  { window.navigator.share ? (
                    <MenuItem
                      onClick={handleWebShareListOfSalesApplications}
                    >
                      <FormattedMessage id="Send it to app (Part of mobile only)" />
                    </MenuItem>
                  ) : null }
                </Menu>
              </div>
              { additionalUserInfo ? null : (
                <>
                  <Divider
                    light
                    classes={{ root: classes.divider }}
                  />
                  <div className={classes.contents}>
                    <Typography
                      component="div"
                      display="block"
                      variant="body1"
                      color="textPrimary"
                    >
                      <FormattedMessage id="Delete account" />
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="textSecondary"
                    >
                      <FormattedMessage id="This operation cannot be undone after it has been deleted. All items will be deleted and you will not be able to receive sales." />
                    </Typography>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleOpenDeleteAccountDialog}
                      classes={{ root: classes.button }}
                      startIcon={<Delete />}
                    >
                     <FormattedMessage id="Delete account" />
                    </Button>
                  </div>
                </>
              )}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={callbacks.closeDialog}
          >
            <FormattedMessage id="Close" />
          </Button>
        </DialogActions>
      </Dialog>
      {dialog}

      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>

  );

}

export default injectIntl(AccountSettingsDialog);
