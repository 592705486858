
// カレンダーサイズ
export const CALENDAR_PX = { x: 1132, y: 2136 };
export const CALENDAR_MM = { x: 90, y: 175 };

// A5マガジンサイズ（片側ページ、見開きではない）
export const A5_MAGAZINE_PX = { x: 1789, y: 2550 };
export const A5_MAGAZINE_MM = { x: 148, y: 210 };


// 印刷物のアスペクト比を取得
export const getPrintAspectRatio = (type, calendarDateOption) => {

  if (type === 'calendar') {
    return calendarDateOption && calendarDateOption === 'none' ? CALENDAR_PX.y / CALENDAR_PX.x : 1.0;
  } else {
    return A5_MAGAZINE_PX.y / A5_MAGAZINE_PX.x;
  }

}
