
export function getShopifyDomain() {

  let shopifyDomain = null;
  if (process.env.REACT_APP_ENV === 'prod') {
    shopifyDomain = 'publy-fun.myshopify.com';
  } else /* dev */ {
    shopifyDomain = 'publy-fun-dev.myshopify.com';
  }

  return shopifyDomain;

}

export function getShopifyStorefrontAccessToken() {

  let storefrontAccessToken = null;
  if (process.env.REACT_APP_ENV === 'prod') {
    storefrontAccessToken = '89b6b478f065533e8e3ff21dd65dc700';
  } else /* dev */ {
    storefrontAccessToken = 'd33811adca6d4732f81432f5a44b2920';
  }
  return storefrontAccessToken;

}
