const firebaseConfig = process.env.REACT_APP_ENV === 'prod' ? {
  apiKey: "AIzaSyDtDT9gB-P3N2XklMIu9bxgLuZMcy22ucw",
  authDomain: "publy-prod.firebaseapp.com",
  databaseURL: "https://publy-prod.firebaseio.com",
  projectId: "publy-prod",
  storageBucket: "publy-prod.appspot.com",
  messagingSenderId: "607677836642",
  appId: "1:607677836642:web:f4b8432524ab66d518503b",
  measurementId: "G-9JYMCTJL8R"
} : {
  apiKey: "AIzaSyBJAuLb9-NGSuWAN4-dmdkxvps7VeaO1BE",
  authDomain: "wemagazine-dev.firebaseapp.com",
  databaseURL: "https://wemagazine-dev.firebaseio.com",
  projectId: "wemagazine-dev",
  storageBucket: "wemagazine-dev.appspot.com",
  messagingSenderId: "1077005782556",
  appId: "1:1077005782556:web:0affe1a096fe4a502d19d9",
  measurementId: "G-0BR1R3XQ72"
};

export default firebaseConfig;
