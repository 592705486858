import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { CardActionArea } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';

import { red } from '@material-ui/core/colors';

import { FormattedMessage, injectIntl } from "react-intl";
import { getDownloadUrl } from "./DownloadFile";
import { RESIZE_SIZE } from "./DownloadFile";
import { getPreviewStyleImage } from "./ImageUtil";
import { getTwitterProfileImageOriginUrl } from "./ImageUtil";

// theme.spacing
const useStyles = makeStyles(theme => ({

  root: {
  },
  actionAreaRoot: {
    textAlign: 'center',
  },
  actionsRoot: {
    display: 'block',
    cursor: 'pointer',
  },
  itemMedia: {
    height: 250,
  },
  avatar: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
  authorName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  price: {
    color: red[500],
    fontWeight: 'bold',
  },
  request: {
    fontStyle: 'italic',
  },

}));

function RecommendedItem(props) {

  const { item, intl } = props;
  const classes = useStyles();

  // State
  const [image, setImage] = React.useState(null);

  // Callbacks
  const handleClick = React.useCallback(() => {

    const win = window.open(`/items/${item['userId']}/${item['id']}`, '_blank');
    if (!win) {
      alert(intl.formatMessage({ id: 'Blocked pop-up. Allow pop-up.' }));
    }

  }, [item, intl]);

  // Memo
  // リクエスト作品ならtrue
  const isRequestItem = React.useMemo(() => {

    const requestId = item['requestId'] || null;
    return requestId !== null;

  }, [item]);

  // 表示するタイトル
  const getTitle = React.useCallback(() => {

    if (isRequestItem) {
      return intl.formatMessage({ id: 'Request item' })
    } else {
      return item['title'];
    }

  }, [isRequestItem, item, intl]);

  React.useEffect(() => {

    const page = item['pages'][0];
    getDownloadUrl(page['remotePath'].replace('origin', `origin_${RESIZE_SIZE}`), (url) => {

      getPreviewStyleImage(url, item, page, null, (src) => {
          setImage(src);
      })

    });

  }, [item]);

  return (

    <Card
      classes={{ root: classes.root }}
    >
      <CardActionArea
        classes={{ root: classes.actionAreaRoot }}
        onClick={handleClick}
      >
        { image !== null ? (
          <img
            className={classes.itemMedia}
            alt={item['title']}
            src={image}
          />
        ) : (
          <Skeleton
            variant="rect"
            className={classes.itemMedia}
          />
        )}
      </CardActionArea>
      <CardActions
        classes={{ root: classes.actionsRoot }}
        onClick={handleClick}
      >
        <CardContent
          classes={{ root: classes.contentRoot }}
        >
          <Avatar
            src={getTwitterProfileImageOriginUrl(item['profileImage'])}
            classes={{ root: classes.avatar }}
          />
          <Typography
            component="div"
            variant="caption"
            color="textPrimary"
            classes={{ root: classes.authorName }}
          >
            {item['name']}
          </Typography>
          <Typography
            component="div"
            variant="body1"
            color="textPrimary"
            classes={{ root: [classes.title, isRequestItem ? classes.request : ''].join(' ') }}
          >
            {getTitle(item)}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            { item['type'] === 'calendar' ? <FormattedMessage id="Calendar type" /> : <FormattedMessage id="Magazine type" /> }
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color="textSecondary"
            classes={{ root: classes.price }}
          >
            <FormattedMessage id="¥{ yen }" values={{ yen: Number(item['itemPrice']).toLocaleString() }} />
          </Typography>
        </CardContent>
      </CardActions>
    </Card>
  );

}

export default injectIntl(RecommendedItem);
