import React from 'react';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({

  dangerous: {
    color: '#FF3300',
  },

}));

function AlertDialog(props) {

  const { title, message, dangerousMessage, closeCallback } = props
  const classes = useStyles();
  const fullScreen = false;

  return (

    <Dialog
      open
      fullScreen={fullScreen}
      onClose={closeCallback}
    >
      { title ? (
        <DialogTitle>
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent>
        { message ? (
          <DialogContentText>
            {message}
          </DialogContentText>
        ) : null}
        { dangerousMessage ? (
          <DialogContentText classes={{ root: classes.dangerous }}>
            {dangerousMessage}
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeCallback}
          autoFocus
        >
          <FormattedMessage id="Close" />
        </Button>
      </DialogActions>
    </Dialog>

  );

}

export default injectIntl(AlertDialog);
