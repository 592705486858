import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { TwitterFollowButton } from 'react-twitter-embed';
import { TwitterDMButton } from 'react-twitter-embed';

import queryString from 'query-string';
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import Footer from "./Footer";
import Header from "./Header";
import buttonArrow from './buttonArrow.png';
import howToSelectItem from './howToSelectItem.png';
import howToShareItem from './howToShareItem.png';
import howToUploadImage from './howToUploadImage.png';
import topCatchy from './topCatchy.jpg';
import { disableHoverEventOnMobile } from './EventUtil';
import { termsOfUse } from './WidgetUtil';
import { getTwitterLoginButton } from './WidgetUtil';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const useStyles = makeStyles(theme => ({

  aboutService: {
    padding: 0,
  },
  catchy: {
    backgroundImage: `url(${topCatchy})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    [theme.breakpoints.only('xs')]: {
      padding: '40px 20px 30px 20px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '50px 20px 40px 20px',
    },
    [theme.breakpoints.up('md')]: {
      height: 550,
      paddingTop: 150,
    },
  },
  catchyText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 36,
    },
    textAlign: 'center',
    color: 'white',
    textShadow: 'black 0 0 8px',
  },
  buttonStartWrap: {
    width: 224,
    margin: 'auto',
    marginTop: 20,
  },
  buttonWrap: {
    cursor: 'pointer',
    fontFamily: "HiraKakuProN-W6, sans-serif",
    display: "block",
    float: "left",
    position: "relative",
    borderRadius: 8,
    border: "2px solid black",
    textAlign: "center",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  buttonStart: {
    width: 224,
    height: 45,
    boxShadow: "-4px 3px 0px 0px black",
  },
  buttonStartText: {
    lineHeight: "43px",
    fontSize: 18,
  },
  buttonStartArrowImage: {
    position: 'relative',
    left: 12,
    transform: 'rotate(90deg)',
  },
  buttonLogin: {
    width: 290,
    height: 71,
    boxShadow: "-5px 7px 0px 0px black",
  },
  buttonLoginText: {
    fontSize: 20,
    marginTop: 12,
    float: 'left',
    marginLeft: 20,
  },
  buttonSmallText: {
    fontFamily: "Hiragino Kaku Gothic ProN, sans-serif",
    fontSize: 12,
    float: "left",
    marginLeft: 55,
  },
  buttonLoginArrowImage: {
    position: "relative",
    top: 26,
  },
  buttonTwitterImage: {
    float: "left",
  },
  mainContents: {
    [theme.breakpoints.only('xs')]: {
      padding: 20,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '40px 87px',
    },
  },
  howTo: {
    lineHeight: '36px',
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 45,
  },
  howToContentsWrap: {
    listStyle: 'none',
    padding: 0,
    [theme.breakpoints.only('xs')]: {
      maxWidth: 320,
      margin: 'auto',
    },
  },
  howToContents: {
    maxWidth: 320,
    float: 'left',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(33% - 3vw)',
      '&:nth-child(n+2)': {
        marginLeft: '3vw',
      },
    },
  },
  howToContentsTitle: {
    textAlign: 'center',
    marginBottom: 8,
    fontFamily: "HiraKakuProN-W6, sans-serif",
  },
  howToContentsImage: {
    position: 'relative',
    maxWidth: 200,
    [theme.breakpoints.only('xs')]: {
      top: 40,
      width: 200,
    },
    [theme.breakpoints.up('sm')]: {
      top: '3vw',
      width: '16vw',
    },
  },
  howToUploadImage: {
    maxWidth: 150,
    [theme.breakpoints.only('xs')]: {
      top: 0,
      width: 200,
    },
    [theme.breakpoints.up('sm')]: {
      width: '8vw',
      top: '1vw',
    },
  },
  howToShareItem: {
    maxWidth: 200,
    [theme.breakpoints.only('xs')]: {
      top: 10,
      width: 200,
    },
    [theme.breakpoints.up('sm')]: {
      width: '13vw',
      top: '0.5vw',
    },
  },
  howToContentsDescription: {
    marginTop: 14,
    fontFamily: "Hiragino Kaku Gothic ProN, sans-serif",
  },
  howToContentsBg: {
    margin: 'auto',
    maxWidth: 220,
    maxHeight: 220,
    backgroundColor: '#F8F0E8',
    border: '3px solid white',
    [theme.breakpoints.only('xs')]: {
      width: 220,
      height: 220,
      borderRadius: 110,
    },
    [theme.breakpoints.up('sm')]: {
      width: '16vw',
      height: '16vw',
      borderRadius: '8vw',
    },
  },
  attention: {
    textAlign: 'center',
    border: `1px solid ${red[500]}`,
    color: red[500],
    padding: 16,
  },
  loginWrap: {
    marginTop: theme.spacing(4),
    margin: 'auto',
    width: 290,
    height: 80,
  },
  agreementWrap: {
    clear: 'both',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
  },

}));

// マイページへ遷移
const goMyPage = () => {

  window.history.pushState(null, null, '/myPage');
  window.location.href = '/myPage';

};

const jumpToLogin = (e) => {
  window.scrollTo(0, document.getElementById('twitterLogin').offsetTop);
};

function Top(props) {

  const classes = useStyles();

  // State
  const [isSuccessLogin, setSuccessLogin] = React.useState(false);

  // Ref
  const isSignedInRef = React.useRef(false);
  const signInRedirectRef = React.useRef(null);

  // プロフィール作成 or 更新
  const updateAndGetProfile = React.useCallback(async (user, additionalUserInfo) => {

    const email = additionalUserInfo.profile.email;
    const data = {
      email,
      username: additionalUserInfo.username,
      name: additionalUserInfo.profile.name,
      profileImage: additionalUserInfo.profile.profile_image_url_https,
    };
    if (additionalUserInfo.isNewUser) {
      data['emailForNews'] = email;
    }

    await firebase.firestore()
    .collection('profiles')
    .doc(user.uid)
    .set(data, { merge: true })
    .catch(console.error);

  }, []);

  // ログイン後
  const signedIn = React.useCallback(async (user, additionalUserInfo) => {

    setSuccessLogin(true);
    await updateAndGetProfile(user, additionalUserInfo);

    if (signInRedirectRef.current === null) {
      goMyPage();
    } else {
      window.location.href = signInRedirectRef.current;
    }

  }, [updateAndGetProfile]);

  // Twitterログイン
  const signInWithTwitter = React.useCallback(() => {

    // ログインしていないときだけログインする
    if (firebase.auth().currentUser === null) {

      const provider = new firebase.auth.TwitterAuthProvider();
      firebase.auth().signInWithPopup(provider).then(async function(result) {
        signedIn(result.user, result.additionalUserInfo);
      })
      .catch(console.error);
      isSignedInRef.current = true;

    } else {
      goMyPage();
    }

  }, [signedIn]);

  // 認証前トップ
  const mainContents = React.useMemo(() => {

    return (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
        >
          <Container
            maxWidth={false}
            classes={{ root: classes.aboutService }}
          >
            <Container
              maxWidth={false}
              classes={{ root: classes.catchy }}
            >
              <Typography
                variant="h6"
                classes={{ root: classes.catchyText }}
              >
                <FormattedMessage id="Make and sell" />
              </Typography>
              <Typography
                variant="h6"
                classes={{ root: classes.catchyText }}
              >
                <FormattedMessage id="original calendars and magazines" />
              </Typography>
              <Typography
                variant="h6"
                classes={{ root: classes.catchyText }}
              >
                <FormattedMessage id="with your favorite photos!" />
              </Typography>
              <div
                className={`clearfix ${classes.buttonStartWrap}`}
              >
                <Link
                  underline="none"
                  className={`${classes.buttonWrap} ${classes.buttonStart}`}
                  onClick={jumpToLogin}
                >
                  <span
                    className={classes.buttonStartText}
                  >
                    <FormattedMessage id="Make it now" />
                  </span>
                  <img
                    alt="arrow"
                    src={buttonArrow}
                    className={classes.buttonStartArrowImage}
                    width="8"
                  />
                </Link>
              </div>
            </Container>
            <Container
              maxWidth="lg"
              className="clearfix"
              classes={{ root: classes.mainContents }}
            >
              <div
                className={classes.howTo}
              >
                how to make
              </div>
              <ul
                className={classes.howToContentsWrap + " clearfix"}
              >
                <li
                  className={classes.howToContents}
                >
                  <Typography
                    variant="h6"
                    classes={{ root: classes.howToContentsTitle }}
                  >
                    <FormattedMessage id="Select calendar or magazine" />
                  </Typography>
                  <div
                    className={classes.howToContentsBg}
                  >
                    <img
                      alt="Select calendar or magazine"
                      src={howToSelectItem}
                      className={classes.howToContentsImage}
                    />
                  </div>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.howToContentsDescription }}
                  >
                    <FormattedMessage id="You can choose from a desktop size of about 17 cm in length for calendar, A5 size for magazine of 12 page mini magazine." />
                  </Typography>
                </li>
                <li
                  className={classes.howToContents + " clearfix"}
                >
                  <Typography
                    variant="h6"
                    classes={{ root: classes.howToContentsTitle }}
                  >
                    <FormattedMessage id="Upload and edit images" />
                  </Typography>
                  <div
                    className={classes.howToContentsBg}
                  >
                    <img
                      alt="Upload and edit images"
                      src={howToUploadImage}
                      className={classes.howToContentsImage + " " + classes.howToUploadImage}
                    />
                  </div>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.howToContentsDescription }}
                  >
                    <FormattedMessage id="Easy operation just by uploading image according to instructions on screen. Available to change size and orientation." />
                  </Typography>
                </li>
                <li
                  className={classes.howToContents}
                >
                  <Typography
                    variant="h6"
                    classes={{ root: classes.howToContentsTitle }}
                  >
                    <FormattedMessage id="Share and sell on Twitter!" />
                  </Typography>
                  <div
                    className={classes.howToContentsBg}
                  >
                  <img
                    alt="Share and sell on Twitter!"
                    src={howToShareItem}
                    className={classes.howToContentsImage + " " + classes.howToShareItem}
                  />
                  </div>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.howToContentsDescription }}
                  >
                    <FormattedMessage id="Publy takes care of everything from purchasing to shipping, so you can sell your item without stock." />
                  </Typography>
                </li>
              </ul>
              <Typography
                component="div"
                variant="body1"
                classes={{ root: classes.attention }}
              >
                ※ <FormattedMessage id="Sales of your item will be paid with Amazon Gift Certificates." />
              </Typography>
              { getTwitterLoginButton(classes, signInWithTwitter) }
              <Typography
                variant="body1"
                classes={{ root: classes.agreementWrap }}
              >
                { termsOfUse }
              </Typography>
            </Container>
            <Typography
              component="div"
              variant="body1"
              classes={{ root: classes.agreementWrap }}
              align="center"
            >
              <FormattedMessage id="Follow our official account." />
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
            >
              <TwitterFollowButton
                screenName="publyfun"
              />
            </Grid>
            <Typography
              component="div"
              variant="body1"
              align="center"
            >
              <FormattedMessage id="For inquiries, please use DM or marshmallow." />
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
            >
              <TwitterDMButton
                id="1259466717123579905"
              />
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
            >
              <Link
                href="https://marshmallow-qa.com/publyfun"
                target="_blank"
              >
                https://marshmallow-qa.com/publyfun
              </Link>
            </Grid>
          </Container>
        </Grid>
      </>
    );

  }, [classes, signInWithTwitter]);

  // ヘッダー
  const header = React.useMemo(() => {
    return (<Header
      signInHandler={() => {

        if (firebase.auth().currentUser === null) {
          window.location.href = '#twitterLogin'
        } else {
          window.location.href = '/myPage'
        }

      }}
    />);
  }, []);

  // フッター
  const footer = React.useMemo(() => {
    return (<Footer />);
  }, []);

  React.useEffect(() => {

    disableHoverEventOnMobile();

    // ログイン要求
    const queryParameters = queryString.parse(window.location.search);
    signInRedirectRef.current = queryParameters['redirectTo'] || null;
    if (signInRedirectRef.current !== null) {
      signInWithTwitter();
    }

  }, [signInWithTwitter]);

  return (

    <>
      { header }
      { isSuccessLogin ? <LinearProgress color="secondary" /> : null }
      { mainContents }
      { footer }
    </>

  );

}

export default injectIntl(Top);
