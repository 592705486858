import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { CardActionArea } from '@material-ui/core';
import { CardMedia } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { Grid } from '@material-ui/core';

import { FormattedMessage } from "react-intl";
import calendarItem from "./calendarItem.png";
import magazineItem from "./magazineItem.png";
import { CALENDAR_MM } from './PrintSize';
import { A5_MAGAZINE_MM } from './PrintSize';
import { MyPageContext } from "./MyPage";

const useStyles = makeStyles(theme => ({

  choiceItemRoot: {
    marginTop: theme.spacing(0),
  },
  choiceItem: {
    display: "inline-block",
    [theme.breakpoints.only('xs')]: {
      '&:nth-child(n+2)': {
        marginTop: theme.spacing(3),
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(n+2)': {
        marginLeft: theme.spacing(3),
      },
    },
  },
  actionAreaRoot: {
    backgroundColor: theme.palette.action.selected,
  },
  itemMedia: {
    backgroundSize: 'contain',
    backgroundColor: 'white',
    width: 300,
    height: 226,
    margin: 'auto',
  },

}));

export default function SelectItem(props) {

  const classes = useStyles();

  // Callback
  const { callbacks } = React.useContext(MyPageContext);

  return (

    <>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        classes={{ root: classes.choiceItemRoot }}
      >
        <Card className={classes.choiceItem}>
          <CardActionArea
            classes={{ root: classes.actionAreaRoot }}
          >
            <CardMedia
              onClick={callbacks.newCalendarItem}
              className={classes.itemMedia}
              image={calendarItem}
            />
          </CardActionArea>
          <CardActions classes={{ root: classes.actionsRoot }}>
            <CardContent classes={{ root: classes.contentRoot }}>
              <Typography
                variant="h5"
                color="textPrimary"
                component="div"
                gutterBottom
              >
                <FormattedMessage id="Calendar type" />
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
              >
                <div>
                  <FormattedMessage
                    id="Month's calendar"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="Size: { x }mm x { y } mm"
                    values={{ x: CALENDAR_MM.x, y: CALENDAR_MM.y }}
                  />
                </div>
                <div>
                  <FormattedMessage id="Paper: CARD BOARD" />
                </div>
              </Typography>
            </CardContent>
          </CardActions>
        </Card>
        <Card
          className={classes.choiceItem}
        >
          <CardActionArea
            classes={{ root: classes.actionAreaRoot }}
          >
            <CardMedia
              onClick={callbacks.newMagazineItem}
              className={classes.itemMedia}
              image={magazineItem}
            />
          </CardActionArea>
          <CardActions
            classes={{ root: classes.actionsRoot }}
          >
            <CardContent
              classes={{ root: classes.contentRoot }}
            >
              <Typography
                variant="h5"
                color="textPrimary"
                component="div"
                gutterBottom
              >
                <FormattedMessage id="Magazine type" />
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
              >
                <div>
                  <FormattedMessage
                    id="Size: A5({ x }mm × { y }mm)"
                    values={{ x: A5_MAGAZINE_MM.x, y: A5_MAGAZINE_MM.y }}
                  />
                </div>
                <div>
                  <FormattedMessage id="Number of pages: 12p" />
                </div>
                <div>
                  <FormattedMessage id="Paper: COATED PAPER" />
                </div>
              </Typography>
            </CardContent>
          </CardActions>
        </Card>
      </Grid>

    </>

  );

}
